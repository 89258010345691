import React, { useState } from "react";
import ReactGA from "react-ga4";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import YouTube from "react-youtube";
import CommonvideoContent from "../CommonvideoContent";
import InjectScript from "../CustomScript";

/**
 * Video ad script
 */
const homevidcrunch =
  "" +
  '<script async id="AV65a444a5fc8f9843480d85c8" type="text/javascript" src=" https://tg1.vidcrunch.com/api/adserver/spt?AV_TAGID=65a444a5fc8f9843480d85c8&AV_PUBLISHERID=5b8cff1a28a06149a014c4b0"></script>' +
  "";

/**
 * Videos component
 * 
 * This component renders a section for videos on the homepage.
 */
const Videos = () => {
  /**
   * Get the asset base URL from the Redux store
   */
  const { 21: assetbaseUrl } = useSelector(
    (state) => state?.SettingReducer?.data,
  );

  /**
   * Get the videos list from the Redux store
   */
  const Videoslist = useSelector(
    (state) => state?.HomeSecondaryReducer?.data?.Videos,
  );

  /**
   * Set the state for the video ID, title, and time
   */
  const [vidId, setvidId] = useState("");
  const [vidTitle, setvidTitle] = useState("");
  const [vidTime, setvidTime] = useState("");

  /**
   * Function to open the video modal
   * 
   * @param {string} VidDataId - The video ID
   * @param {string} VidDataTitle - The video title
   * @param {string} VidDataTime - The video time
   */
  const VideoModalOpen = (VidDataId, VidDataTitle, VidDataTime) => {
    setvidId(VidDataId);
    setvidTitle(VidDataTitle);
    setvidTime(VidDataTime);
    document.getElementById("videoPoPup1").classList.add("active-modal");
    document.body.style.overflow = "hidden";
  };

  /**
   * Function to close the video modal
   */
  const VideoModalClose = () => {
    setvidId("");
    document.body.style.overflow = "auto";
    document.getElementById("videoPoPup1").classList.remove("active-modal");
  };

  /**
   * Options for the YouTube player
   */
  const opts = {
    height: "100%",
    width: "100%",
    playerVars: {
      autoplay: 0,
      modestbranding: 1,
    },
  };

  /**
   * Options for the YouTube player in the modal
   */
  const optsModal = {
    height: "100%",
    width: "100%",
    playerVars: {
      autoplay: 0,
      modestbranding: 1,
    },
  };

  return (
    <>
      <section className="videos bg-gray">
        <div className="container mb-3">
          <div className="">
            <span className="badge badge-info">Videos</span>
            <Link
              onClickCapture={() =>
                ReactGA.event({
                  category: "Homepage",
                  action: "Videos",
                  label: "Read More",
                })
              }
              to="/videos"
              className="readmore"
            >
              View All
            </Link>
          </div>
          <div className="row">
            <div className="col-md-3 relateds borderVideoBottom gujarat-responsive-space-sm ">
              {Videoslist?.length > 0 &&
                Videoslist?.map((els, idx) => (
                  <>
                    {idx === 1 || idx === 2? (
                      <>
                        {els?.post_type === 4? (
                          <div
                            className="mb-3 cursor-pointer"
                            onClickCapture={() => {
                              VideoModalOpen(
                                els?.video_yt,
                                els?.title,
                                els?.publish_date,
                              );
                            }}
                          >
                            <CommonvideoContent
                              dataclass2
                              dataclass="top10"
                              src={`${assetbaseUrl?.value}${els?.media.replace("?resize=800,450", "")}?resize=312,175`}
                              post_type={els?.post_type}
                              time={els?.time}
                              publish_date={els?.publish_date}
                              category={els?.category}
                              title={els?.title}
                            />
                          </div>
                        ) : (
                          <div className="mb-3">
                            <CommonvideoContent
                              dataclass2
                              url={els?.url}
                              dataclass="top10"
                              src={`${assetbaseUrl?.value}${els?.media.replace("?resize=800,450", "")}?resize=312,175`}
                              post_type={els?.post_type}
                              time={els?.time}
                              publish_date={els?.publish_date}
                              category={els?.category}
                              title={els?.title}
                            />
                          </div>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </>
                ))}
            </div>
            <div className="col-md-6 mt-04 borderoverlap">
              {Videoslist?.length > 0 &&
                Videoslist?.map((els, idx) => (
                  <>
                    {idx === 0? (
                      <>
                        <div className="bordersLeftRight">
                          <div className="border-video">
                            <div className="iframePlay">

                              <InjectScript script={homevidcrunch} />

                            </div>
                          </div>
                          <div className="video-content-main">
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                ))}

              <div className="open-links-mobile">
                <a
                  href="https://play.google.com/store/apps/details?id=com.sandesh.news"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="https://epapercdn.sandesh.com/images/2024/04/16/yAGjx1D7Ne9UfVGTn3pMYHi4RsIfGrGZHZsLDxO9.svg"
                    alt=""
                  />
                </a>
                <a
                  href="https://apps.apple.com/in/app/sandesh-news/id1200153846"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="https://epapercdn.sandesh.com/images/2024/04/16/g6SbD2Webbbs7p01gwZ4HREoLBrIHUFj890lux4k.svg"
                    alt=""
                  />
                </a>
              </div>

            </div>
            <div className="col-md-3 relateds borderVideoBottom gujarat-responsive-space-sm ">
              {Videoslist?.length > 0 &&
                Videoslist?.map((els, idx) => (
                  <>
                    {idx === 3 || idx === 4? (
                      <>
                        {els?.post_type === 4? (
                          <div
                            className="mb-3"
                            onClickCapture={() => {
                              VideoModalOpen(
                                els?.video_yt,
                                els?.title,
                                els?.publish_date,
                              );
                            }}
                          >
                            <CommonvideoContent
                              dataclass2
                              dataclass="top10"
                              src={`${assetbaseUrl?.value}${els?.media}`}
                              post_type={els?.post_type}
                              time={els?.time}
                              publish_date={els?.publish_date}
                              category={els?.category}
                              title={els?.title}
                            />
                          </div>
                        ) : (
                          <div className="mb-3">
                            <CommonvideoContent
                              dataclass2
                              url={els?.url}
                              dataclass="top10"
                              src={`${assetbaseUrl?.value}${els?.media}`}
                              post_type={els?.post_type}
                              time={els?.time}
                              publish_date={els?.publish_date}
                              category={els?.category}
                              title={els?.title}
                            />
                          </div>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </>
                ))}
            </div>
          </div>
        </div>
        <div id="videoPoPup1" className="videoSidebar">
          <div className="closebtn" onClick={() => VideoModalClose()}>
            <i className="fas fa-times"></i>
          </div>
          
          <YouTube
            className="dailymotion-videoPoPup-page"
            videoId={vidId}
            opts={optsModal}
          />

          
        </div>
      </section>
    </>
  );
};

export default Videos;