import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import "react-multi-carousel/lib/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import sandeshske from "../../assets/sandeshske.png";
import { CityResultFetchData } from "../../redux/actions/cityResult";
import { GujaratSamagraCityFetchData } from "../../redux/actions/gujaratSamagraCityAction";
import { Get } from "../../services/api.service";
import HttpService from "../../utils/httpService";
import CommonContent from "../CommonContent";
import CommonHeadCardContent from "../CommonHeadCardContent";
import InjectScript from "../CustomScript";
import LocationSelect from "./LocationSelect";

// Define the afterPrimary script
const afterPrimary = `
  <!-- script content -->
`;

const Gujarat = () => {
  // Get the asset base URL and default active Samagra tab from the Redux store
  const { 21: assetbaseUrl, 23: defaultActiveSamagraTab } = useSelector(
    (state) => state?.SettingReducer?.data,
  );

  // Get the Gujarat and Gujarat Metro data from the Redux store
  const Gujarat = useSelector((state) => state?.HomeSecondaryReducer?.data?.Gujarat);
  const GujaratMetro = useSelector((state) => state?.HomeSecondaryReducer?.data?.Gujaratmetro);

  // Get the Samagra API data from the Redux store
  const { data: samagraAPIData } = useSelector((state) => state?.CityResultReducer?.data);

  // Initialize the dispatch and navigate functions
  let dispatch = useDispatch();
  let navigate = useNavigate();

  // Initialize the state variables
  const [icon, seticon] = useState();
  const [activeTab, setActiveTab] = useState("Metro");
  const [myLocation, setmyLocation] = useState(localStorage.getItem("SandeshLocation"));
  const [activeTabSam, setActiveTabSam] = useState("samagra");
  const [apiData, setapiData] = useState([]);
  const [activeCity, setActiveCity] = useState(
    JSON.parse(localStorage.getItem("activeCity") ? localStorage.getItem("activeCity") : '[{"name":"Other Cities","url":"samagra"}]'),
  );

  // Fetch the Gujarat Samagra city data on mount
  useEffect(() => {
    dispatch(GujaratSamagraCityFetchData());
  }, []);

  // Set the active Samagra tab based on the default active tab
  useEffect(() => {
    if (!activeTabSam && defaultActiveSamagraTab?.value) {
      setActiveTabSam(defaultActiveSamagraTab?.value);
    }
  }, [defaultActiveSamagraTab?.value, activeTabSam]);

  // Fetch the city result data based on the active Samagra tab
  useEffect(() => {
    const timer = setTimeout(() => {
      if (activeTabSam) {
        let customLocation = "";
        if (myLocation) {
          const parseData = JSON.parse(myLocation);
          customLocation = "?lat=" + parseData.lat + "&long=" + parseData.long;
        }
        dispatch(CityResultFetchData(activeTabSam + customLocation));
      }
    }, 300);
    return () => {
      clearTimeout(timer);
    };
  }, [activeTabSam, myLocation]);

  // Update the myLocation state based on the local storage
  useEffect(() => {
    const timerLocation = setInterval(() => {
      if (myLocation !== localStorage.getItem("SandeshLocation")) {
        setmyLocation(localStorage.getItem("SandeshLocation"));
      }
    }, 500);
    return () => {
      clearInterval(timerLocation);
    };
  }, []);

  // Handle the visible city handler
  const visibleCityHandler = (citiesURL) => {
    citiesURL.push({ name: "Other Cities", url: "samagra" });
    citiesURL.reverse();
    localStorage.setItem("activeCity", JSON.stringify(citiesURL));
    setActiveCity(citiesURL);
    setActiveTabSam(citiesURL?.[0]?.url);
    dispatch(CityResultFetchData(citiesURL?.[0]?.url));
  };

  // Handle the share click events
  const onShareClick = (e) => {
    document.getElementById("metro-dropdown").classList.remove("d-none");
    e.preventDefault();
    e.stopPropagation();
    return false;
  };

  const onShareClickMet = (e) => {
    document.getElementById("metro-dropdown-inner").classList.remove("d-none");
    e.stopPropagation();
    e.preventDefault();
    return false;
  };

  const onShareClickSam = (e) => {
    document.getElementById("samagra-dropdown").classList.remove("d-none");
    e.preventDefault();
    e.stopPropagation();
    return false;
  };

  // Handle the left and right scroll events
  const leftScroll = (event) => {
   const conent = document.querySelector("#content-sam-home");
    conent.scrollLeft -= 100;
    event.preventDefault();
  };

  const rightScroll = (event) => {
    const conent = document.querySelector("#content-sam-home");
    conent.scrollLeft += 100;
    event.preventDefault();
  };

  // Fetch the metro data
  useEffect(() => {
    async function fetchData() {
      const myMetroData = await Get(HttpService.metro);
      setapiData(Object.entries(myMetroData?.data));
    }
    fetchData();
  }, []);

  useEffect(() => {
    const asd = document.getElementById("content-sam-home").scrollWidth;
    seticon(asd);
  });

  return (
    // Render the Gujarat section
    <section className="gujarat bg-gray samgraMetro">
      <div className="container">
        <div className="text-center">
          {/* Inject the afterPrimary script */}
          <InjectScript script={afterPrimary} key={Math.random()} />
        </div>
        <div className="row">
          <div className="col-md-12 col-lg-12 col-xl-12">
            <div className="row">
              <div className="col-md-6 col-lg-6 col-xl-6 mobile-margin-bottom removePaddingHover removePaddingLeft">
                {/* Render the Gujarat Metro section */}
                <div
                  className="single-blog"
                  onClickCapture={() =>
                    ReactGA.event({
                      category: "Homepage",
                      action: "Gujarat Metro",
                      label: "Read More",
                    })
                  }
                >
                  <Link target="_parent" to={"/gujarat"}>
                    <span className="badge badge-warning">Gujarat Metro</span>
                  </Link>
                  <Link
                    target="_parent"
                    to={"/gujarat/metro"}
                    className="readmore"
                  >
                    Read More
                  </Link>
                </div>

                {/* Render the Gujarat Metro carousel */}
                <div className="d-flex align-items-center guj-metro-carousel">
                  <ul className=" d-flex center-metro">
                    {/* Render the metro tabs */}
                    <div
                      className="nav-item"
                      onClickCapture={() =>
                        ReactGA.event({
                          category: "Homepage",
                          action: "Gujarat Metro",
                          label: "Metro",
                        })
                      }
                    >
                      <a
                        className={`nav-link guj-metro-carousel-tabs ${activeTab === "Metro"? "active-guj-metro-carousel" : ""}`}
                        data-toggle="tab"
                        onClick={() => setActiveTab(() => "Metro")}
                      >
                        Metro
                      </a>
                    </div>
                    {apiData?.map((els, index) => {
                      return (
                        <li
                          className="nav-item internal"
                          style={{ width: "auto" }}
                          key={index}
                          onClickCapture={() =>
                            ReactGA.event({
                              category: "Homepage",
                              action: "Gujarat Metro",
                              label: els[1]?.name,
                            })
                          }
                        >
                          <a
                            className={`nav-link guj-metro-carousel-tabs ${activeTab === els[1]?.name? "active-guj-metro-carousel" : ""}`}
                            data-toggle="tab"
                            onClick={() => setActiveTab(() => els[1]?.name)}
                          >
                            {els[1]?.name}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>

                {/* Render the Gujarat Metro content */}
                {GujaratMetro &&
                  GujaratMetro.length > 0 &&
                  GujaratMetro?.map((el) => (
                    <>
                      {activeTab === el?.name && el?.data?.length > 0
                       ? el?.data?.map((ele, idx) => (
                            <>
                              {idx === 0 && (
                                <CommonHeadCardContent
                                  dataClass="margin-bottom-guj"
                                  url={ele?.url}
                                  sandeshske={sandeshske}
                                  media={ele?.media}
                                  mediaimg={`${assetbaseUrl?.value}${ele?.media.replace("?resize=800,450", "")}?resize=653,367`}
                                  post_type={ele?.post_type}
                                  time={ele?.time}
                                  title={ele?.title}
                                  id={ele?.id}
                                  publish_date={ele?.publish_date}
                                />
                              )}
                              {idx > 0 && idx < 4 && (
                                <CommonContent
                                  url={ele?.url}
                                  src={`${assetbaseUrl?.value}${ele?.media.replace("?resize=800,450", "")}?resize=90,51`}
                                  post_type={ele?.post_type}
                                  time={ele?.time}
                                  publish_date={ele?.publish_date}
                                  category={ele?.tagline}
                                  title={ele?.title}
                                />

                              
                              )}
                            </>
                          ))
                        : ""}
                    </>
                  ))}
              </div>

              <div className="col-md-6 col-lg-6 col-xl-6 removePaddingHover">
                {/* Render the Gujarat Samagra section */}
                <div
                  className="single-blog"
                  onClickCapture={() =>
                    ReactGA.event({
                      category: "Homepage",
                      action: "Gujarat Samagra",
                      label: "Read More",
                    })
                  }
                >
                  <Link target="_parent" to={"/gujarat"}>
                    <span className="badge badge-warning">
                      Khabar Gujarat
                    </span>
                  </Link>
                  <Link
                    target="_parent"
                    to={"/gujarat/samagra"}
                    className="readmore"
                  >
                    Read More
                  </Link>
                </div>
                <div className="d-flex align-items-center">
                  <a
                    className="nav-link tab-bar-padding"
                    data-toggle="tab"
                    href="#food1"
                  >
                    {" "}
                    <LocationSelect visibleCity={visibleCityHandler} />
                  </a>
                  {icon > 611? (
                    <div className="float-left">
                      <button
                        className="buttonGujSamagra"
                        onClick={() => leftScroll()}
                      >
                        <i className="fa fa-angle-left" aria-hidden="true" />
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                  <ul className="center asd" id="content-sam-home">
                    {activeCity?.map((els, index) => {
                      return (
                        <li
                          className="nav-item internal"
                          style={{ width: "auto" }}
                          key={index}
                          onClickCapture={() =>
                            ReactGA.event({
                              category: "Homepage",
                              action: "Gujarat Samagra",
                              label: els?.name,
                            })
                          }
                        >
                          <a
                            className={`nav-link ${activeTabSam === els?.url? "active-guj-samagra w-100" : ""}`}
                            data-toggle="tab"
                            onClick={() => setActiveTabSam(els?.url)}
                            href={`#${els?.name}`}
                          >
                            {els?.name}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                  {icon > 611? (
                    <div className="float-right">
                      <button
                        className="buttonGujSamagra"
                        onClick={() => rightScroll()}
                      >
                        <i className="fa fa-angle-right" aria-hidden="true" />
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {samagraAPIData &&
                  samagraAPIData.length > 0 &&
                  samagraAPIData?.map((ele, idx) => (
                    <>
                      <>
                        {idx === 0 && (
                          <CommonHeadCardContent
                            dataClass="margin-bottom-guj"
                            url={ele?.url}
                            sandeshske={sandeshske}
                            media={ele?.media}
                            mediaimg={`${assetbaseUrl?.value}${ele?.media.replace("?resize=800,450", "")}?resize=653,367`}
                            post_type={ele?.post_type}
                            time={ele?.time}
                            title={ele?.title}
                            id={ele?.id}
                            publish_date={ele?.publish_date}
                          />
                        )}
                        {idx > 0 && idx < 4 && (
                          <CommonContent
                            url={ele?.url}
                            src={`${assetbaseUrl?.value}${ele?.media.replace("?resize=800,450", "")}?resize=90,51`}
                            post_type={ele?.post_type}
                            time={ele?.time}
                            publish_date={ele?.publish_date}
                            category={ele?.tagline}
                            title={ele?.title}
                          />

                        
                        )}
                      </>
                    </>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Gujarat;