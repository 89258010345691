import React from "react";
import Astrology from "./Astrology";

const Header = () => {
  // Return the header component
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12 my-3 gujarat-responsive-space-sm">
          <div className="tab-content">
            {/* Astrology tab pane */}
            <div className="tab-pane active" id="astrology">
              <Astrology />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;