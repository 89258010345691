import {
  LOGING_FETCH_DATA_SUCCESS,
  LOGING_HAVE_ERROR,
  LOGING_ARE_LOADING,
} from "../actionType";
import HttpService from "../../utils/httpService";
import { Post } from "../../services/api.service";

// Action creator function to handle different types of login actions
export function LogingAction(action, payload) {
  switch (action) {
    case LOGING_HAVE_ERROR:
      return {
        type: action,
        hasError: payload,
      };

    case LOGING_ARE_LOADING:
      return {
        type: action,
        isLoading: payload,
      };

    case LOGING_FETCH_DATA_SUCCESS:
      return {
        type: action,
        Login: payload,
      };

    default:
      return;
  }
}

// Async action creator function to fetch login data
export function LogingFetchData(userData, otp = undefined) {
  return (dispatch) => {
    try {
      return new Promise((resolve, reject) => {
        // Set the appropriate loading action based on the presence of OTP
        const url = otp ? HttpService.otpCheck : HttpService.loginCheck;
        dispatch(LogingAction(LOGING_ARE_LOADING, true));

        // Make a POST request based on the presence of OTP
        Post(url, userData).then(
          (result) => {
            dispatch(LogingAction(LOGING_FETCH_DATA_SUCCESS, result));
            resolve(result);
          },
          (error) => {
            reject(error);
          },
        );
      });
    } catch (error) {
      dispatch(LogingAction(LOGING_HAVE_ERROR, true));
    }
  };
}

// Async action creator function to fetch signup data
export function SignupFetchData(userSignup) {
  return (dispatch) => {
    try {
      return new Promise((resolve, reject) => {
        dispatch(LogingAction(LOGING_ARE_LOADING, true));
        Post(HttpService.signup, userSignup).then(
          (result) => {
            dispatch(LogingAction(LOGING_FETCH_DATA_SUCCESS, result));
            resolve(result);
          },
          (error) => {
            reject(error);
          },
        );
      });
    } catch (error) {
      dispatch(LogingAction(LOGING_HAVE_ERROR, true));
    }
  };
}

// Async action creator function to fetch signin data
export function SigninFetchData(userSignin) {
  return (dispatch) => {
    try {
      return new Promise((resolve, reject) => {
        dispatch(LogingAction(LOGING_ARE_LOADING, true));
        Post(HttpService.login, userSignin).then(
          (result) => {
            dispatch(LogingAction(LOGING_FETCH_DATA_SUCCESS, result));
            resolve(result);
            // Store user data in localStorage if signin is successful
            if (result?.user_data) {
              localStorage.setItem("access_token", result?.user_data?.token);
              localStorage.setItem("userInfo", JSON.stringify(result));
              localStorage.setItem(
                "userID",
                JSON.stringify(result?.user_data?.id),
              );
            }
          },
          (error) => {
            reject(error);
          },
        );
      });
    } catch (error) {
      dispatch(LogingAction(LOGING_HAVE_ERROR, true));
    }
  };
}
