// Import action types related to gallery data
import {
  GALLARY_ARE_LOADING,
  GALLARY_FETCH_DATA_SUCCESS,
  GALLARY_HAVE_ERROR,
} from "../actionType";

// Define the initial state for the GalleryReducer
const initialState = {
  data: [],
  loading: true,
  error: [], // Initialize error as null for better error handling
};

// Reducer function to handle gallery-related actions and update state accordingly
export function GallaryReducer(state = initialState, action) {
  switch (action.type) {
    // Handle error case when fetching gallery data
    case GALLARY_HAVE_ERROR:
      return {
        ...state,
        error: action.hasError, // Update the error state with the error from the action
        loading: false, // Set loading to false since the request is complete
      };

    // Handle loading state when gallery data is being fetched
    case GALLARY_ARE_LOADING:
      return {
        ...state,
        loading: action.isLoading, // Update the loading state with the loading status from the action
      };

    // Handle success case when gallery data is successfully fetched
    case GALLARY_FETCH_DATA_SUCCESS:
      return {
        ...state,
        data: action.Gallary, // Update the data state with the fetched gallery data
        loading: false, // Set loading to false since the request is complete
        error: null, // Clear any previous errors
      };

    // Return the current state by default if action type is not recognized
    default:
      return state;
  }
}
