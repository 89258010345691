// Import action types related to pages
import {
  PAGE_ARE_LOADING,
  PAGE_FETCH_DATA_SUCCESS,
  PAGE_HAVE_ERROR,
} from "../actionType";

// Define the initial state for the Page reducer
const initialState = {
  data: [], // Initialize data as an empty array
  loading: true, // Set loading to true initially
  error: [], // Initialize error as an empty array
};

// Reducer function to handle page-related actions and update state accordingly
export function PageReducer(state = initialState, action) {
  switch (action.type) {
    // Handle error case when fetching pages
    case PAGE_HAVE_ERROR:
      return {
        ...state,
        error: action.hasError, // Update the error state with the error from the action
        loading: false, // Set loading to false since the request is complete
      };

    // Handle loading state when pages are being fetched
    case PAGE_ARE_LOADING:
      return {
        ...state,
        loading: action.isLoading, // Update the loading state with the loading status from the action
      };

    // Handle success case when pages are successfully fetched
    case PAGE_FETCH_DATA_SUCCESS:
      return {
        ...state,
        data: action.Page, // Update the data state with the fetched pages
        loading: false, // Set loading to false since the request is complete
        error: [], // Clear any previous errors (assuming the success case means no error)
      };

    // Return the current state by default if action type is not recognized
    default:
      return state;
  }
}
