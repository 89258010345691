import React from "react";
import { Link } from "react-router-dom";

function CommonGalleryContent({ url, src, title, playicon }) {
  // Optional chaining for safer prop access
  const hasUrl = url !== undefined; // Check for URL existence

  const content = (
    <div className="skeleton-gallery">
      <img loading="lazy" src={src} alt={title} key={src} className="img-responsive" />
      {playicon && <p className="playicon-subhome"><i className="fas fa-play"></i></p>}
      <span className="caption mr-4 ellipsis">{title}</span>
    </div>
  );

  return (
    <div className="single-photo pr-4 share-common-card-content">
      {hasUrl ? (
        <Link target="_parent" to={`/${url}`}>
          {content}
        </Link>
      ) : (
        content // Render content even without URL
      )}
    </div>
  );
}

export default CommonGalleryContent;
