import React, { useEffect, useState } from "react";
import HttpService from "../../utils/httpService";
import { Get } from "../../services/api.service";
import { Helmet } from "react-helmet";

const Rss = () => {
  const [data, setData] = useState({}); // Use descriptive variable name

  // Fetch RSS data using useEffect hook
  useEffect(() => {
    async function fetchData() {
      const response = await Get(HttpService.rssURL);
      setData(response?.data || {}); // Handle potential empty response
    }
    fetchData();
  }, []);

  return (
    <>
      <section className="rss">
        <div className="container">
          {/* Set page title with Helmet */}
          <Helmet>
            <title>RSS Feeds | Sandesh</title>
          </Helmet>

          <div className="row my-3">
            <div className="col-md-2"></div>
            <div className="col-md-8">
              <h1>RSS Feeds</h1>

              {/* Description about RSS feeds */}
              <div className="rss-top-box">
                <span>
                  With billions of webpages, finding what you want can be tedious.
                  RSS (Really Simple Syndication) feeds deliver customized content
                  to your desktop. Sandesh.com offers free headlines (non-commercial
                  use) via RSS 2.0 for easy access to latest news.
                </span>
              </div>

              <div className="rss-feed-sec-title">
                <span>Available RSS Feeds by Category:</span>

                {/* Display category feeds conditionally */}
                {data.rss_category?.length > 0 ? (
                  <div className="row">
                    {data.rss_category.map((category) => (
                      <div className="col-md-3 rss-feed my-1 col-6" key={category.url}>
                        {/* Link to category RSS feed */}
                        <a target="_blank" href={`/rss/${category.url}.xml`} rel="noreferrer">
                          <i className="fa fa-rss mr-2"></i>
                          {category.name}
                        </a>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="col-md-12 text-center">Loading...</div>
                )}

                <span>Available RSS Feeds by City:</span>

                {/* Display city feeds conditionally */}
                {data.city?.length > 0 ? (
                  <div className="row">
                    {data.city.map((city) => (
                      <div className="col-md-3 rss-feed my-1 col-6" key={city.url}>
                        {/* Link to city RSS feed */}
                        <a target="_blank" href={`/rss/${city.url}-city.xml`} rel="noreferrer">
                          <i className="fa fa-rss mr-2"></i>
                          {city.name}
                        </a>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="col-md-12 text-center">Loading...</div>
                )}
              </div>

              {/* Instructions on using RSS feeds */}
              <div className="rss-use-box">
                <span>How can I use RSS?</span>
                <p>
                  Click on the section you're interested in and install a news
                  reader that displays RSS feeds. Many free and commercial
                  options are available. Once set to sandesh.com, the reader
                  will check for latest headlines.
                </p>
                <hr className="mb-1" />
                <span>Terms of Use</span>
                <ul>
                  <li>
                    sandesh.com RSS feeds are free for personal, non-commercial use.
                  </li>
                  <li>Attribution to sandesh.com is required when using these feeds.</li>
                  <li>For text attribution, use "sandesh.com".</li>
                  <li>For graphic attribution, use the provided sandesh.com logo.</li>
                </ul>
              </div>
            </div>
            <div className="col-md-2"></div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Rss;
