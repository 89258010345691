// Import action types related to home primary data
import {
  HOMEPRIMARY_FETCH_DATA_SUCCESS,
  HOMEPRIMARY_HAVE_ERROR,
  HOMEPRIMARY_ARE_LOADING,
} from "../actionType";

// Retrieve default data from local storage if available, otherwise set as an empty array
const defaultData = localStorage.getItem("SandeshHome") || [];
// Define the initial state for the HomePrimaryReducer
const initialState = {
  data: defaultData,
  loading: true,
  error: [], // Initialize error as null for better error handling
};

// Reducer function to handle home primary-related actions and update state accordingly
export function HomePrimaryReducer(state = initialState, action) {
  switch (action.type) {
    // Handle error case when fetching home primary data
    case HOMEPRIMARY_HAVE_ERROR:
      return {
        ...state,
        error: action.hasError, // Update the error state with the error from the action
        loading: false, // Set loading to false since the request is complete
      };

    // Handle loading state when home primary data is being fetched
    case HOMEPRIMARY_ARE_LOADING:
      return {
        ...state,
        loading: action.isLoading, // Update the loading state with the loading status from the action
      };

    // Handle success case when home primary data is successfully fetched
    case HOMEPRIMARY_FETCH_DATA_SUCCESS:
      return {
        ...state,
        data: action.homePrimary, // Update the data state with the fetched home primary data
        loading: false, // Set loading to false since the request is complete
        error: null, // Clear any previous errors
      };

    // Return the current state by default if action type is not recognized
    default:
      return state;
  }
}
