// Import necessary action types, HttpService, and Post function
import {
  POST_FETCH_DATA_SUCCESS,
  POST_HAVE_ERROR,
  POST_ARE_LOADING,
} from "../actionType";
import HttpService from "../../utils/httpService";
import { Post } from "../../services/api.service";

// Action creator function to handle different types of post actions
export function PostAction(action, payload) {
  switch (action) {
    case POST_HAVE_ERROR:
      // Return action object indicating error
      return {
        type: action,
        hasError: payload,
      };

    case POST_ARE_LOADING:
      // Return action object indicating loading state and resetting Post data to null
      return {
        type: action,
        isLoading: payload,
        Post: null,
      };

    case POST_FETCH_DATA_SUCCESS:
      // Return action object containing fetched post data
      return {
        type: action,
        Post: payload,
      };

    default:
      return;
  }
}

// Async action creator function to fetch post data based on provided URL
export function PostFetchData(url) {
  return (dispatch) => {
    // Dispatch action to indicate data loading
    dispatch(PostAction(POST_ARE_LOADING, true));

    // Make a POST request to fetch post data based on provided URL
    return Post(HttpService.post, { url: url })
      .then((result) => {
        // Dispatch success action with fetched post data
        dispatch(PostAction(POST_FETCH_DATA_SUCCESS, result.data));
        // Resolve the promise with the result
        return result;
      })
      .catch((error) => {
        // Dispatch error action if request fails and reject the promise
        dispatch(PostAction(POST_HAVE_ERROR, true));
        return Promise.reject(error);
      });
  };
}
