import React from "react";
import { Link } from "react-router-dom";
import ShareCommon from "./component/ShareCommon";

// Functional component for displaying common content
const CommonContent = (props) => {
  // Function to handle share button click
  const onShareClick = (e) => {
    document.getElementById("common-share-dropdown").classList.remove("d-none");
    e.preventDefault();
    e.stopPropagation();
    return false;
  };

  return (
    <div className={`custom-card ${props?.dataclass} ${props?.dataclass2 ? "share-common-trend" : "share-common"}  ${props?.dataclass3 ? "min-h-video-page" : ""}`}>
      {props?.url ? (
        <Link target="_parent" to={`/${props.url}`} className="clearfix a">
          <div className="float-left">
            <div className="skeleton-sub">
              <img
                loading="lazy"
                src={props.src}
                alt={props.title}
                key={props.src}
              />
              <span className="button-hover subvideo-tagline">
                {props?.time}{" "}
              </span>
              {props?.type === "video" ? (
                <p className="playicon-subhome-subcategory">
                  <i className="fas fa-play"></i>
                </p>
              ) : (
                ""
              )}
            </div>
            {props.post_type === 3 || props.post_type === 4 ? (
              <span className="button-hover timer_ subvideo-tagline">
                {props.time}{" "}
              </span>
            ) : (
              ""
            )}
            {props.publish_date ? (
              <span className="blog-time">
                <i className="far fa-clock mr-2"></i>
                {props.publish_date}
              </span>
            ) : (
              ""
            )}
          </div>
          <div className="">
            <p className="color-red m-0">{props.category}</p>
            <p className="ellipsis">{`${props.title}`}</p>
          </div>
          <div class="dropleft">
            <button onClick={(e) => onShareClick(e)} className="dropbtn ml-2">
              <i class="fa fa-share-alt"></i>
            </button>
            <div class="dropdown-menu">
              <ShareCommon
                id="common-share-dropdown"
                url={props.url}
                media={props.src}
                title={props.title}
                dataid={props.id}
              />
            </div>
          </div>
        </Link>
      ) : (
        <a
          href="javascript:void(0)"
          className="share-common-trend-video share-common clearfix a"
        >
          <div className="float-left">
            <div className="skeleton-sub">
              <img
                loading="lazy"
                src={props.src}
                alt={props.title}
                key={Math.random()}
              />
              <span className="button-hover subvideo-tagline">
                {props?.time}{" "}
              </span>
              {props?.type === "video" ? (
                <p className="playicon-subhome-subcategory">
                  <i className="fas fa-play"></i>
                </p>
              ) : (
                ""
              )}
            </div>
            {props.post_type === 3 || props.post_type === 4 ? (
              <span className="button-hover timer_ subvideo-tagline">
                {props.time}{" "}
              </span>
            ) : (
              ""
            )}
            {props.publish_date ? (
              <span className="blog-time">
                <i className="far fa-clock mr-2"></i>
                {props.publish_date}
              </span>
            ) : (
              ""
            )}
          </div>
          <div className="">
            <p className="color-red m-0">{props.category}</p>
            <p className="ellipsis">{`${props.title}`}</p>
          </div>
          <div class={`dropleft ${props?.bg === "black" ? "bg-black" : ""}`}>
            <button
              onClickCapture={(e) => onShareClick(e)}
              className="dropbtn ml-2"
            >
              <i class="fa fa-share-alt"></i>
            </button>
            <div class="dropdown-menu">
              <ShareCommon
                id="common-share-dropdown"
                url={props.url}
                media={props.src}
                title={props.title}
                dataid={props.id}
              />
            </div>
          </div>
        </a>
      )}
    </div>
  );
};

export default CommonContent;