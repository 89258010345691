// Import necessary action types, HttpService, and Get function
import {
  PAGE_ARE_LOADING,
  PAGE_FETCH_DATA_SUCCESS,
  PAGE_HAVE_ERROR,
} from "../actionType";
import HttpService from "../../utils/httpService";
import { Get } from "../../services/api.service";

// Action creator function to handle different types of page actions
export function PageAction(action, payload) {
  switch (action) {
    case PAGE_HAVE_ERROR:
      // Return action object indicating error and setting isLoading to false
      return {
        type: action,
        hasError: payload,
        isLoading: false,
      };

    case PAGE_ARE_LOADING:
      // Return action object indicating loading state
      return {
        type: action,
        isLoading: payload,
      };

    case PAGE_FETCH_DATA_SUCCESS:
      // Return action object containing fetched page data and setting isLoading to false
      return {
        type: action,
        Page: payload,
        isLoading: false,
      };

    default:
      return;
  }
}

// Async action creator function to fetch page data based on provided id
export function pageFetchData(id) {
  return (dispatch) => {
    // Dispatch action to indicate data loading
    dispatch(PageAction(PAGE_ARE_LOADING, true));

    // Make a GET request to fetch page data based on provided id
    return Get(HttpService?.baseurl + id)
      .then((result) => {
        // Dispatch success action with fetched page data
        dispatch(PageAction(PAGE_FETCH_DATA_SUCCESS, result.data));
        // Resolve the promise with the result
        return result;
      })
      .catch((error) => {
        // Dispatch error action if request fails and reject the promise
        dispatch(PageAction(PAGE_HAVE_ERROR, true));
        return Promise.reject(error);
      });
  };
}
