import {
  EPAPERIMAGE_ARE_LOADING,
  EPAPERIMAGE_FETCH_DATA_SUCCESS,
  EPAPERIMAGE_HAVE_ERROR,
} from "../actionType.js";
import HttpService from "../../utils/httpService";
import { Get } from "../../services/api.service";

// Action creator function to handle different types of ePaper image actions
export function ePaperImageAction(action, payload) {
  switch (action) {
    case EPAPERIMAGE_HAVE_ERROR:
      return {
        type: action,
        hasError: payload,
      };

    case EPAPERIMAGE_ARE_LOADING:
      return {
        type: action,
        isLoading: payload,
      };

    case EPAPERIMAGE_FETCH_DATA_SUCCESS:
      return {
        type: action,
        ePaperImage: payload,
      };

    default:
      return;
  }
}

// Async action creator function to fetch ePaper image data
export function ePaperImageFetchData(date) {
  return (dispatch) => {
    try {
      // Dispatch action indicating data is loading
      dispatch(ePaperImageAction(EPAPERIMAGE_ARE_LOADING, true));

      // Fetch ePaper image data using GET request
      return new Promise((resolve, reject) => {
        Get(HttpService.ePaperImage + "?date=" + date).then(
          (result) => {
            // Dispatch action with fetched ePaper image data
            dispatch(
              ePaperImageAction(EPAPERIMAGE_FETCH_DATA_SUCCESS, result.data),
            );
            resolve(result);
          },
          (error) => {
            reject(error); // Reject promise with error if fetching fails
          },
        );
      });
    } catch (error) {
      // Dispatch action if there's an error in fetching data
      dispatch(ePaperImageAction(EPAPERIMAGE_HAVE_ERROR, true));
    }
  };
}
