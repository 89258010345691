import {
  GALLARY_FETCH_DATA_SUCCESS,
  GALLARY_HAVE_ERROR,
  GALLARY_ARE_LOADING,
} from "../actionType";
import HttpService from "../../utils/httpService";
import { Get } from "../../services/api.service";

// Action creator function to handle different types of gallery actions
export function GalleryAction(action, payload) {
  switch (action) {
    case GALLARY_HAVE_ERROR:
      return {
        type: action,
        hasError: payload,
      };

    case GALLARY_ARE_LOADING:
      return {
        type: action,
        isLoading: payload,
      };

    case GALLARY_FETCH_DATA_SUCCESS:
      return {
        type: action,
        Gallary: payload,
      };

    default:
      return;
  }
}

// Async action creator function to fetch gallery data
export function gallaryFetchData(limit) {
  return (dispatch) => {
    try {
      // Dispatch action indicating data is loading
      dispatch(GalleryAction(GALLARY_ARE_LOADING, true));

      // Fetch gallery data using GET request
      return new Promise((resolve, reject) => {
        Get(HttpService.gallary).then(
          (result) => {
            // Dispatch action with fetched gallery data
            dispatch(GalleryAction(GALLARY_FETCH_DATA_SUCCESS, result.data));
            resolve(result);
          },
          (error) => {
            reject(error); // Reject promise with error if fetching fails
          },
        );
      });
    } catch (error) {
      // Dispatch action if there's an error in fetching data
      dispatch(GalleryAction(GALLARY_HAVE_ERROR, true));
    }
  };
}
