// Import action types related to home secondary data
import {
  HOMESECONDARY_FETCH_DATA_SUCCESS,
  HOMESECONDARY_HAVE_ERROR,
  HOMESECONDARY_ARE_LOADING,
} from "../actionType";

// Retrieve default data from local storage if available, otherwise set as an empty array
const defaultData = localStorage.getItem("SandeshHomeSub") || [];
// Define the initial state for the HomeSecondaryReducer
const initialState = {
  data: defaultData,
  loading: true,
  error: [], // Initialize error as null for better error handling
};

// Reducer function to handle home secondary-related actions and update state accordingly
export function HomeSecondaryReducer(state = initialState, action) {
  switch (action.type) {
    // Handle error case when fetching home secondary data
    case HOMESECONDARY_HAVE_ERROR:
      return {
        ...state,
        error: action.hasError, // Update the error state with the error from the action
        loading: false, // Set loading to false since the request is complete
      };

    // Handle loading state when home secondary data is being fetched
    case HOMESECONDARY_ARE_LOADING:
      return {
        ...state,
        loading: action.isLoading, // Update the loading state with the loading status from the action
      };

    // Handle success case when home secondary data is successfully fetched
    case HOMESECONDARY_FETCH_DATA_SUCCESS:
      return {
        ...state,
        data: action.homeSecondary, // Update the data state with the fetched home secondary data
        loading: false, // Set loading to false since the request is complete
        error: null, // Clear any previous errors
      };

    // Return the current state by default if action type is not recognized
    default:
      return state;
  }
}
