import React, { useState, useEffect } from "react";

const ScrollButton = () => {
  const [isVisible, setIsVisible] = useState(false); 

  // Track scroll position and update visibility state on component mount and scroll
  useEffect(() => {
    const handleScroll = () => {
      const scrolled = document.documentElement.scrollTop;
      setIsVisible(scrolled > 300); // Concise logic
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup function to remove event listener on component unmount
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleClick = () => {
    scrollToTop();
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div
        className="topicon-design icone-design float-right"
        style={{ display: isVisible ? "inline" : "none" }}
        onClick={handleClick} // Use separate function for better separation of concerns
      >
        <i className="fas fa-arrow-up"></i>{" "}
      </div>
    </>
  );
};

export default ScrollButton;
