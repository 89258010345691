// Import necessary action types and services
import {
  VIDEO_FETCH_DATA_SUCCESS,
  VIDEO_HAVE_ERROR,
  VIDEO_ARE_LOADING,
} from "../actionType";
import HttpService from "../../utils/httpService";
import { Get } from "../../services/api.service";

// Action creator function to handle different types of Video actions
export function VideoAction(action, payload) {
  switch (action) {
    case VIDEO_HAVE_ERROR:
      // Return action object indicating error
      return {
        type: action,
        hasError: payload,
      };

    case VIDEO_ARE_LOADING:
      // Return action object indicating loading state
      return {
        type: action,
        isLoading: payload,
      };

    case VIDEO_FETCH_DATA_SUCCESS:
      // Return action object containing Video data
      return {
        type: action,
        Video: payload,
      };

    default:
      return;
  }
}

// Action creator function to fetch Video data
export function videoFetchData() {
  return (dispatch) => {
    try {
      // Dispatch loading action
      dispatch(VideoAction(VIDEO_ARE_LOADING, true));
      return new Promise((resolve, reject) => {
        // Fetch Video data
        Get(HttpService.video).then(
          (result) => {
            // Dispatch success action with Video data
            dispatch(VideoAction(VIDEO_FETCH_DATA_SUCCESS, result.data));
            resolve(result);
          },
          (error) => {
            // Dispatch error action if request fails
            reject(error);
          }
        );
      });
    } catch (error) {
      // Dispatch error action if an error occurs during fetch
      dispatch(VideoAction(VIDEO_HAVE_ERROR, true));
    }
  };
}
