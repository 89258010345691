import React, { useState, useEffect } from "react";
import "react-multi-carousel/lib/styles.css";
import { useSelector } from "react-redux";
import InjectScript from "../CustomScript";

const GallerySpotted = () => {
  // Get the Spotted gallery data from the Redux store
  const GallerylistData = useSelector((state) => state?.HomeSecondaryReducer?.data?.Spottedgallery);

  // Initialize the Gallerylist state with the initial data or an empty array
  const [Gallerylist, setGallerylist] = useState(GallerylistData || []);

  // Update the Gallerylist state when the GallerylistData changes
  useEffect(() => {
    setGallerylist(JSON.parse(JSON.stringify(GallerylistData || [])));
  }, [GallerylistData]);

  // Define the ad script as a string
  const belowCelebduniya = `
    <div id='div-gpt-ad-1686047749606-0' style='min-width: 728px; min-height: 90px;'>
      <script>
        window.googletag = window.googletag || {cmd: []};
        googletag.cmd.push(function() {
          googletag.defineSlot('/22678428348/SDPL_HP_BelowCelebDuniya_728x90', [728, 90], 'div-gpt-ad-1686047749606-0').addService(googletag.pubads());
          googletag.pubads().enableSingleRequest();
          googletag.enableServices();
        });
      </script>
      <script>
        googletag.cmd.push(function() { googletag.display('div-gpt-ad-1686047749606-0'); });
      </script>
    </div>
  `;

  // Render the ad script only on desktop screens (width > 991px)
  return (
    <>
      {window.innerWidth > 991? (
        <div className="">
          <div className="text-center">
            {/* Inject the ad script */}
            <InjectScript script={belowCelebduniya} key={belowCelebduniya} />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default GallerySpotted;