import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CommonContent from "../CommonContent";
import InjectScript from "../CustomScript";

const Astro = () => {
  const { 21: assetbaseUrl } = useSelector(
    (state) => state?.SettingReducer?.data,
  );
  const { 47: astroGanesha } = useSelector(
    (state) => state?.SettingReducer?.data,
  );
  const { 55: electionImage } = useSelector(
    (state) => state?.SettingReducer?.data,
  );
  const Astrologylist = useSelector(
    (state) => state?.HomeSecondaryReducer?.data?.Astrology,
  );
  const Horoscope = useSelector((state) => state?.HoroscopeReducer?.data);
  const [tabactivecls, settabactivecls] = useState("મેષ");
  const [content, setContent] = React.useState("");

  const tabActive = (data) => {
    settabactivecls(data);
  };

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1200 },
      items: 7,
    },
    desktop: {
      breakpoint: { max: 1200, min: 991 },
      items: 7,
    },
    tablet: {
      breakpoint: { max: 991, min: 515 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 515, min: 0 },
      items: 3,
    },
  };
  useEffect(() => {
    settabactivecls(Horoscope?.[0]?.name_guj);
    setContent(Horoscope?.[0]?.detail);
  }, [Horoscope]);

  

  const astroHardcode12 = `
  <div id='div-gpt-ad-1670307396791-0' style='min-width: 300px; min-height: 250px;'>
    <script>
      window.googletag = window.googletag || {cmd: []};
      googletag.cmd.push(function() {
        googletag.defineSlot('/22678428348/SDPL_HP_Desktop_BTF_300X600', [[300, 600], [300, 250]], 'div-gpt-ad-1670307396791-0').addService(googletag.pubads());
        googletag.pubads().enableSingleRequest();
        googletag.pubads().disableInitialLoad();
        googletag.enableServices();
      });
    </script>
    <script>
      googletag.cmd.push(function() { googletag.display('div-gpt-ad-1670307396791-0'); });
    </script>
  </div>
`;

const astroHardcode174 = `
  <div id='div-gpt-ad-1672904126012-0' style='min-width: 300px; min-height: 250px;'>
    <script>
      window.googletag = window.googletag || {cmd: []};
      googletag.cmd.push(function() {
        googletag.defineSlot('/22678428348/Mweb_HP_Desktop_BTF_Astro_300X250', [300, 250], 'div-gpt-ad-1672904126012-0').addService(googletag.pubads());
        googletag.pubads().enableSingleRequest();
        googletag.pubads().disableInitialLoad();
        googletag.enableServices();
      });
    </script>
    <script>
      googletag.cmd.push(function() { googletag.display('div-gpt-ad-1672904126012-0'); });
    </script>
  </div>
`;
  return (
    <>
      <section className="astro">
        <div className="container">
          <div className="row">
            <div className="col-md-9 col-lg-9 col-xl-9">
              <span className="badge badge-danger">Astrology</span>
              <Link
                target="_parent"
                to="/astrology"
                onClickCapture={() =>
                  ReactGA.event({
                    category: "Homepage",
                    action: "Astrology",
                    label: "Read More",
                  })
                }
                className="readmore"
              >
                Read More
              </Link>
              <div className="col-12 p-0">
                <Carousel
                  swipeable={true}
                  draggable={false}
                  infinite={false}
                  showDots={false}
                  autoPlaySpeed={5000000}
                  autoPlay={false}
                  renderArrowsWhenDisabled={true}
                  responsive={responsive}
                  ssr={true}
                  customTransition="transform 750ms ease-in-out"
                >
                  {Horoscope?.length > 0 &&
                    Horoscope?.map((els, idx) => (
                      <div
                        className="astro-carousel"
                        onClick={() => {
                          tabActive(els?.name_guj);
                          setContent(els?.detail);
                        }}
                      >
                        <p className={`astro-carousel-title margin-button`}>
                          {els?.name_guj}
                        </p>
                        <div className={`astro-carousel-img `}>
                          <img
                            loading="lazy"
                            className="border-radius-50"
                            src={`${assetbaseUrl?.value}${els?.media}`}
                            alt=""
                          />
                        </div>
                        <p
                          className={`astro-carousel-title ${tabactivecls === els?.name_guj ? "astro-carousel-title-active" : ""}`}
                        >
                          {els?.name}
                        </p>
                      </div>
                    ))}
                </Carousel>
              </div>
              <div className="astro-active-tab mt-3 bg-gray">
                <div className="astro-tabs-content">
                  <div className="astro-active-tab-content ">
                    <p className="font-size-span">
                      {" "}
                      <i
                        style={{
                          color: "#ae0a0b",
                          fontStyle: "normal",
                          fontWeight: "bold",
                        }}
                      >
                        {tabactivecls}:{" "}
                      </i>{" "}
                      {content}{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                {Astrologylist?.length > 0 &&
                  Astrologylist?.map((els, idx) => (
                    <div className="col-md-6">
                      <CommonContent
                        url={els?.url}
                        src={`${assetbaseUrl?.value}${els?.media.replace("?resize=800,450", "")}?resize=90,51`}
                        post_type={els?.post_type}
                        time={els?.time}
                        publish_date={els?.publish_date}
                        category={els?.tagline}
                        title={els?.title}
                      />
                    </div>
                  ))}
              </div>
            </div>
            <div className="col-12 col-md-3 col-lg-3 col-xl-3 gujarat-responsive-space-sm">
              <div className="ad-section">
                <InjectScript
                  key={Math.random()}
                  script={
                    window.innerWidth > 991 ? astroHardcode12 : astroHardcode174
                  }
                />

              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Astro;
