import {
  CITY_FETCH_DATA_SUCCESS,
  CITY_HAVE_ERROR,
  CITY_ARE_LOADING,
} from "../actionType";
import HttpService from "../../utils/httpService";
import { Get } from "../../services/api.service";

// Action creator function to create actions for city data
export function CityAction(action, payload) {
  switch (action) {
    case CITY_HAVE_ERROR:
      return {
        type: action,
        hasError: payload,
      };

    case CITY_ARE_LOADING:
      return {
        type: action,
        isLoading: payload,
      };

    case CITY_FETCH_DATA_SUCCESS:
      return {
        type: action,
        city: payload,
      };

    default:
      return; // Return undefined by default if action type is not recognized
  }
}

// Async action creator function to fetch city data from the server
export function CityFetchData() {
  return (dispatch) => {
    try {
      return new Promise((resolve, reject) => {
        dispatch(CityAction(CITY_ARE_LOADING, true)); // Dispatch loading action
        // Fetch city data from the server
        Get(HttpService.city).then(
          (result) => {
            // Dispatch success action with the fetched data
            dispatch(CityAction(CITY_FETCH_DATA_SUCCESS, result));
            resolve(result); // Resolve promise with the fetched data
          },
          (error) => {
            reject(error); // Reject promise if there is an error
          },
        );
      });
    } catch (error) {
      dispatch(CityAction(CITY_HAVE_ERROR, true)); // Dispatch error action if an exception occurs
    }
  };
}
