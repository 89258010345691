import React, { useEffect } from "react";

const InjectScript = React.memo(({ script, type }) => {
  const divRef = React.useRef(null);

  useEffect(() => {
    // Check for valid ref and script before proceeding (avoid unnecessary work)
    if (!divRef.current || !script) return;

    const doc = document.createRange().createContextualFragment(script);

    // Clear existing content and append script fragment atomically
    divRef.current.textContent = ""; // Use textContent for script injection (security best practice)
    divRef.current.appendChild(doc);

    // Optional delay for specific script types (if needed)
    if (type === "taboola") {
      setTimeout(() => {
        // Double-check for validity before appending again (potential race condition)
        if (divRef.current && doc) {
          divRef.current.appendChild(doc);
        }
      }, 1000);
    }
  }, [script, type]); // Dependency array for useEffect

  return <div key={Math.random()} ref={divRef} />;
});

export default InjectScript;
