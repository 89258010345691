import axios from "axios";

// Redirects to maintenance page
function maintenance() {
  window.location.href = "/maintenance";
}

// Redirects to home page
function maintenanceClose() {
  window.location.href = "/";
}

// Redirects on network error
function errorRedirect(msg) {
  if (msg === "Network Error") {
  }
}

// Function to perform POST request
export function Post(url, payload, config) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, payload, config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        errorRedirect(e.message);
        reject(e);
      });
  });
}

// Function to perform PUT request
export function Put(url, payload, config) {
  return new Promise((resolve, reject) => {
    axios
      .put(url, payload, config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        errorRedirect(e.message);
        reject(e);
      });
  });
}

// Function to perform GET request
export function Get(url, config) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, config)
      .then((response) => {
        // Check if maintenance mode is enabled
        if (url.includes("setting")) {
          if (response.data) {
            if (parseInt(response.data?.data?.[1]?.value) === 1) {
              if ("/maintenance" !== window.location.pathname) {
                maintenance();
              }
            } else if (parseInt(response.data?.data?.[1]?.value) === 0) {
              if ("/maintenance" === window.location.pathname) {
                maintenanceClose();
              }
            }
          }
        }
        resolve(response.data);
      })
      .catch((e) => {
        errorRedirect(e.message);
        reject(e);
      });
  });
}

// Function to perform DELETE request
export function Delete(url, config) {
  return new Promise((resolve, reject) => {
    axios
      .delete(url, config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        errorRedirect(e.message);
        reject(e);
      });
  });
}
