// Import necessary action types and services
import {
  TITHI_ARE_LOADING,
  TITHI_FETCH_DATA_SUCCESS,
  TITHI_HAVE_ERROR,
} from "../actionType";
import HttpService from "../../utils/httpService";
import { Get } from "../../services/api.service";

// Action creator function to handle different types of Tithi actions
export function TithiAction(action, payload) {
  switch (action) {
    case TITHI_HAVE_ERROR:
      // Return action object indicating error
      return {
        type: action,
        hasError: payload,
      };

    case TITHI_ARE_LOADING:
      // Return action object indicating loading state
      return {
        type: action,
        isLoading: payload,
      };

    case TITHI_FETCH_DATA_SUCCESS:
      // Return action object containing Tithi data
      return {
        type: action,
        Tithi: payload,
      };

    default:
      return;
  }
}

// Action creator function to fetch Tithi data
export function tithiFetchData() {
  return (dispatch) => {
    // Dispatch loading action
    dispatch(TithiAction(TITHI_ARE_LOADING, true));
    try {
      // Fetch Tithi data
      return new Promise((resolve, reject) => {
        Get(HttpService.tithi).then(
          (result) => {
            // Dispatch success action with Tithi data
            dispatch(TithiAction(TITHI_FETCH_DATA_SUCCESS, result.data));
            resolve(result);
          },
          (error) => {
            // Dispatch error action if request fails
            reject(error);
          }
        );
      });
    } catch (error) {
      // Dispatch error action if an error occurs during fetch
      dispatch(TithiAction(TITHI_HAVE_ERROR, true));
    }
  };
}
