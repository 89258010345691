import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import logo from "../../assets/logo.png";

/**
 * Maintenance component
 */
const Maintenance = () => {
  /**
   * State to store the maintenance message
   */
  const [maintenanceMessage, setMaintenanceMessage] = useState("");

  /**
   * Get the setting data from the Redux store
   */
  const setting = useSelector((state) => state?.SettingReducer?.data);

  /**
   * Reload the page after 2 seconds
   */
  useEffect(() => {
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  }, []);

  /**
   * Update the maintenance message when the setting changes
   */
  useEffect(() => {
    if (setting?.[1]?.value === "1") {
      setMaintenanceMessage(setting?.[2]?.value);
    }
  }, [setting]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12 py-5">
          <div className="maintenanceMessage">
            <img loading="lazy" style={{ filter: "none" }} src={logo} alt="" />
            <div
              /**
               * Use dangerouslySetInnerHTML to render the maintenance message as HTML
               * Replace newline characters with <br> tags
               */
              dangerouslySetInnerHTML={{
                __html: maintenanceMessage
                  ? maintenanceMessage.replace(/(?:\r\n|\r|\n)/g, "<br>")
                  : "Please wait...",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Maintenance;