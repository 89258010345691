import React from 'react';
import HelperScript from '../../utils/helper';

const AdTwoVideo = () => {
  const adScripts = {
    small: `
      <div id='div-gpt-ad-1646387306029-0' style='min-width: 300px; min-height: 50px;'>
        <script>
          window.googletag = window.googletag || {cmd: []};
          googletag.cmd.push(function() {
            googletag.defineSlot('/22678428348/Mweb_HP_Desktop_BTF3_300X100', [[320, 50], [300, 100]], 'div-gpt-ad-1646387306029-0').addService(googletag.pubads());
            googletag.pubads().enableSingleRequest();
            googletag.pubads().disableInitialLoad();
            googletag.enableServices();
          });
        </script>
        <script>
          googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646387306029-0'); });
        </script>
      </div>
    `,
    large: `
      <div id='div-gpt-ad-1646034692019-0' style='min-width: 970px; min-height: 90px;'>
        <script>
          window.googletag = window.googletag || {cmd: []};
          googletag.cmd.push(function() {
            googletag.defineSlot('/22678428348/SDPL_Trending_Desktop_970X90', [970, 90], 'div-gpt-ad-1646034692019-0').addService(googletag.pubads());
            googletag.pubads().enableSingleRequest();
            googletag.pubads().disableInitialLoad();
            googletag.enableServices();
          });
        </script>
        <script>
          googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646034692019-0'); });
        </script>
      </div>
    `,
  };
// Return the JSX for the AdTwoVideo component
  return (
    <section className="ad-2">
      <div className="container">
        <div className="row ad-side-head text-center">
          <div className="col-md-12 ad-side">
            <div className="ad-section">
             {/* Render the HelperScript component with arguments for small and large ads */}
              <HelperScript argSmall={206} argLarge={102} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdTwoVideo;