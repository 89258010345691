import {
  EPAPER_MENU_FETCH_DATA_SUCCESS,
  EPAPER_MENU_HAVE_ERROR,
  EPAPER_MENU_ARE_LOADING,
} from "../actionType";
import HttpService from "../../utils/httpService";
import { Get } from "../../services/api.service";

// Action creator function to handle different types of ePaper menu actions
export function ePaperMenuAction(action, payload) {
  switch (action) {
    case EPAPER_MENU_HAVE_ERROR:
      return {
        type: action,
        hasError: payload,
      };

    case EPAPER_MENU_ARE_LOADING:
      return {
        type: action,
        isLoading: payload,
      };

    case EPAPER_MENU_FETCH_DATA_SUCCESS:
      return {
        type: action,
        ePaperMenu: payload,
      };

    default:
      return;
  }
}

// Async action creator function to fetch ePaper menu data
export function ePaperMenuFetchData() {
  return (dispatch) => {
    try {
      // Dispatch action indicating data is loading
      dispatch(ePaperMenuAction(EPAPER_MENU_ARE_LOADING, true));

      // Fetch ePaper menu data using GET request
      return new Promise((resolve, reject) => {
        Get(HttpService.ePaperMenu).then(
          (result) => {
            // Dispatch action with fetched ePaper menu data
            dispatch(ePaperMenuAction(EPAPER_MENU_FETCH_DATA_SUCCESS, result));
            resolve(result);
          },
          (error) => {
            reject(error); // Reject promise with error if fetching fails
          },
        );
      });
    } catch (error) {
      // Dispatch action if there's an error in fetching data
      dispatch(ePaperMenuAction(EPAPER_MENU_HAVE_ERROR, true));
    }
  };
}
