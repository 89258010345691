import React from "react";
import ReactGA from "react-ga4";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import sandeshske from "../../assets/sandeshske.png";
import CommonContent from "../CommonContent";
import InjectScript from "../CustomScript";
import ShareCommon from "../component/ShareCommon";

const Lifestyle = () => {
  const { 21: assetbaseUrl } = useSelector(
    (state) => state?.SettingReducer?.data,
  );
  const Supplement = useSelector(
    (state) => state?.HomeSecondaryReducer?.data?.Supplement,
  );
  const Technologylist = useSelector(
    (state) => state?.HomeSecondaryReducer?.data?.Technology,
  );
  const Foodlist = useSelector(
    (state) => state?.HomeSecondaryReducer?.data?.Food,
  );
  const Travellist = useSelector(
    (state) => state?.HomeSecondaryReducer?.data?.Travel,
  );
  const Relationshiplist = useSelector(
    (state) => state?.HomeSecondaryReducer?.data?.Relationship,
  );

  const onShareClick = (e) => {
    document.getElementById("common-share-dropdown").classList.remove("d-none");
    e.preventDefault();
    return false;
  };

  const belowTechnologyMweb =
    "" +
    "<div id='div-gpt-ad-1657893316146-0' style='min-width: 300px; min-height: 100px;'>" +
    "<script>" +
    "window.googletag = window.googletag || {cmd: []};" +
    "googletag.cmd.push(function() {" +
    "googletag.defineSlot('/22678428348/Mweb_HP_Abovefoodandtravel_300X100', [300, 100], 'div-gpt-ad-1657893316146-0').addService(googletag.pubads());" +
    "googletag.pubads().enableSingleRequest();" +
    "googletag.enableServices();" +
    "});" +
    "</script>" +
    "" +
    "<script>" +
    "googletag.cmd.push(function() { googletag.display('div-gpt-ad-1657893316146-0'); });" +
    " </script>" +
    "</div>" +
  
    "";

  return (
    <>
      <section className="lifestyle bg-gray removeTimes">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-4 col-xl-4">
              <div className="single-blog">
                <span className="badge badge-warning">Supplements</span>
                <Link
                  target="_parent"
                  to="/supplement"
                  onClickCapture={() =>
                    ReactGA.event({
                      category: "Homepage",
                      action: "Supplements",
                      label: "Read More",
                    })
                  }
                  className="readmore"
                >
                  Read More
                </Link>
                {Supplement?.length > 0 && (
                  <>
                    <Link
                      target="_parent"
                      to={`/${Supplement[0]?.url}`}
                      className="main-blogs share-blog"
                    >
                      <div className="skeleton-sub">
                        <img
                          loading="lazy"
                          src={
                            !Supplement?.[0]?.media
                              ? sandeshske
                              : `${assetbaseUrl?.value}${Supplement?.[0]?.media.replace("?resize=800,450", "")}?resize=425,240`
                          }
                          alt=""
                          className="img-responsive"
                        />
                      </div>
                      {Supplement?.[0]?.post_type === 3 ||
                      Supplement?.[0]?.post_type === 4 ? (
                        <p className="button-hover timer_ subvideo-tagline">
                          {Supplement?.[0]?.time}
                        </p>
                      ) : (
                        ""
                      )}
                      <div className="blog-details">
                        <p className="ellipsis">{Supplement[0]?.title}</p>
                        <span className="blog-time">
                          <i className="far fa-clock"></i>
                          {Supplement[0]?.publish_date}
                        </span>
                      </div>
                      <div class="dropleft">
                        <button
                          onClick={(e) => onShareClick(e)}
                          className="dropbtn ml-2"
                        >
                          <i class="fa fa-share-alt"></i>
                        </button>
                        <div class="dropdown-menu">
                          <ShareCommon
                            id="common-share-dropdown"
                            url={Supplement[0].url}
                            media={`${assetbaseUrl?.value}${Supplement?.[0]?.media}`}
                            title={Supplement[0]?.title}
                            dataid={Supplement[0]?.id}
                          />
                        </div>
                      </div>
                    </Link>
                  </>
                )}
                <div>
                  {Supplement?.length > 0 &&
                    Supplement?.map((els, idx) => (
                      <>
                        {idx > 0 && idx <= 3 && (
                          <CommonContent
                            url={els?.url}
                            src={`${assetbaseUrl?.value}${els?.media.replace("?resize=800,450", "")}?resize=90,51`}
                            post_type={els?.post_type}
                            time={els?.time}
                            publish_date={els?.publish_date}
                            category={els?.tagline}
                            title={els?.title}
                          />
                        )}
                      </>
                    ))}
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-4 col-xl-4 gujarat-responsive-space-sm">
              <div className="single-blog">
                <span className="badge badge-danger">Technology</span>
                <Link
                  target="_parent"
                  to="/tech"
                  onClickCapture={() =>
                    ReactGA.event({
                      category: "Homepage",
                      action: "Technology",
                      label: "Read More",
                    })
                  }
                  className="readmore"
                >
                  Read More
                </Link>
                {Technologylist?.length > 0 && (
                  <>
                    <Link
                      target="_parent"
                      to={`/${Technologylist[0]?.url}`}
                      className="main-blogs share-blog"
                    >
                      <div className="skeleton-sub">
                        <img
                          loading="lazy"
                          src={
                            !Technologylist?.[0]?.media
                              ? sandeshske
                              : `${assetbaseUrl?.value}${Technologylist?.[0]?.media.replace("?resize=800,450", "")}?resize=425,240`
                          }
                          alt=""
                          className="img-responsive"
                        />
                      </div>
                      {Technologylist?.[0]?.post_type === 3 ||
                      Technologylist?.[0]?.post_type === 4 ? (
                        <p className="button-hover timer_ subvideo-tagline">
                          {Technologylist?.[0]?.time}
                        </p>
                      ) : (
                        ""
                      )}
                      <div className="blog-details">
                        <p className="ellipsis">{Technologylist[0]?.title}</p>
                        <span className="blog-time">
                          <i className="far fa-clock"></i>
                          {Technologylist[0]?.publish_date}
                        </span>
                      </div>
                      <div class="dropleft">
                        <button
                          onClick={(e) => onShareClick(e)}
                          className="dropbtn ml-2"
                        >
                          <i class="fa fa-share-alt"></i>
                        </button>
                        <div class="dropdown-menu">
                          <ShareCommon
                            id="common-share-dropdown"
                            url={Supplement[0].url}
                            media={`${assetbaseUrl?.value}${Supplement?.[0]?.media}`}
                            title={Supplement[0]?.title}
                            dataid={Supplement[0]?.id}
                          />
                        </div>
                      </div>
                    </Link>
                  </>
                )}
                <div>
                  {Technologylist?.length > 0 &&
                    Technologylist?.map((els, idx) => (
                      <>
                        {idx > 0 && idx <= 3 && (
                          <CommonContent
                            url={els?.url}
                            src={`${assetbaseUrl?.value}${els?.media.replace("?resize=800,450", "")}?resize=90,51`}
                            post_type={els?.post_type}
                            time={els?.time}
                            publish_date={els?.publish_date}
                            category={els?.tagline}
                            title={els?.title}
                          />
                        )}
                      </>
                    ))}
                </div>
              </div>
            </div>
            {window.innerWidth < 991 ? (
              <div className="w-100">
                <div className="text-center">
                  <InjectScript
                    script={belowTechnologyMweb}
                    key={belowTechnologyMweb}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="col-md-12 col-lg-4 col-xl-4  gujarat-responsive-space-sm">
              <ul className="nav nav-tabs justify-content-center border-0 margin-bottom-fun mb-2">
                <li
                  className="nav-item"
                  onClickCapture={() =>
                    ReactGA.event({
                      category: "Homepage",
                      action: "Food",
                      label: "Read More",
                    })
                  }
                >
                  <a className="nav-link active" data-toggle="tab" href="#food">
                    Food
                  </a>
                </li>
                <li
                  className="nav-item"
                  onClickCapture={() =>
                    ReactGA.event({
                      category: "Homepage",
                      action: "Travel",
                      label: "Read More",
                    })
                  }
                >
                  <a className="nav-link" data-toggle="tab" href="#travel">
                    Travel
                  </a>
                </li>
                <li
                  className="nav-item"
                  onClickCapture={() =>
                    ReactGA.event({
                      category: "Homepage",
                      action: "Relationship",
                      label: "Read More",
                    })
                  }
                >
                  <a
                    className="nav-link"
                    data-toggle="tab"
                    href="#relationship"
                  >
                    Relationship
                  </a>
                </li>
              </ul>
              <div className="tab-content">
                <div className="tab-pane active" id="food">
                  <div className="single-blog">
                    <div className="row col-12 p-0 m-0">
                      {Foodlist?.length > 0 &&
                        Foodlist?.map((els, idx) => (
                          <>
                            {idx === 0 && (
                              <Link
                                target="_parent"
                                to={`/${els?.url}`}
                                className="main-blogs share-blog"
                              >
                                <div className="skeleton-sub">
                                  <img
                                    loading="lazy"
                                    src={
                                      !els?.media
                                        ? sandeshske
                                        : `${assetbaseUrl?.value}${els?.media.replace("?resize=800,450", "")}?resize=425,240`
                                    }
                                    alt=""
                                    className="img-responsive"
                                  />
                                </div>
                                {els?.post_type === 3 ||
                                els?.post_type === 4 ? (
                                  <p className="button-hover timer_ subvideo-tagline">
                                    {els?.time}
                                  </p>
                                ) : (
                                  ""
                                )}
                                <div className="blog-details">
                                  <p className="ellipsis">{els?.title}</p>
                                  <span className="blog-time">
                                    <i className="far fa-clock"></i>
                                    {els?.publish_date}
                                  </span>
                                </div>
                                <div class="dropleft">
                                  <button
                                    onClick={(e) => onShareClick(e)}
                                    className="dropbtn ml-2"
                                  >
                                    <i class="fa fa-share-alt"></i>
                                  </button>
                                  <div class="dropdown-menu">
                                    <ShareCommon
                                      id="common-share-dropdown"
                                      url={Supplement[0].url}
                                      media={`${assetbaseUrl?.value}${Supplement?.[0]?.media}`}
                                      title={Supplement[0]?.title}
                                      dataid={Supplement[0]?.id}
                                    />
                                  </div>
                                </div>
                              </Link>
                            )}
                            {idx > 0 && idx < 4 && (
                              <CommonContent
                                url={els?.url}
                                src={`${assetbaseUrl?.value}${els?.media.replace("?resize=800,450", "")}?resize=90,51`}
                                post_type={els?.post_type}
                                time={els?.time}
                                publish_date={els?.publish_date}
                                category={els?.tagline}
                                title={els?.title}
                              />
                            )}
                          </>
                        ))}
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="travel">
                  <div className="single-blog">
                    <div className="row col-12 p-0 m-0">
                      {Travellist?.length > 0 &&
                        Travellist?.map((els, idx) => (
                          <>
                            {idx === 0 && (
                              <Link
                                target="_parent"
                                to={`/${els?.url}`}
                                className="main-blogs share-blog"
                              >
                                <div className="skeleton-sub">
                                  <img
                                    loading="lazy"
                                    src={
                                      !els?.media
                                        ? sandeshske
                                        : `${assetbaseUrl?.value}${els?.media.replace("?resize=800,450", "")}?resize=425,240`
                                    }
                                    alt=""
                                    className="img-responsive"
                                  />
                                </div>
                                {els?.post_type === 3 ||
                                els?.post_type === 4 ? (
                                  <p className="button-hover timer_ subvideo-tagline">
                                    {els?.time}
                                  </p>
                                ) : (
                                  ""
                                )}
                                <div className="blog-details">
                                  <p className="ellipsis">{els?.title}</p>
                                  <span className="blog-time">
                                    <i className="far fa-clock"></i>
                                    {els?.publish_date}
                                  </span>
                                </div>
                                <div class="dropleft">
                                  <button
                                    onClick={(e) => onShareClick(e)}
                                    className="dropbtn ml-2"
                                  >
                                    <i class="fa fa-share-alt"></i>
                                  </button>
                                  <div class="dropdown-menu">
                                    <ShareCommon
                                      id="common-share-dropdown"
                                      url={Supplement[0].url}
                                      media={`${assetbaseUrl?.value}${Supplement?.[0]?.media}`}
                                      title={Supplement[0]?.title}
                                      dataid={Supplement[0]?.id}
                                    />
                                  </div>
                                </div>
                              </Link>
                            )}
                            {idx > 0 && idx < 4 && (
                              <CommonContent
                                url={els?.url}
                                src={`${assetbaseUrl?.value}${els?.media.replace("?resize=800,450", "")}?resize=90,51`}
                                post_type={els?.post_type}
                                time={els?.time}
                                publish_date={els?.publish_date}
                                category={els?.tagline}
                                title={els?.title}
                              />
                            )}
                          </>
                        ))}
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="relationship">
                  <div className="single-blog">
                    <div className="row col-12 p-0 m-0">
                      {Relationshiplist?.length > 0 &&
                        Relationshiplist?.map((els, idx) => (
                          <>
                            {idx === 0 && (
                              <Link
                                target="_parent"
                                to={`/${els?.url}`}
                                className="main-blogs share-blog"
                              >
                                <div className="skeleton-sub">
                                  <img
                                    loading="lazy"
                                    src={
                                      !els?.media
                                        ? sandeshske
                                        : `${assetbaseUrl?.value}${els?.media.replace("?resize=800,450", "")}?resize=425,240`
                                    }
                                    alt=""
                                    className="img-responsive"
                                  />
                                </div>
                                {els?.post_type === 3 ||
                                els?.post_type === 4 ? (
                                  <p className="button-hover timer_ subvideo-tagline">
                                    {els?.time}
                                  </p>
                                ) : (
                                  ""
                                )}
                                <div className="blog-details">
                                  <p className="ellipsis">{els?.title}</p>
                                  <span className="blog-time">
                                    <i className="far fa-clock"></i>
                                    {els?.publish_date}
                                  </span>
                                </div>
                                <div class="dropleft">
                                  <button
                                    onClick={(e) => onShareClick(e)}
                                    className="dropbtn ml-2"
                                  >
                                    <i class="fa fa-share-alt"></i>
                                  </button>
                                  <div class="dropdown-menu">
                                    <ShareCommon
                                      id="common-share-dropdown"
                                      url={Supplement[0].url}
                                      media={`${assetbaseUrl?.value}${Supplement?.[0]?.media}`}
                                      title={Supplement[0]?.title}
                                      dataid={Supplement[0]?.id}
                                    />
                                  </div>
                                </div>
                              </Link>
                            )}
                            {idx > 0 && idx < 4 && (
                              <CommonContent
                                url={els?.url}
                                src={`${assetbaseUrl?.value}${els?.media.replace("?resize=800,450", "")}?resize=90,51`}
                                post_type={els?.post_type}
                                time={els?.time}
                                publish_date={els?.publish_date}
                                category={els?.tagline}
                                title={els?.title}
                              />
                            )}
                          </>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Lifestyle;
