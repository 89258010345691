import {
  COMMENT_FETCH_DATA_SUCCESS,
  COMMENT_HAVE_ERROR,
  COMMENT_ARE_LOADING,
} from "../actionType";
import HttpService from "../../utils/httpService";
import { Post } from "../../services/api.service";

// Action creator function to create actions for comments
export function CommentAction(action, payload) {
  switch (action) {
    case COMMENT_HAVE_ERROR:
      return {
        type: action,
        hasError: payload,
      };

    case COMMENT_ARE_LOADING:
      return {
        type: action,
        isLoading: payload,
      };

    case COMMENT_FETCH_DATA_SUCCESS:
      return {
        type: action,
        data: payload,
      };

    default:
      return;
  }
}

// Async action creator function to fetch comments data from the server
export function CommentFetchData(id) {
  return (dispatch) => {
    try {
      return new Promise((resolve, reject) => {
        dispatch(CommentAction(COMMENT_ARE_LOADING, true)); // Dispatch loading action
        // Send post request to fetch comments data from the server
        Post(HttpService.comment, { id: id }).then(
          (result) => {
            // Dispatch success action with the fetched comments data
            dispatch(CommentAction(COMMENT_FETCH_DATA_SUCCESS, result?.data));
            resolve(result?.data); // Resolve promise with the fetched comments data
          },
          (error) => {
            reject(error); // Reject promise if there is an error
          },
        );
      });
    } catch (error) {
      dispatch(CommentAction(COMMENT_HAVE_ERROR, true)); // Dispatch error action if an exception occurs
    }
  };
}
