import $ from "jquery";
import React, { useEffect, useState } from "react";
import GoogleLogin from "react-google-login";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  facebookLogin,
  forgetPassword,
  googleLogin,
  updatePassword,
} from "../../redux/actions/commonAction";
import {
  LogingFetchData,
  SigninFetchData,
  SignupFetchData,
} from "../../redux/actions/loginAction";

const Login = (props) => {
  const dispatch = useDispatch();
  const [userId, setUserId] = useState();
  const [visiblesignup, setVisiblesignup] = useState(false);
  const [visibleConsignup, setVisibleConsignup] = useState(false);
  const [Visibleforget, setVisibleforget] = useState(false);
  const [visiblelogin, setVisiblelogin] = useState(false);
  const [userExistClick, setUserExistClick] = useState("");
  const [uemail, setUemail] = useState("");
  const [umobile, setUmobile] = useState("");
  const [userLoginData, setUserLoginData] = useState();
  const [message, setMessage] = useState();
  const [user, setUser] = useState({
    userid: "",
    userEmail: "",
    password: "",
    fullName: "",
    policy: false,
    yearTerms: false,
    confirmPassword: "",
    mobile: "",
    otp: "",
  });
  const [apiCall, setAPICall] = useState(false);
  const checkUserId = useSelector((state) => state?.LogingReducer);
  const [profile] = useState();

  const responseFacebook = async (response) => {
    if (response?.hasOwnProperty("error")) {
      return true;
    } else {
      dispatch(facebookLogin(response)).then((res) => {
        if (res) {
          localStorage.setItem("socialLogin", "Facebook");
          $(".login-main-box").removeClass("active");
          $(".signin-section").find(".signinform").hide();
          $(".signin-section").find(".signupform").hide();
          $(".signin-section").find(".main-form").show();
          props.success("success");
        }
      });
    }
  };

  const responseGoogle = async (response) => {
    if (response?.hasOwnProperty("error")) {
      return true;
    } else {
      dispatch(googleLogin(response)).then((res) => {
        if (res) {
          localStorage.setItem("socialLogin", "Google");
          $(".login-main-box").removeClass("active");
          $(".signin-section").find(".signinform").hide();
          $(".signin-section").find(".signupform").hide();
          $(".signin-section").find(".main-form").show();
          props.success("success");
        }
      });
    }
  };

  useEffect(() => {}, [profile]);

  useEffect(() => {
    if (apiCall) {
      if (checkUserId?.data?.userid) {
        setUserId(checkUserId?.data?.userid);
        setAPICall(false);
      } else {
        $(".signin-section").find(".main-form").hide();
        $(".signin-section").find(".signinform").hide();
        $(".signin-section").find(".signupform").show();
        $(".signin-section")
          .find(".heading")
          .find("span")
          .text("Complete Your Profile");
        $(".signin-section").find(".signupform").find(".secondary-link").show();
        $(".signin-section")
          .find(".signupform")
          .find("input[name=mobile]")
          .val(user.userEmail);
      }
    }
  }, [checkUserId, apiCall]);
  const checkUserIsExist = async (e) => {
    e.preventDefault();
    const loginConfig = { master: user.userEmail };
    setUserExistClick("clicked");
    if (user.userEmail && user.userEmail.includes("@")) {
      setUemail(user.userEmail);
    } else if (user.userEmail && !user.userEmail.includes("@")) {
      setUmobile(user.userEmail);
    }
    setUser({ ...user, mobile: umobile });
    setUser({ ...user, userEmail: uemail });
    const response = await dispatch(LogingFetchData(loginConfig));
    if (response?.status && response?.type === "login") {
      setAPICall(true);
      $(".signin-section").find(".otpform").hide();
      $(".signin-section").find(".main-form").hide();
      $(".signin-section").find(".signupform").hide();
      $(".signin-section").find(".signinform").show();
    } else {
      setUserId(response?.userid);
      if (response?.type === "register") {
        $(".signin-section").find(".main-form").hide();
        $(".signin-section").find(".signupform").hide();
        $(".signin-section").find(".signinform").hide();
        $(".signin-section").find(".otpform").show();
      } else {
        setMessage(response?.message);
        setTimeout(() => {
          setMessage(undefined);
        }, 5000);
      }
    }
    setUserExistClick("");
  };

  const closeModal = () => {
    $(".login-main-box").removeClass("active");
    $(".signin-section").find(".signinform").hide();
    $(".signin-section").find(".signupform").hide();
    $(".signin-section").find(".main-form").show();
  };

  const SignupHandler = async (e) => {
    e.preventDefault();

    if (
      document.getElementsByClassName("g-recaptcha-response")[0].value === ""
    ) {
      document.getElementById("g-recaptcha-error").innerHTML =
        '<span style="color:red;">This Field is Required.</span>';
    }
    else {
      document.getElementById("g-recaptcha-error").innerHTML = "";

      const configSignupData = {
        id: userId,
        mobile: umobile ? umobile : user?.mobile,
        name: user?.fullName,
        email: uemail ? uemail : user?.userEmail,
        password: user?.password,
        cpassword: user?.confirmPassword,
      };
      const response = await dispatch(SignupFetchData(configSignupData));
      if (response?.status) {
        const configSignin = {
          userid: response?.user_data?.id,
          password: response?.user_data?.password,
        };
        dispatch(SigninFetchData(configSignin));
        setMessage(response?.message);
        setTimeout(() => {
          setMessage(undefined);
          closeModal();
        }, 1000);
        props.success("success");
      } else {
        setMessage(response?.message);
        setTimeout(() => {
          setMessage(undefined);
        }, 1000);
        props.success("success");
      }
    }
  };
  const SigninHandler = async (e) => {
    e.preventDefault();
    const configSignin = {
      userid: userId,
      password: user?.password,
    };
    setAPICall(false);
    const res = await dispatch(SigninFetchData(configSignin));
    if (res?.status) {
      $(".login-main-box").removeClass("active");
      $(".signin-section").find(".signinform").hide();
      $(".signin-section").find(".signupform").hide();
      $(".signin-section").find(".main-form").show();
      closeModal();
      props.success("success");
    } else {
      setMessage(res?.message);
      setTimeout(() => {
        setMessage(undefined);
      }, 5000);
    }
  };


  const OTPHandler = async (e) => {
    e.preventDefault();
    const configOtpData = {
      id: userId,
      otp: user?.otp,
    };
    if (!userLoginData) {
      setUserLoginData(checkUserId?.data);
    }
    const res = await dispatch(LogingFetchData(configOtpData, "otp"));
    if (
      res?.status &&
      (userLoginData?.type === "login" || checkUserId?.data?.type === "login")
    ) {
      setAPICall(true);
      $(".signin-section").find(".otpform").hide();
      $(".signin-section").find(".main-form").hide();
      $(".signin-section").find(".signupform").hide();
      $(".signin-section").find(".signinform").show();
    } else {
      if (
        res?.status &&
        (userLoginData?.type === "register" ||
          checkUserId?.data?.type === "register")
      ) {
        $(".signin-section").find(".otpform").hide();
        $(".signin-section").find(".main-form").hide();
        $(".signin-section").find(".signinform").hide();
        $(".signin-section").find(".signupform").show();
        $(".signin-section")
          .find(".heading")
          .find("span")
          .text("Complete Your Profile");
        $(".signin-section").find(".signupform").find(".secondary-link").show();
        $(".signin-section")
          .find(".signupform")
          .find("input[name=mobile]")
          .val(user.userEmail);
      } else {
        setAPICall(false);
        setMessage(res?.message);
        setTimeout(() => {
          setMessage(undefined);
        }, 5000);
      }
    }
  };

  const forgetPasswordHandler = async (e) => {
    e.preventDefault();
    const configUserForgetPassword = {
      id: userId,
      otp: user.otp,
      password: user.password,
    };
    const response = await dispatch(updatePassword(configUserForgetPassword));
    if (response?.status) {
      setMessage(response?.message);
      setTimeout(() => {
        setMessage(undefined);
        $(".signin-section").find(".otpform").hide();
        $(".signin-section").find(".main-form").hide();
        $(".signin-section").find(".signupform").hide();
        $(".signin-section").find(".signupform").hide();
        $(".signin-section").find(".forgetPasswordForm").hide();
        $(".signin-section").find(".signinform").show();
        setUser({ otp: "", password: "" });
      }, 1000);
    } else {
      setMessage(response?.message);
      setTimeout(() => {
        setMessage(undefined);
      }, 1000);
    }
  };
  const viewForgetPasswordScreen = async (e) => {
    if (
      uemail?.length > 8 ||
      umobile?.length > 8 ||
      user?.userEmail?.length > 8 ||
      user?.mobile?.length > 8
    ) {
      e.preventDefault();
      const configUserForgetPassword = {
        master: user?.userEmail,
      };
      const response = await dispatch(forgetPassword(configUserForgetPassword));
      if (response?.status) {
        setUserId(response?.userid);
        $(".signin-section").find(".otpform").hide();
        $(".signin-section").find(".main-form").hide();
        $(".signin-section").find(".signinform").hide();
        $(".signin-section").find(".signupform").hide();
        $(".signin-section").find(".signupform").hide();
        $(".signin-section").find(".forgetPasswordForm").show();
        $(".signin-section").find(".userEmailforgetpassword").hide();
      } else {
        setMessage(response?.message);
        setTimeout(() => {
          setMessage(undefined);
        }, 2000);
      }
    } else {
      setMessage("Please Enter Email/Password");
      setTimeout(() => {
        setMessage(undefined);
      }, 5000);
    }
  };

  const viewForgetPasswordScreenSignin = async (e) => {
    if (
      uemail?.length > 8 ||
      umobile?.length > 8 ||
      user?.userEmail?.length > 8 ||
      user?.mobile?.length > 8
    ) {
      e.preventDefault();
      const configUserForgetPassword = {
        master: umobile ? umobile : uemail,
      };
      const response = await dispatch(forgetPassword(configUserForgetPassword));
      if (response?.status) {
        setUserId(response?.userid);
        $(".signin-section").find(".otpform").hide();
        $(".signin-section").find(".main-form").hide();
        $(".signin-section").find(".signinform").hide();
        $(".signin-section").find(".signupform").hide();
        $(".signin-section").find(".signupform").hide();
        $(".signin-section").find(".forgetPasswordForm").show();
        $(".signin-section").find(".userEmailforgetpassword").hide();
      } else {
        setMessage(response?.message);
        setTimeout(() => {
          setMessage(undefined);
        }, 2000);
      }
    } else {
      setMessage("Please Enter Email/Password");
      setTimeout(() => {
        setMessage(undefined);
      }, 5000);
    }
  };

  const checkUserExistForgetPassword = async (e) => {
    e.preventDefault();
    const configUserForgetPassword = {
      master: user.userEmail,
    };
    const response = await dispatch(forgetPassword(configUserForgetPassword));
    if (response?.status) {
      setUserId(response?.userid);
      $(".signin-section").find(".otpform").hide();
      $(".signin-section").find(".main-form").hide();
      $(".signin-section").find(".signinform").hide();
      $(".signin-section").find(".signupform").hide();
      $(".signin-section").find(".signupform").hide();
      $(".signin-section").find(".forgetPasswordForm").show();
      $(".signin-section").find(".userEmailforgetpassword").hide();
    }
  };
  function showPasswordsignup() {
    var x = document.getElementById("signuppassword");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }
  function showconPasswordsignup() {
    var x = document.getElementById("signupconfirmpassword");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }
  function showPasswordlogin() {
    var x = document.getElementById("loginpassword");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }

  function showPasswordforget() {
    var x = document.getElementById("forgetpassword");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }

  const maxLengthCheck = (e) => {
    if (e.target.value.length > e.target.maxLength) {
      e.target.value = e.target.value.slice(0, e.target.maxLength);
    }
  };

  return (
    <>
      <section className="login">
        <div className="container ">
          <div
            className="login-main-box active uppermodel fix-top"
            style={{ position: "fixed" }}
          >
            <div className="row my-3">
              <a
                onClick={closeModal}
                id="LoginModalComponent"
                className="closebtn"
              >
                <i className="fa fa-times icon-color-times" />
              </a>
              <button
                type="button"
                className="btn btn-primary profile"
                style={{ position: "fixed", top: "10px", zIndex: "99" }}
              >
                <Link target="_parent" to="/profile">
                  {" "}
                  <i className="fas fa-user" style={{ color: "#fff" }}></i>{" "}
                </Link>
              </button>
            </div>
            <div className="inn-block">
              <div className="signin-section">
                <form
                  onSubmit={checkUserIsExist}
                  className="form main-form"
                  autocomplete="off"
                >
                  <ul>
                    <li className="input-field user">
                      <p>
                        <input
                          value={user.userEmail}
                          onChange={(e) =>
                            setUser({ userEmail: e.target.value })
                          }
                          autocomplete="off"
                          type="text"
                          name="emailId"
                          className="checkno"
                          placeholder="Sign In/Sign Up with Email or Mobile No."
                          maxlength="100"
                        />
                      </p>
                      <div className="errorMsg"></div>
                      <a
                        href="javascript:void(0)"
                        id="changeEmailIdDiv"
                        className="secondary-link"
                        style={{ display: "none" }}
                      >
                        Change Email Or Mobile No.
                      </a>
                    </li>
                    <li id="sso-signInButtonDiv" className="submit">
                      <input
                        type="submit"
                        className={`submit-btn contibtn ${user.userEmail?.length >= 10 ? "" : "disabled"}`}
                        value="Continue"
                        disabled={`${userExistClick === "clicked" || user.userEmail?.length <= 9 ? "disabled" : ""}`}
                      />
                    </li>
                  </ul>
                  <a
                    onClick={viewForgetPasswordScreen}
                    href="javascript:void(0)"
                    id="sso-forgot-pass"
                    className="forget-password"
                  >
                    Forgot Password?
                  </a>
                  {message && <span className="forgotMsg">{message}</span>}

                  <div className="socialConnectImgDiv">
                   
                    <span id="sso-fblogin-error" className="errorMsg"></span>
                    <GoogleLogin
                      className="my-3"
                      clientId="834904142648-0i3hktukk3t4egeqp1lop4jt4nfjmo7o.apps.googleusercontent.com"
                      buttonText="Sign in with Google"
                      onSuccess={responseGoogle}
                      onFailure={responseGoogle}
                      cookiePolicy={"single_host_origin"}
                    >
                    </GoogleLogin>
                    <span id="sso-gplus-error" className="errorMsg"></span>
                  </div>
                </form>
                <form
                  onSubmit={SigninHandler}
                  className="form signinform"
                  autocomplete="off"
                  style={{ display: "none" }}
                >
                  <ul>
                    <li className="input-field email user">
                      <p>
                        <input
                          required
                          value={user.userEmail ? user.userEmail : user.mobile}
                          onChange={(e) =>
                            setUser({ ...user, userEmail: e.target.value })
                          }
                          autocomplete="off"
                          type="text"
                          name="emailId"
                          placeholder="Sign In/Sign Up with Email or Mobile No."
                          maxlength="100"
                          disabled
                        />
                      </p>
                      <div
                        className="errorMsg"
                        style={{ display: "none" }}
                      ></div>
                    </li>
                    <li
                      className="input-field password"
                      id="sso-pwdDiv"
                      style={{ display: "list-item" }}
                    >
                      <p>
                        <input
                          required
                          value={user.password}
                          id="loginpassword"
                          onChange={(e) =>
                            setUser({ ...user, password: e.target.value })
                          }
                          autocomplete="off"
                          type="password"
                          name="password"
                          placeholder="Password"
                          maxlength="14"
                        />
                        <span
                          className="view-password"
                          onClick={showPasswordlogin}
                        >
                          {visiblelogin === false ? (
                            <i
                              className="fas fa-eye"
                              onClick={() => setVisiblelogin(true)}
                            ></i>
                          ) : (
                            ""
                          )}
                          {visiblelogin === true ? (
                            <i
                              className="fas fa-eye-slash"
                              onClick={() => setVisiblelogin(false)}
                            ></i>
                          ) : (
                            ""
                          )}
                        </span>
                      </p>

                    </li>
                    {message && (
                      <p
                        style={{
                          color: "red",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {message}
                      </p>
                    )}
                    <li id="sso-login-otp-msg" className="text-field">
                      <p></p>
                    </li>
                    <li className="input-field password" id="sso-otpLoginDiv">
                      <p>
                        <input
                          type="password"
                          name="otplogin"
                          maxlength="6"
                          placeholder="Enter the Verification code"
                        />
                      </p>
                      <div className="errorMsg"></div>
                      <div className="successMsg"></div>
                      <span className="regenerate-otp">
                        Didn't receive OTP?
                      </span>
                      <a
                        id="sso-regenerate-otp"
                        href="javascript:void(0)"
                        className="secondary-link"
                      >
                        Re-Generate OTP
                      </a>
                    </li>
                  
                    <li id="sso-signInButtonDiv" className="submit">
                      <input
                        type="submit"
                        className="submit-btn"
                        value="Sign In"
                      />
                    </li>
                  </ul>
                  <a
                    onClick={viewForgetPasswordScreenSignin}
                    href="javascript:void(0)"
                    id="sso-forgot-pass"
                    className="forget-password"
                  >
                    Forgot Password?
                  </a>
                  <div className="socialConnectImgDiv">
                    <button type="button" id="sso-fb-login" className="fb-btn">
                      Sign in with Facebook
                    </button>
                    <span id="sso-fblogin-error" className="errorMsg"></span>
                    <button
                      type="button"
                      id="sso-gplus-login"
                      className="gplus-btn"
                    >
                      Sign in with Google
                    </button>
                    <span id="sso-gplus-error" className="errorMsg"></span>
                  </div>
                </form>
                <form
                  onSubmit={OTPHandler}
                  className="form otpform"
                  autocomplete="off"
                  style={{ display: "none" }}
                >
                  <ul>
                    <li className="input-field otpfiled">
                      <p>
                        <input
                          name="otp"
                          onChange={(e) => setUser({ otp: e.target.value })}
                          autocomplete="off"
                          placeholder="OTP"
                          type="text"
                          value={user.otp}
                        />
                      </p>
                    </li>
                    <li className="submit">
                      <input
                        type="submit"
                        className="submit-btn otpbtn"
                        value="Submit"
                      />
                    </li>
                    {message && (
                      <span
                        style={{
                          color: "red",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {message}
                      </span>
                    )}
                  </ul>
                </form>
                <form
                  onSubmit={checkUserExistForgetPassword}
                  className="form userEmailforgetpassword"
                  autocomplete="off"
                  style={{ display: "none" }}
                >
                  <ul>
                    <li className="input-field otpfiled">
                      <p>
                        <input
                          name="userEmail"
                          onChange={(e) =>
                            setUser({ ...user, userEmail: e.target.value })
                          }
                          autocomplete="off"
                          placeholder="Enter User Email / Password"
                          type="text"
                          value={user.userEmail}
                        />
                      </p>
                    </li>
                    <li className="submit">
                      <input
                        type="submit"
                        className="submit-btn otpbtn"
                        value="Submit"
                        disabled={user.userEmail?.length > 5 ? "" : true}
                      />
                    </li>
                    {message && (
                      <span
                        style={{
                          color: "red",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {message}
                      </span>
                    )}
                  </ul>
                </form>

                <form
                  onSubmit={forgetPasswordHandler}
                  className="form forgetPasswordForm"
                  autocomplete="off"
                  style={{ display: "none" }}
                >
                  <ul>
                    <li className="input-field">
                      We Have Sent a 4 Digit Verification Code on{" "}
                      {user?.userEmail ? user?.userEmail : uemail}
                      {umobile}
                    </li>
                    <li className="input-field otpfiled">
                      <p>
                        <input
                          name="otp"
                          onChange={(e) =>
                            setUser({ ...user, otp: e.target.value })
                          }
                          autoComplete="off"
                          placeholder="Enter The Varification Code"
                          type="text"
                          value={user.otp}
                        />
                      </p>
                    </li>
                    <li className="input-field otpfiled">
                      <p className="eyeissue">
                        <input
                          name="password"
                          id="forgetpassword"
                          onChange={(e) =>
                            setUser({ ...user, password: e.target.value })
                          }
                          autocomplete="off"
                          placeholder="Enter New Password"
                          type="password"
                          value={user.password}
                        />
                        <span
                          className="view-password"
                          onClick={showPasswordforget}
                        >
                          {Visibleforget === false ? (
                            <i
                              className="fas fa-eye"
                              onClick={() => setVisibleforget(true)}
                            ></i>
                          ) : (
                            ""
                          )}
                          {Visibleforget === true ? (
                            <i
                              className="fas fa-eye-slash"
                              onClick={() => setVisibleforget(false)}
                            ></i>
                          ) : (
                            ""
                          )}
                        </span>
                      </p>
                    </li>
                    <li className="submit">
                      <input
                        disabled={`${user.otp?.length > 0 && !message && user.password?.length > 0 ? "" : "disabled"}`}
                        type="submit"
                        className="submit-btn otpbtn"
                        value="Submit"
                      />
                    </li>
                    {message && (
                      <span
                        style={{
                          color: "red",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {message}
                      </span>
                    )}
                  </ul>
                </form>
                <form
                  onSubmit={SignupHandler}
                  className="form signupform"
                  autocomplete="off"
                  style={{ display: "none" }}
                >
                  <ul>
                    <li className={`input-field email`}>
                      <p>
                        <input
                          required
                          disabled={uemail !== ""}
                          value={uemail ? uemail : user.userEmail}
                          onChange={(e) =>
                            setUser({ ...user, userEmail: e.target.value })
                          }
                          autocomplete="off"
                          type="text"
                          name="emailId"
                          maxlength="100"
                          placeholder="Email"
                        />
                      </p>
                    
                    </li>
                    <li className="input-field user-name">
                      <p>
                        <input
                          required
                          value={user.fullName}
                          onChange={(e) =>
                            setUser({ ...user, fullName: e.target.value })
                          }
                          autocomplete="off"
                          type="text"
                          name="fullname"
                          placeholder="Full Name"
                          maxlength="30"
                        />
                      </p>
                      <div className="errorMsg"></div>
                    </li>
                    <li className="input-field password">
                      <p className="eyeissue">
                        <input
                          required
                          autocomplete="off"
                          id="signuppassword"
                          value={user.password}
                          onChange={(e) =>
                            setUser({ ...user, password: e.target.value })
                          }
                          type="password"
                          name="registerPwd"
                          placeholder="Password"
                          maxlength="14"
                        />
                        <span
                          className="view-password"
                          onClick={showPasswordsignup}
                        >
                          {visiblesignup === false ? (
                            <i
                              className="fas fa-eye"
                              onClick={() => setVisiblesignup(true)}
                            ></i>
                          ) : (
                            ""
                          )}
                          {visiblesignup === true ? (
                            <i
                              className="fas fa-eye-slash"
                              onClick={() => setVisiblesignup(false)}
                            ></i>
                          ) : (
                            ""
                          )}
                        </span>
                      </p>
                    </li>
                    <li className="input-field password">
                      <p>
                        <input
                          required
                          value={user.confirmPassword}
                          id="signupconfirmpassword"
                          onChange={(e) =>
                            setUser({
                              ...user,
                              confirmPassword: e.target.value,
                            })
                          }
                          autocomplete="off"
                          type="password"
                          name="registerCnfrmPwd"
                          placeholder="Confirm Password"
                          maxlength="14"
                        />
                        <span
                          className="view-password"
                          onClick={showconPasswordsignup}
                        >
                          {visibleConsignup === false ? (
                            <i
                              className="fas fa-eye"
                              onClick={() => setVisibleConsignup(true)}
                            ></i>
                          ) : (
                            ""
                          )}
                          {visibleConsignup === true ? (
                            <i
                              className="fas fa-eye-slash"
                              onClick={() => setVisibleConsignup(false)}
                            ></i>
                          ) : (
                            ""
                          )}
                        </span>
                      </p>
                      <div className="errorMsg"></div>
                    </li>
                    {/* {email ? */}
                    <li className="input-field mobile-no">
                      <p>
                        <span className="country-code">+91 - </span>
                        <input
                          required
                          disabled={umobile !== ""}
                          value={umobile ? umobile : user.mobile}
                          onChange={(e) =>
                            setUser({ ...user, mobile: e.target.value })
                          }
                          autocomplete="off"
                          type="number"
                          name="emailId"
                          maxLength={10}
                          onInput={maxLengthCheck}
                          placeholder="Mobile No."
                        />
                      </p>
                      <div className="errorMsg" id="mobile-errmsg"></div>
                    </li>

                    <li className="recaptcha-wrapper">
                      <div id="recaptcha-container">
                        <div style={{ width: "304px", height: "78px" }}>
                          <Helmet>
                            <script
                              src="https://www.google.com/recaptcha/api.js"
                              async
                              defer
                            ></script>
                          </Helmet>
                          <div
                            className="g-recaptcha"
                            data-sitekey="6LcoUMEeAAAAAOUUw6Cf5_jLHzxVb9MZjsXKGMlz"
                            data-callback="verifyCaptcha"
                          ></div>
                          <div id="g-recaptcha-error"></div>
                          <br />
                        </div>
                      </div>
                      <div className="errorMsg"></div>
                    </li>
                    <li className="checkbox">
                      <p className="row">
                        <input
                          className="col-1 js-contentCB"
                          type="checkbox"
                          required
                          id="agree"
                          name="agree"
                        />
                        <label className="col-11" htmlFor="agree">
                          I warrant that I am 18 years old, and agree with the
                          <Link
                            target="_parent"
                            to="/page/terms-conditions"
                            rel="noopener noreferrer"
                          >
                            Terms &amp; Conditions{" "}
                          </Link>
                          and
                          <Link
                            target="_parent"
                            to="page/privacy-policy"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            Privacy Policy{" "}
                          </Link>
                          of Sandesh Ltd.
                          <span className="mandatory-field">*</span>
                        </label>
                      </p>
                      <div className="errorMsg"></div>
                    </li>
                    <li className="checkbox">
                      <p className="row">
                        <input
                          className="col-1 js-contentCB"
                          type="checkbox"
                          required
                          id="sharedDataAllowed"
                          name="sharedDataAllowed"
                        />
                        <label className="col-11" htmlFor="sharedDataAllowed">
                          I agree to a single sign on for seamless experience
                          across Sandesh Ltd.
                          <span className="mandatory-field">*</span>
                        </label>
                      </p>
                      <div className="errorMsg"></div>
                    </li>
                    <li className="submit">
                      <input
                        type="submit"
                        id="sso-registerBtn btn-validate"
                        className="submit-btn"
                        value="Update"
                      />
                    </li>
                    {message && (
                      <span
                        className="msg-error error"
                        style={{
                          color: "green",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {message}
                      </span>
                    )}
                  </ul>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
