import {
  MARQUEE_FETCH_DATA_SUCCESS,
  MARQUEE_HAVE_ERROR,
  MARQUEE_ARE_LOADING,
} from "../actionType";
import HttpService from "../../utils/httpService";
import { Get } from "../../services/api.service";

// Action creator function to handle different types of marquee actions
export function marqueeAction(action, payload) {
  switch (action) {
    case MARQUEE_HAVE_ERROR:
      return {
        type: action,
        hasError: payload,
      };

    case MARQUEE_ARE_LOADING:
      return {
        type: action,
        isLoading: payload,
      };

    case MARQUEE_FETCH_DATA_SUCCESS:
      return {
        type: action,
        marquee: payload,
      };

    default:
      return;
  }
}

// Async action creator function to fetch marquee data
export function marqueeFetchData() {
  return (dispatch) => {
    try {
      return new Promise((resolve, reject) => {
        dispatch(marqueeAction(MARQUEE_ARE_LOADING, true));
        // Make a GET request to fetch marquee data
        Get(HttpService.marquee).then(
          (result) => {
            // Dispatch success action and store data in the state
            dispatch(marqueeAction(MARQUEE_FETCH_DATA_SUCCESS, result.data));
            resolve(result);
          },
          (error) => {
            // Dispatch error action if request fails
            reject(error);
            dispatch(marqueeAction(MARQUEE_HAVE_ERROR, true));
          },
        );
      });
    } catch (error) {
      // Dispatch error action if there's any exception
      dispatch(marqueeAction(MARQUEE_HAVE_ERROR, true));
    }
  };
}
