import React from "react";
import Header from "../subcomponents/astrologycomponent/Header";

/**
 * AstrologyComponent
 * 
 * This component renders the astrology component section.
 */
const AstrologyComponent = () => {
  return (
    <>
      {/* Astrology component section */}
      <section className="astrology-component">
        <div className="container">
          {/* Header component */}
          <Header />
        </div>
      </section>
    </>
  );
};

export default AstrologyComponent;