// Import necessary action types
import {
  INPUT_VALUE_FETCH_DATA_SUCCESS,
  INPUT_VALUE_HAVE_ERROR,
  INPUT_VALUE_ARE_LOADING,
} from "../actionType";

// Action creator function to handle different types of search value actions
export function searchValueAction(action, payload) {
  switch (action) {
    case INPUT_VALUE_HAVE_ERROR:
      // Return action object indicating error
      return {
        type: action,
        hasError: payload,
      };

    case INPUT_VALUE_ARE_LOADING:
      // Return action object indicating loading state
      return {
        type: action,
        isLoading: payload,
      };

    case INPUT_VALUE_FETCH_DATA_SUCCESS:
      // Return action object containing input search value
      return {
        type: action,
        inputSearch: payload,
      };

    default:
      return;
  }
}

// Action creator function to dispatch input search value
export function searchValue(searchValue) {
  return (dispatch) => {
    // Dispatch action with input search value
    return dispatch(
      searchValueAction(INPUT_VALUE_FETCH_DATA_SUCCESS, searchValue)
    );
  };
}
