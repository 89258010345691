// Import necessary action types and services
import {
  WIDGET_FETCH_DATA_SUCCESS,
  WIDGET_HAVE_ERROR,
  WIDGET_ARE_LOADING,
} from "../actionType";
import HttpService from "../../utils/httpService";
import { Get } from "../../services/api.service";

// Action creator function to handle different types of widget actions
export function widgetAction(action, payload) {
  switch (action) {
    case WIDGET_HAVE_ERROR:
      // Return action object indicating error
      return {
        type: action,
        hasError: payload,
      };

    case WIDGET_ARE_LOADING:
      // Return action object indicating loading state
      return {
        type: action,
        isLoading: payload,
      };

    case WIDGET_FETCH_DATA_SUCCESS:
      // Return action object containing widget data
      return {
        type: action,
        widget: payload,
      };

    default:
      return;
  }
}

// Action creator function to fetch widget data
export function widgetFetchData() {
  return (dispatch) => {
    try {
      // Dispatch loading action
      dispatch(widgetAction(WIDGET_ARE_LOADING, true));
      return new Promise((resolve, reject) => {
        // Fetch widget data
        Get(HttpService.widget).then(
          (result) => {
            // Dispatch success action with widget data
            dispatch(widgetAction(WIDGET_FETCH_DATA_SUCCESS, result.data));
            resolve(result);
          },
          (error) => {
            // Dispatch error action if request fails
            reject(error);
          }
        );
      });
    } catch (error) {
      // Dispatch error action if an error occurs during fetch
      dispatch(widgetAction(WIDGET_HAVE_ERROR, true));
    }
  };
}
