import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import { useDispatch, useSelector } from "react-redux";
import { sharecount } from "../../redux/actions/commonAction";

const ShareWithGallery = (props) => {
  // Construct URL paths and messages for sharing
  const urlshare = props.url + "\r\n \r\n";
  const { 21: assetbaseUrl, 26: sharemsg } = useSelector((state) => state?.SettingReducer?.data);
  const media = assetbaseUrl?.value + props.media + "?resize=1200,630";
  const title = props.title + "\r\n";
  const ShareValue = sharemsg?.value + "\r\n \r\n";

  // Update local storage with share count
  useEffect(() => {
    if (props?.url) {
      const localCount = localStorage.getItem("SandeshLike")
        ? JSON.parse(localStorage.getItem("SandeshLike"))
        : {};
      const myCategory = props?.url.split("/")[0];
      localCount[myCategory] = (localCount[myCategory] || 0) + 1;
      if (localCount[myCategory] > 9999) {
        localCount[myCategory] = 1000;
      }
      localStorage.setItem("SandeshLike", JSON.stringify(localCount));
    }
  }, [props?.url]);

  const dispatch = useDispatch();

  // Function to handle share events and dispatch share count
  const returnfalse = async (name) => {
    await dispatch(sharecount(name, props?.dataid));
  };

  // Function to handle email share
  const returnStop4 = (e) => {
    document.getElementById(props.id).classList.add("d-none");
    ReactGA.event({ category: "Share", action: "Email", label: urlshare });
    e.stopPropagation();
    return false;
  }

  // JSX for share buttons
  return (
    <>
      {/* Facebook share button */}
      <a
        className="left-icon-border mb-3"
        onClickCapture={() =>
          ReactGA.event({
            category: "Share",
            action: "Facebook",
            label: urlshare,
          })
        }
        onClick={() => returnfalse("fb")}
        aria-label="Facebook"
        href={`${"https://facebook.com/sharer/sharer.php?display=page&picture=" + encodeURIComponent(media) + "&quote=" + encodeURIComponent(title) + "&u=" + encodeURIComponent(urlshare) + encodeURIComponent(ShareValue)}`}
        target="_blank"
        rel="noreferrer"
      >
        <i className="fab left-fixed-icon-border fa-facebook-f"></i>
      </a>
      {/* Twitter share button */}
      <a
        className="left-icon-border mb-3"
        onClickCapture={() =>
          ReactGA.event({
            category: "Share",
            action: "Twitter",
            label: urlshare,
          })
        }
        onClick={() => returnfalse("tw")}
        aria-label="Twitter"
        href={`${"https://twitter.com/share?text=" + encodeURIComponent(title) + "&url=" + encodeURIComponent(urlshare) + encodeURIComponent(ShareValue)}`}
        target="_blank"
        rel="noreferrer"
      >
        <i className="fab left-fixed-icon-border fa-twitter"></i>
      </a>
      {/* Whatsapp share button */}
      <a
        className="left-icon-border mb-3"
        onClickCapture={() =>
          ReactGA.event({
            category: "Share",
            action: "Whatsapp",
            label: urlshare,
          })
        }
        onClick={() => returnfalse("wa")}
        aria-label="Whatsapp"
        href={`${"https://api.whatsapp.com/send?text=" + encodeURIComponent(title) + encodeURIComponent(urlshare) + encodeURIComponent(ShareValue)}`}
        target="_blank"
        rel="noreferrer"
      >
        <i className="fab left-fixed-icon-border fa-whatsapp"></i>
      </a>
      {/* Email share button */}
      <a
        onClick={returnStop4}
        target="_blank"
        href={`mailto:?subject=${title}&body=${title}%0D%0A%0D%0A${urlshare}%0D%0A%0D%0A${ShareValue}`}
        className="left-icon-border"
        onClickCapture={() =>
          ReactGA.event({ category: "Share", action: "Email", label: urlshare })
        } rel="noreferrer"
      >
        <i class="fa fa-envelope"></i>
      </a>
    </>
  );
};

export default ShareWithGallery;
