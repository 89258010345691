import React from "react";
import InjectScript from "../CustomScript";

const AdTwo = () => {
  const adtwoHardcode = window.innerWidth > 991? `
  <div id="div-gpt-ad-1646028172937-0" style="min-width: 728px; min-height: 66px;">
    <script>
      window.googletag = window.googletag || {cmd: []};
      googletag.cmd.push(function() {
        googletag.defineSlot('/22678428348/SDPL_HP_Desktop_BTF_970x90', [[728, 90], [970, 66], [970, 90]], 'div-gpt-ad-1646028172937-0').addService(googletag.pubads());
        googletag.pubads().enableSingleRequest();
        googletag.pubads().disableInitialLoad();
        googletag.enableServices();
        googletag.display('div-gpt-ad-1646028172937-0');
      });
    </script>
  </div>
` : `
  <div id="div-gpt-ad-1646386314348-0" style="min-width: 300px; min-height: 50px;">
    <script>
      window.googletag = window.googletag || {cmd: []};
      googletag.cmd.push(function() {
        googletag.defineSlot('/22678428348/Mweb_HP_Desktop_BTF1_300X100', [[300, 150], [320, 50]], 'div-gpt-ad-1646386314348-0').addService(googletag.pubads());
        googletag.pubads().enableSingleRequest();
        googletag.pubads().disableInitialLoad();
        googletag.enableServices();
      });
      googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646386314348-0'); });
    </script>
  </div>
`;

  return (
    <>
      <section className="ad-2 ">
        <div className="container">
          <div className="row ad-side-head text-center">
            <div className="col-md-12  ad-side">
              <div className="ad-section">
                <InjectScript script={adtwoHardcode} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AdTwo;