import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import YouTube from "react-youtube";
import { open_app } from "../../redux/actions/commonAction";
import CommonContent from "../CommonContent";
import InjectScript from "../CustomScript";

function Image(props) {
  const saveFile = (id, url, parentid) => {
    let returnurl = url;
    const defaultImageId = JSON.parse(
      localStorage.getItem("SandeshHomeImageId") || [],
    );
    defaultImageId[id] = parentid;
    localStorage.setItem("SandeshHomeImageId", JSON.stringify(defaultImageId));

    const defaultImage = JSON.parse(
      localStorage.getItem("SandeshHomeImage") || [],
    );
    const checkImage = defaultImage[parentid];
    defaultImage[parentid] = url;
    if (!checkImage) {
      try {
        localStorage.setItem("SandeshHomeImage", JSON.stringify(defaultImage));
      } catch (error) {}
    }

    if (!checkImage) {
      const xhr = new XMLHttpRequest();
      xhr.responseType = "blob";
      xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
          const href = reader.result;
          if (href) {
            const defaultImageParse = JSON.parse(
              localStorage.getItem("SandeshHomeImage") || [],
            );
            defaultImageParse[parentid] = href;
            try {
              localStorage.setItem(
                "SandeshHomeImage",
                JSON.stringify(defaultImageParse),
              );
            } catch (error) {}
          }
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", url);
      xhr.send();
    } else {
      returnurl = checkImage;
    }

    return returnurl;
  };

  let mysrc = props?.src;
  let mysrclink = props?.title;
  if (props?.dataid) {
    // mysrc = saveFile(props?.dataid, props?.src, props?.dataval);
  }

  return (
    <div className="imgWrapper">
      <img
        loading="lazy"
        className="img"
        data-id={props?.dataid}
        src={mysrc}
        alt=""
      />
    </div>
  );
}

const Home = () => {
  const { 21: assetbaseUrl, 57: liveYtUrl } = useSelector(
    (state) => state?.SettingReducer?.data,
  );
  const homePrimary = useSelector((state) => state?.HomePrimaryReducer.data);
  const homePrimaryLatest = useSelector(
    (state) => state?.HomePrimaryReducer?.data?.Latest,
  );

  let dispatch = useDispatch();

  const [vidId, setvidId] = useState("");
  const [vidTitle, setvidTitle] = useState("");
  const [vidTime, setvidTime] = useState("");
  if (!localStorage.getItem("SandeshHomeImage"))
    localStorage.setItem("SandeshHomeImage", "{}");
  if (!localStorage.getItem("SandeshHomeImageId"))
    localStorage.setItem("SandeshHomeImageId", "{}");

  const VideoModalOpen = (VidDataId, VidDataTitle, VidDataTime, e) => {
    e.preventDefault();
    e.stopPropagation();
    setvidId(VidDataId);
    setvidTitle(VidDataTitle);
    setvidTime(VidDataTime);
    document.getElementById("videoPoPup").classList.add("active-modal");
    document.body.style.overflow = "hidden";
  };

  const opts = {
    height: "100%",
    width: "100%",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      mute: 1,
      modestbranding: 1,
    },
  };
  const optsModal = {
    height: "100%",
    width: "100%",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      modestbranding: 1,
    },
  };

  const VideoModalClose = () => {
    setvidId("");
    document.body.style.overflow = "auto";
    document.getElementById("videoPoPup").classList.remove("active-modal");
  };

  useEffect(() => {
    setTimeout(() => {
      const defaultImageId = Object.entries(
        JSON.parse(localStorage.getItem("SandeshHomeImageId")),
      );
      const defaultImage = Object.entries(
        JSON.parse(localStorage.getItem("SandeshHomeImage")),
      );
      const defaultImageParse = {};

      const mycurrentId = [];
      defaultImageId.map((els) => {
        mycurrentId.push(parseInt(els[1]));
      });

      defaultImage.map((els) => {
        if (mycurrentId.includes(parseInt(els[0]))) {
          defaultImageParse[els[0]] = els[1];
        }
      });

      try {
        localStorage.setItem(
          "SandeshHomeImage",
          JSON.stringify(defaultImageParse),
        );
      } catch (error) {}
    }, 1000);
  }, []);
  const onShareClick = (e) => {
    e.preventDefault();
    document.getElementById("common-share-dropdown").classList.remove("d-none");
    return false;
  };

  const liveTVPlayer = `<script src="https://geo.dailymotion.com/player/x8q4n.js" data-video=${homePrimary?.Livetv}></script>`;

  const adtwoHardcode166 =
    "" +
    "<div id='div-gpt-ad-1646309696587-0' style='min-width: 300px; min-height: 250px;'>  " +
    "<script>" +
    "window.googletag = window.googletag || {cmd: []};" +
    "googletag.cmd.push(function() { " +
    "googletag.defineSlot('/22678428348/Mweb_HP_Desktop_ATF_300X250', [300, 250], 'div-gpt-ad-1646309696587-0').addService(googletag.pubads()); " +
    "googletag.pubads().enableSingleRequest();" +
    "googletag.pubads().disableInitialLoad(); " +
    "googletag.enableServices();  " +
    "});" +
    "</script>" +
    "" +
    "<script>" +
    "googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646309696587-0'); });" +
    "</script>" +
    "</div>" +
    // '' +
    // '<script async="async" src="https://cdn.unibots.in/headerbidding/adScriptSandesh_new.js">' +
    // ' </script>' +
    // '' +
    "" +
    "";

  const adtwoHardcode4 =
    "" +
    "<div id='div-gpt-ad-1645339970921-0' style='min-width: 300px; min-height: 250px;'>  " +
    "<script>" +
    " window.googletag = window.googletag || {cmd: []}; " +
    "googletag.cmd.push(function() { " +
    "googletag.defineSlot('/22678428348/SDPL_HP_Desktop_ATF_300X250', [300, 250], 'div-gpt-ad-1645339970921-0').addService(googletag.pubads());  " +
    "googletag.pubads().enableSingleRequest(); " +
    "googletag.pubads().disableInitialLoad();" +
    " googletag.enableServices(); " +
    "});" +
    "</script>" +
    "" +
    "<script>" +
    " googletag.cmd.push(function() { googletag.display('div-gpt-ad-1645339970921-0'); });   " +
    "</script>" +
    "</div>" +
    // '' +
    // '<script async="async" src="https://cdn.unibots.in/headerbidding/adScriptSandesh_new.js">' +
    // ' </script>' +
    // '' +
    "" +
    "";

  const Taboola1x1thumbshome =
    "" +
    '<script type="text/javascript">' +
    "window._taboola = window._taboola || [];" +
    "_taboola.push({" +
    "mode: 'thumbnails-a'," +
    "container: 'taboola-homepage-1x1-thumbs'," +
    "placement: 'Homepage-1x1-thumbs'," +
    "target_type: 'mix'" +
    "});" +
    "</script>" +
    "" +
    '<script type="text/javascript">' +
    "window._taboola = window._taboola || [];" +
    " _taboola.push({flush: true});" +
    "</script>" +
    "";

  const top10newsmweb =
    "" +
    // 'dummy test'+
    '<script type="text/javascript">' +
    "window._taboola = window._taboola || [];" +
    "_taboola.push({" +
    "mode: 'thumbnails-home-stream'," +
    "container: 'taboola-home-stream-thumbs'," +
    "placement: 'Home-stream-thumbs'," +
    "target_type: 'mix'" +
    "});" +
    "</script>" +
    "" +
    '<script type="text/javascript">' +
    "window._taboola = window._taboola || [];" +
    " _taboola.push({flush: true});" +
    "</script>" +
    "";

  const unibotssubhome =
    "" +
    "" +
    '<div id="div-ub-sandesh_home">' +
    "<script>" +
    " window.unibots = window.unibots || { cmd: [] };" +
    'unibots.cmd.push(()=>{ unibotsPlayer("sandesh_home"); });' +
    "</script>" +
    "</div>" +
    "";

  const returnfalse = async (name, category_url) => {
    await dispatch(open_app(name, category_url));
  };

  const cricketwidget = "";
  // const cricketwidget = '<iframe src="https://bwidget.crictimes.org/" style="width:100%;min-height: 250px;" frameborder="0" scrolling="yes"></iframe>';
  const cricketwidgetUnibot =
    '<div id="ub_cricwidget_carousel"></div><script async src="https://cdn.unibots.in/cricketwidget/common/script.js"></script><script>' +
    "window.unibotscw = window.unibotscw || { cmd: [] };" +
    'unibotscw.cmd.push("sandesh");' +
    "</script>";

  const crickads =
    "" +
    "<div id='div-gpt-ad-1696483309875-0' style='min-width: 300px; min-height: 250px;'>  " +
    "<script>" +
    " window.googletag = window.googletag || {cmd: []}; " +
    "googletag.cmd.push(function() { " +
    "googletag.defineSlot('/22678428348/App_ATF_Video', [300, 250], 'div-gpt-ad-1696483309875-0').addService(googletag.pubads()); " +
    "googletag.pubads().enableSingleRequest(); " +
    "googletag.pubads().disableInitialLoad();" +
    " googletag.enableServices(); " +
    "});" +
    "</script>" +
    "" +
    "<script>" +
    "  googletag.cmd.push(function() { googletag.display('div-gpt-ad-1696483309875-0'); });  " +
    "</script>" +
    "</div>" +
    "";

  return (
    <>
      <section className="home">
        <div className="container">
          {/* <div className="row mb-3">
                        <div className="col-12 col-md-12 col-lg-9">
                            <InjectScript script={cricketwidget} />
                        </div>
                        <div className="col-12 col-md-12 col-lg-3">
                        <InjectScript script={window.innerWidth < 991 ? '' : crickads} />
                            
                        </div>
                    </div> */}
          <div className="row ChangeSec">
            <div className="HomeSec1 col-12 col-md-12 col-lg-3">
              {window.screen.availWidth > 991 ? (
                <div className="livetv shadow">
                  <div className="iframeLiveTv">
                    {liveYtUrl && liveYtUrl?.value && (
                      <YouTube
                        videoId={liveYtUrl?.value}
                        loading="lazy"
                        opts={opts}
                      />
                    )}
                    {
                      homePrimary?.Livetv &&
                        homePrimary?.Trendingvideos?.length > 0 &&
                        homePrimary?.Trending?.length > 0 && <div></div>
                      // <iframe
                      //     defer
                      //     loading="lazy"
                      //     // src={`https://www.dailymotion.com/embed/video/${homePrimary?.Livetv}?autoplay=true&api=true&mute=1`}
                      //     src={`https://geo.dailymotion.com/player/x7jsd.html?video=${homePrimary?.Livetv}&mute=true`}
                      //     // src={`https://www.youtube.com/embed/${homePrimary?.Livetv1}?autoplay=true&mute=1`}
                      //     allow="autoplay; fullscreen; picture-in-picture" allowfullscreen frameborder="0"
                      //     title="video"
                      //     muted>
                      // </iframe>
                      // <iframe src={`https://www.dailymotion.com/embed/video/${homePrimary?.Livetv}?controls=0&amp;autoplay=1&amp;mute=1`} title="Dailymotion video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                      // <script src="https://geo.dailymotion.com/player/x8q4n.js" data-video={`${homePrimary?.Livetv}`}></script>
                      // <iframe src={`https://geo.dailymotion.com/player/x8q4n.html?video=${homePrimary?.Livetv}`} allow="autoplay; fullscreen; picture-in-picture" allowfullscreen frameborder="0" width="640" height="360"></iframe>

                      // <InjectScript script={liveTVPlayer}></InjectScript>
                      // <YouTube videoId="VmW8VgNOzpo" opts={opts} />

                      // <script src="https://geo.dailymotion.com/player/x8q4n.js" data-video="x86ycl3"></script>
                      // <div dangerouslySetInnerHTML={{ __html: player }}></div>

                      // <iframe
                      //     id="same-origin-iframe"
                      //     src="https://www.youtube.com/embed/VmW8VgNOzpo?autoplay=true&mute=1"
                      //     title="YouTube video player"
                      //     frameBorder="0"
                      //     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      //     allowFullScreen
                      //     pictureInPicture
                      // ></iframe>
                    }
                  </div>
                  <p className="subhome-live-tv">
                    <Link
                      onClickCapture={() =>
                        ReactGA.event({
                          category: "Homepage",
                          action: "Live TV",
                          label: "Clicked",
                        })
                      }
                      to="/videos"
                      className="text-light"
                    ></Link>
                    {/* <span>{homePrimary?.Livetvtext}</span> */}
                    <span>
                      જુઓ પળે પળની ખબર{" "}
                      <span
                        className="ml-0"
                        style={{ color: "#ae0a0b", fontWeight: "700" }}
                      >
                        24*7
                      </span>{" "}
                    </span>
                  </p>
                </div>
              ) : (
                ""
              )}
              {/* <div className='ad-section'><div id="taboola-homepage-1x1-thumbs"></div>
                            <InjectScript script={window.innerWidth > 991 ? Taboola1x1thumbshome : ''} /></div> */}
              <div className="featured">
                <div className="ad-section">
                  <div id="taboola-home-stream-thumbs"></div>
                  <InjectScript
                    script={window.innerWidth < 991 ? top10newsmweb : ""}
                  />
                </div>
                <a href="javascript:void(0)" className="title">
                  Top 10 News
                  {/*      <i className="fas fa-chevron-right"></i> */}
                </a>
                <div className="scroll s-story">
                  {homePrimary?.Trending?.length > 0 &&
                    homePrimary?.Trending?.map((els, idx) => (
                      <>
                        {/* <Link target="_parent" to={`/${els?.url}`}>
                                                <div className='col p-0 top10-img-responsive' style={{ width: '90px' }}>
                                                    <><Image src={`${assetbaseUrl?.value}${els?.media}`} alt="" />
                                                        {els?.post_type === 3 || els?.post_type === 4 ? <span className="button-hover timer_ subvideo-tagline">{els?.time} </span> : ''}
                                                        <span className="blog-time"><i className="far fa-clock mr-2"></i>{els?.publish_date}</span>
                                                    </>
                                                </div>
                                                <div className='col-7 p-0'>
                                                    <p className="color-red">{els?.category}</p>
                                                    <p className="font-size-video ellipsis">{`${els?.title}`}</p>
                                                </div>
                                            </Link> */}
                        <CommonContent
                          id="common-share-dropdown"
                          dataid={els?.id}
                          url={els?.url}
                          dataclass="top10"
                          src={`${assetbaseUrl?.value}${els?.media.replace("?resize=800,450", "")}?resize=90,51`}
                          post_type={els?.post_type}
                          publish_date={els?.publish_date}
                          category={els?.category}
                          title={els?.title}
                        />
                        <hr className="border-bt" />
                      </>
                    ))}
                </div>
              </div>
            </div>
            <a
              onClick={() => returnfalse(0, "homepage")}
              // target='_blank'
              rel="noreferrer"
              href={`https://linkapp.sandesh.com/?link=https://sandesh.com${window.location.pathname}&apn=com.sandesh.news&isi=1200153846&ibi=com.sandesh.news&ifl=https://sandesh.com/d__${window.location.pathname}&efr=1`}
              className="open_app_header"
            >
              OPEN APP
            </a>
            <div className="HomeSec2 col-md-12 col-lg-6 gujarat-responsive-space-sm 15616">
              {/* <img loading="lazy" src="https://i0.wp.com/ws.infilon.net/sandesh/img/logo.png" /> */}
              {
                <>
                  {/* {(homePrimaryLoadingState && !homePrimaryLatest?.length) ? <SkeletonTheme><Skeleton width="100%" height="350px" /></SkeletonTheme> : */}
                  <div className="row">
                    <div className="col-md-12">
                      <Link
                        target="_parent"
                        to={`/${homePrimaryLatest?.[0]?.url}`}
                        className="story"
                      >
                        <div className="caption">
                          <Image
                            dataid="v0"
                            dataval={homePrimaryLatest?.[0]?.id}
                            key={homePrimaryLatest?.[0]?.id}
                            src={`${assetbaseUrl?.value}${homePrimaryLatest?.[0]?.media.replace("?resize=800,450", "")}?resize=655,367`}
                            alt={`${homePrimaryLatest?.[0]?.title}`}
                          />
                          {homePrimaryLatest?.[0]?.post_type === 3 ||
                          homePrimaryLatest?.[0]?.post_type === 4 ? (
                            <p className="playicon-subhome">
                              <i className="fas fa-play"></i>
                            </p>
                          ) : (
                            ""
                          )}
                          {homePrimaryLatest?.[0]?.post_type === 3 ||
                          homePrimaryLatest?.[0]?.post_type === 4 ? (
                            <p className="button-hover timer_ subvideo-tagline">
                              {homePrimaryLatest?.[0]?.time}{" "}
                            </p>
                          ) : (
                            ""
                          )}
                          {homePrimaryLatest?.[0]?.tagline ? (
                            <span>{homePrimaryLatest?.[0]?.tagline}</span>
                          ) : (
                            ""
                          )}
                        </div>
                        <p className="ellipsis-one">
                          {homePrimaryLatest?.[0]?.title}
                        </p>
                        {/* <p>{homePrimaryLatest?.[0]?.description}</p> */}
                        {/* <div class="common-share-sub-story dropleft">
                                                    <button onClick={(e) => onShareClick(e)} className="dropbtn ml-2">
                                                        <i class="fa fa-share-alt"></i>
                                                    </button>
                                                    <div class="dropdown-menu">
                                                        <ShareCommon id="common-share-dropdown" url={homePrimaryLatest?.[0]?.url} media={`${assetbaseUrl?.value}${homePrimaryLatest?.[0]?.media}`} title={homePrimaryLatest?.[0]?.title} dataid={homePrimaryLatest?.[0]?.id} />
                                                    </div>
                                                </div> */}
                        <span
                          className="text-dark ellipsis"
                          dangerouslySetInnerHTML={{
                            __html: homePrimaryLatest?.[0]?.description,
                          }}
                        />
                      </Link>
                    </div>
                  </div>
                  {/* } */}
                  <div className="row substory">
                    {homePrimaryLatest?.length > 0 &&
                      homePrimaryLatest?.map((els, idx) => (
                        <>
                          {idx > 0 && (
                            <div className="col-md-6">
                              <Link
                                target="_parent"
                                to={`/${els?.url}`}
                                className="story"
                              >
                                <div className="caption">
                                  <Image
                                    dataid={`v${idx}`}
                                    dataval={els?.id}
                                    key={els?.id}
                                    src={`${assetbaseUrl?.value}${els?.media.replace("?resize=800,450", "")}?resize=311,175`}
                                    alt={`${homePrimaryLatest?.[0]?.title}`}
                                  />
                                  {els?.post_type === 3 ||
                                  els?.post_type === 4 ? (
                                    <>
                                      <p className="playicon-subhome">
                                        <i className="fas fa-play"></i>
                                      </p>
                                      <p className="button-hover topleft asd colorspan-top-trending">
                                        {els?.time}
                                      </p>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  {/* {els?.post_type === 3 || els?.post_type === 4 ?  : ""} */}
                                </div>
                                <p className="ellipsis">{els?.title}</p>
                                {/* <div class=" share-common"> */}
                                {/* <div class="common-share-sub-story dropleft">
                                                            <button onClick={(e) => onShareClick(e)} className="dropbtn ml-2">
                                                                <i class="fa fa-share-alt"></i>
                                                            </button>
                                                            <div class="dropdown-menu">
                                                                <ShareCommon id="common-share-dropdown" url={els?.url} media={`${assetbaseUrl?.value}${els?.media}`} title={els?.title} dataid={els?.id} />
                                                            </div>
                                                        </div> */}
                                {/* </div> */}
                              </Link>
                            </div>
                          )}
                        </>
                      ))}
                  </div>
                </>
              }
            </div>

            <div className="HomeSec3 insta-head-responsive col-12 col-md-12 col-lg-3 ">
              <div className="text-center">
                <div className="ad-side-head">
                  <div className="ad-side mb-2">
                    {/* {homePageRight?.[0]?.script && */}
                    <div className="ad-section">
                      {/* <HelperScript argSmall={166} argLarge={4} /> */}

                      <InjectScript
                        script={
                          window.innerWidth < 991
                            ? adtwoHardcode166
                            : adtwoHardcode4
                        }
                      />
                      {/* <div className='unibotssubhome'>
                                                <InjectScript script={unibotssubhome} />
                                            </div> */}
                    </div>
                    {/* } */}
                    {/* <Image src="https://i.imgur.com/9TvXkvh.png" alt="" /> */}
                  </div>
                </div>
              </div>

              <div className="featured">
                <a href="javascript:void(0)" className="title">
                  Trending Videos
                </a>
                <div className="s-story p-0">
                  {homePrimary?.Trendingvideos?.length > 0 &&
                    homePrimary?.Trendingvideos?.map((els, idx) => (
                      <>
                        {idx === 0 ? (
                          <>
                            <Link
                              target="_parent"
                              to={`/${els?.url}`}
                              className="img-subhome-main-video"
                            >
                              <div className="row w-100">
                                <div className="col-12 main">
                                  <div className="skeleton-sub">
                                    <Image
                                      style={{
                                        width: "100%",
                                        marginTop: "5px",
                                      }}
                                      src={`${assetbaseUrl?.value}${els?.media.replace("?resize=800,450", "")}?resize=311,175`}
                                      alt={`${homePrimaryLatest?.[0]?.title}`}
                                      key={Math.random()}
                                    />
                                    <span
                                      style={{ left: "0px" }}
                                      className="button-hover subvideo-tagline"
                                    >
                                      {els?.time}{" "}
                                    </span>
                                    <p className="playicon-subhome">
                                      <i className="fas fa-play"></i>
                                    </p>
                                  </div>
                                </div>
                                <div className="col-12 mt-2 pr-0">
                                  {/* <p className="color-red">{els?.tagline}</p> */}
                                  <p className="font-size-video ellipsis">
                                    {els?.title}
                                  </p>
                                </div>
                                {/* <div class="common-share-trend-video-head dropleft">
                                                                    <button onClick={(e) => onShareClick(e)} className="dropbtn ml-2">
                                                                        <i class="fa fa-share-alt"></i>
                                                                    </button>
                                                                    <div class="dropdown-menu">
                                                                        <ShareCommon id="common-share-dropdown" url={els?.url} media={`${assetbaseUrl?.value}${els?.media}`} title={els?.title} dataid={els?.id} />
                                                                    </div>
                                                                </div> */}
                              </div>
                            </Link>
                            <hr className="border-bt" />
                          </>
                        ) : (
                          <>
                            {idx > 0 && idx < 5 && (
                              <>
                                {els?.post_type === 4 ? (
                                  <>
                                    <div
                                      onClick={(e) => {
                                        VideoModalOpen(
                                          els?.video_yt,
                                          els?.title,
                                          els?.publish_date,
                                          e,
                                        );
                                      }}
                                    >
                                      {/* <div style={{ overflow: 'unset' }}> */}
                                      <CommonContent
                                        type="video"
                                        dataclass2="trend-bottom-share"
                                        id="common-share-dropdown"
                                        dataid={els?.id}
                                        dataclass="top10"
                                        src={`${assetbaseUrl?.value}${els?.media.replace("?resize=800,450", "")}?resize=311,175`}
                                        post_type={els?.post_type}
                                        publish_date={els?.publish_date}
                                        category={els?.category}
                                        title={els?.title}
                                      />
                                      {/* <CommonContent dataclass='top10' src={`${assetbaseUrl?.value}${els?.media}`} post_type={els?.post_type} time={els?.time} publish_date={els?.publish_date} category={els?.category} title={els?.title} /> */}
                                    </div>
                                    <hr className="border-bt" />
                                  </>
                                ) : (
                                  <>
                                    {/* <CommonContent url={els?.url} dataclass='top10' src={`${assetbaseUrl?.value}${els?.media}`} post_type={els?.post_type} time={els?.time} publish_date={els?.publish_date} category={els?.category} title={els?.title} /> */}
                                    <CommonContent
                                      type="video"
                                      id="common-share-dropdown"
                                      dataid={els?.id}
                                      url={els?.url}
                                      dataclass="top10"
                                      src={`${assetbaseUrl?.value}${els?.media.replace("?resize=800,450", "")}?resize=90,51`}
                                      post_type={els?.post_type}
                                      publish_date={els?.publish_date}
                                      category={els?.category}
                                      title={els?.title}
                                    />

                                    <hr className="border-bt" />
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="videoPoPup" className="videoSidebar">
          <div className="closebtn" onClick={() => VideoModalClose()}>
            <i className="fas fa-times"></i>
          </div>
          {/* <Dailymotion
                        className="dailymotion-videoPoPup-page"
                        video={`${vidId}`}
                        showEndScreen={true}
                        uiTheme="light"
                        autoplay
                        uiShowLogo="false"
                        origin=""
                    /> */}
          <YouTube
            className="dailymotion-videoPoPup-page"
            videoId={vidId}
            opts={optsModal}
          />

          {/* <div className="videoPoPup-details">
                        <p>{vidTitle}</p>
                        <span><i className="far fa-clock"></i>&nbsp;{vidTime}</span>
                    </div> */}
        </div>
      </section>
    </>
  );
};

export default Home;
