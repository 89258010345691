import {
  HOROSCOPE_ARE_LOADING,
  HOROSCOPE_FETCH_DATA_SUCCESS,
  HOROSCOPE_HAVE_ERROR,
} from "../actionType";
import HttpService from "../../utils/httpService";
import { Get } from "../../services/api.service";

// Action creator function to handle different types of horoscope actions
export function HoroscopeAction(action, payload) {
  switch (action) {
    case HOROSCOPE_HAVE_ERROR:
      return {
        type: action,
        hasError: payload,
      };

    case HOROSCOPE_ARE_LOADING:
      return {
        type: action,
        isLoading: payload,
      };

    case HOROSCOPE_FETCH_DATA_SUCCESS:
      return {
        type: action,
        horoscope: payload,
      };

    default:
      return;
  }
}

// Async action creator function to fetch horoscope data
export function horoscopeFetchData() {
  return (dispatch) => {
    try {
      // Dispatch action indicating data is loading
      dispatch(HoroscopeAction(HOROSCOPE_ARE_LOADING, true));

      // Fetch horoscope data using GET request
      return new Promise((resolve, reject) => {
        Get(HttpService.horoscope).then(
          (result) => {
            // Dispatch action with fetched horoscope data
            dispatch(HoroscopeAction(HOROSCOPE_FETCH_DATA_SUCCESS, result.data));
            resolve(result);
          },
          (error) => {
            reject(error); // Reject promise with error if fetching fails
          },
        );
      });
    } catch (error) {
      // Dispatch action if there's an error in fetching data
      dispatch(HoroscopeAction(HOROSCOPE_HAVE_ERROR, true));
    }
  };
}
