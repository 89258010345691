import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import InjectScript from "../../CustomScript";
import HomeTaboola from "../../subcomponents/HomeTaboola";

function ClippedImage(props) {
  // State variables initialization
  const [zoomImg, setZoomImg] = useState("");
  const [zoomImgPopup, setZoomImgPopup] = useState(false);
  const [urlParam, setUrlParams] = useState({});
  const [downloadLink, setDownloadLink] = useState("");

  // Get base URL for assets from Redux store
  const { 21: assetbaseUrl } = useSelector((state) => state?.SettingReducer?.data);

  // Function to close current window
  const onClose = () => {
    window.opener = null;
    window.open("", "_self");
    window.close();
  };

  // Function to save file from provided URL
  const saveFile = (url) => {
    const xhr = new XMLHttpRequest();
    xhr.responseType = "blob";
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        const href = reader.result;
        if (href) {
          setDownloadLink(href);
        }
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.send();
  };

  // Function to convert date string to formatted date
  const convertDateStringToDate = (dateStr) => {
    let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    let date = new Date(dateStr);
    let str = date.getDate() + " " + months[date.getMonth()] + " " + date.getFullYear();
    return str;
  };

  // Effect to set URL parameters
  useEffect(() => {
    const params = Object.fromEntries(new URLSearchParams(window.location.search));
    setUrlParams(params);
  }, []);

  // Effect to save file when URL parameters change
  useEffect(() => {
    saveFile("https://assets.sandesh.com/" + urlParam?.path);
  }, [urlParam]);

  // Function to open Zoom modal
  const ZoomModalOpen = (VidDataId, VidDataTitle, VidDataTime) => {
    ReactGA.event({
      category: "E-Paper Clipped Zoom",
      action: urlParam?.name,
      label: urlParam?.date + " - " + urlParam?.path,
    });
    setZoomImg(VidDataId);
    setTimeout(() => {
      document.getElementById("ZoomModal").classList.add("active-modal");
      document.body.style.overflow = "hidden";
    }, 50);
  };

  // Function to toggle Zoom in/out
  const ZoomInOut = () => {
    setZoomImgPopup(!zoomImgPopup);
  };

  // Function to close Zoom modal
  const ZoomModalClose = () => {
    setZoomImg("");
    document.body.style.overflow = "auto";
    document.getElementById("ZoomModal").classList.remove("active-modal");
  };

  // HTML snippet for ad
  const adSnippet =
    "<div id='div-gpt-ad-1657961203661-0' style='min-width: 300px; min-height: 250px;'>" +
    "<script>" +
    "window.googletag = window.googletag || {cmd: []};" +
    "googletag.cmd.push(function() {" +
    "googletag.defineSlot('/22678428348/Mweb_Epaper_AboveBelowclippedimg_300x250', [300, 250], 'div-gpt-ad-1657961203661-0').addService(googletag.pubads());" +
    "googletag.pubads().enableSingleRequest();" +
    "googletag.enableServices();" +
    "});" +
    "</script>" +
    "" +
    "<script>" +
    "googletag.cmd.push(function() {googletag.display('div-gpt-ad-1657961203661-0'); });" +
    "</script>" +
    "</div>" +
    "";

  // JSX for the component
  return (
    <>
      <Helmet>
        <title>{urlParam?.name ? urlParam?.name : ""} - E-Paper | Sandesh</title>
      </Helmet>
      <div className="subPaper">
        <div className="container">
          {/* Header */}
          <div className="row subPaperHeader">
            <div className="col-md-2">
              <h1>{urlParam?.name}</h1>
              <b>{convertDateStringToDate(urlParam?.date)}</b>
            </div>
            <div className="col-md-6">{window.innerWidth > 991 ? <InjectScript script={adSnippet} /> : ""}</div>
            <div className="col-md-4">
              <div className="text-right">
                {/* Zoom and Close buttons */}
                <a onClickCapture={() => ZoomModalOpen("https://assets.sandesh.com/" + urlParam?.path)} className="cursor-pointer new_zoom pb-2  mr-2" rel="noreferrer">
                  <i className="fa fa-search-plus"></i> &nbsp;Zoom
                </a>
                <button onClick={() => onClose()} className="btn-close m-0">
                  <i className="fas fa-times mr-2"></i>Close
                </button>
              </div>
            </div>
          </div>
          {/* Image */}
          <div className="row subPaperBody">
            <div className="col-md-12 text-center">
              <img loading="lazy" src={"https://epapercdn.sandesh.com/" + urlParam?.path} alt={urlParam?.name} />
            </div>
          </div>
          {/* Footer */}
          <div className="row subPaperHeader subPaperFooter">
            <div className="col-md-2">
              <h1>{urlParam?.name}</h1>
              <b>{convertDateStringToDate(urlParam?.date)}</b>
            </div>
            <div className="col-md-6">{window.innerWidth > 991 ? <InjectScript script={adSnippet} /> : ""}</div>
            <div className="col-md-4">
              <div className="text-right">
                {/* Zoom and Close buttons */}
                <a onClickCapture={() => ZoomModalOpen("https://epapercdn.sandesh.com/" + urlParam?.path)} className="cursor-pointer new_zoom pb-2  mr-2" rel="noreferrer">
                  <i className="fa fa-search-plus"></i> &nbsp;Zoom
                </a>
                <button onClick={() => onClose()} className="btn-close m-0">
                  <i className="fas fa-times mr-2"></i>Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Zoom Modal */}
      <div id="ZoomModal" className="videoSidebar">
        <div className="closebtn-zoom-epaper" onClick={() => ZoomModalClose()}>
          <i className="fas fa-times"></i>
        </div>

        <div className="zoombtn-inner-epaper" onClick={() => ZoomInOut()}>
          <i className={`fas fa-${zoomImgPopup ? "minus" : "plus"}`}></i> ZOOM
        </div>

        <div className={`${zoomImgPopup ? "" : "minimize"} epaperZoomer bg-white`}>
          <img loading="lazy" className={`epaper-zoom-modal-page ${zoomImgPopup ? "" : "minimize"}`} src={zoomImg} alt="" />
        </div>
      </div>
      {/* Inject HomeTaboola component */}
      <HomeTaboola key={window.location.href} />
    </>
  );
}

export default ClippedImage;

