// Import action types related to widget data
import {
  WIDGET_ARE_LOADING,
  WIDGET_HAVE_ERROR,
  WIDGET_FETCH_DATA_SUCCESS,
} from "../actionType";

// Define the initial state for the widget reducer
const initialState = {
  data: [], // Initialize data as an empty array
  loading: true, // Set loading to true initially
  error: [], // Initialize error as an empty array
};

// Reducer function to handle widget data-related actions and update state accordingly
export function widgetReducer(state = initialState, action) {
  switch (action.type) {
    // Handle error case when fetching widget data
    case WIDGET_HAVE_ERROR:
      return {
        ...state,
        error: action.hasError, // Update the error state with the error from the action
        loading: false, // Set loading to false since the request is complete
      };

    // Handle loading state when widget data is being fetched
    case WIDGET_ARE_LOADING:
      return {
        ...state,
        loading: action.isLoading, // Update the loading state with the loading status from the action
      };

    // Handle success case when widget data is successfully fetched
    case WIDGET_FETCH_DATA_SUCCESS:
      return {
        ...state,
        data: action.widget, // Update the data state with the fetched widget data
        loading: false, // Set loading to false since the request is complete
        error: [], // Clear any previous errors (assuming the success case means no error)
      };

    // Return the current state by default if action type is not recognized
    default:
      return state;
  }
}
