import React from "react";
import ReactGA from "react-ga4";
import { useDispatch, useSelector } from "react-redux";
import { sharecount } from "../../redux/actions/commonAction";

const ShareCommon = ({ id, url, media, title, dataid }) => {
  // Construct share URLs
  const urlshares = `https://${window.location.hostname}/${url}`;
  const urlshare = `${urlshares}\r\n \r\n`;

  // Get settings from Redux store
  const { assetbaseUrl, sharemsg } = useSelector((state) => state?.SettingReducer?.data);

  // Construct media URL
  const mediaUrl = `${assetbaseUrl?.value}${media}?resize=1200,630`;

  // Construct title with line break
  const titleWithLineBreak = `${title}\r\n`;

  // Construct share value with line breaks
  const shareValue = `${sharemsg?.value}\r\n \r\n`;

  // Get dispatch function from Redux
  const dispatch = useDispatch();

  // Handle share action
  const handleShare = async (name) => {
    document.getElementById(id).classList.add("d-none");
    await dispatch(sharecount(name, dataid));
    return false;
  };

  // Handle Facebook share
  const handleFacebookShare = (e) => {
    e.stopPropagation();
    handleShare("fb");
    ReactGA.event({ category: "Share", action: "Facebook", label: urlshare });
    return false;
  };

  // Handle Twitter share
  const handleTwitterShare = (e) => {
    e.stopPropagation();
    handleShare("tw");
    ReactGA.event({ category: "Share", action: "Twitter", label: urlshare });
    return false;
  };

  // Handle Whatsapp share
  const handleWhatsappShare = (e) => {
    e.stopPropagation();
    handleShare("wa");
    ReactGA.event({ category: "Share", action: "Whatsapp", label: urlshare });
    return false;
  };

  // Handle Email share
  const handleEmailShare = (e) => {
    e.stopPropagation();
    ReactGA.event({ category: "Share", action: "Email", label: urlshare });
    return false;
  };

  // Handle copy action
  const handleCopy = (e) => {
    handleShare("copy");
    ReactGA.event({ category: "Share", action: "Copy", label: urlshare });
    // Create textarea element
    const textArea = document.createElement("textarea");
    //... rest of the copy logic
  };

  return (
    <>
      {/* Facebook share button */}
      <a
        id="dropdown"
        onClickCapture={handleFacebookShare}
        onClick={() => handleShare("fb")}
        className="social-share"
        aria-label="Facebook"
        href={`https://facebook.com/sharer/sharer.php?display=page&picture=${encodeURIComponent(mediaUrl)}&quote=${encodeURIComponent(titleWithLineBreak + urlshare)}&u=${encodeURIComponent(urlshare)}`}
        target="_blank"
        rel="noreferrer"
      >
        <i className="fab fa-facebook"></i>
      </a>

      {/* Twitter share button */}
      <a
        id="dropdown"
        onClickCapture={handleTwitterShare}
        onClick={() => handleShare("tw")}
        className="social-share"
        aria-label="Twitter"
        href={`https://twitter.com/share?text=${encodeURIComponent(titleWithLineBreak)}&url=${encodeURIComponent(urlshare)}${encodeURIComponent(shareValue)}`}
        target="_blank"
        rel="noreferrer"
      >
        <i className="fab fa-twitter"></i>
      </a>

      {/* Whatsapp share button */}
      <a
        id="dropdown"
        onClickCapture={handleWhatsappShare}
        onClick={() => handleShare("wa")}
        className="social-share"
        aria-label="Whatsapp"
        href={`https://api.whatsapp.com/send?text=${encodeURIComponent(titleWithLineBreak)}${encodeURIComponent(urlshare)}${encodeURIComponent(shareValue)}`}
        target="_blank"
        rel="noreferrer"
      >
        <i className="fab fa-whatsapp"></i>
      </a>

      {/* Email share button */}
      <a
        id="dropdown"
        onClickCapture={handleEmailShare}
        onClick={() => handleShare("em")}
        target="_blank"
        href={`mailto:?subject=${titleWithLineBreak}&body=${titleWithLineBreak}%0D%0A%0D%0A${urlshare}%0D%0A%0D%0A${shareValue}`}
        className="social-share cursor-pointer"
        rel="noreferrer"
      >
        <i class="fa fa-envelope"></i>
      </a>
    </>
  );
};

export default ShareCommon;