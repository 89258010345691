import {
  CITY_RESULT_FETCH_DATA_SUCCESS,
  CITY_RESULT_HAVE_ERROR,
  CITY_RESULT_ARE_LOADING,
} from "../actionType";
import HttpService from "../../utils/httpService";
import { Get } from "../../services/api.service";

// Action creator function to create actions for city result data
export function CityResultAction(action, payload) {
  switch (action) {
    case CITY_RESULT_HAVE_ERROR:
      return {
        type: action,
        hasError: payload,
      };

    case CITY_RESULT_ARE_LOADING:
      return {
        type: action,
        isLoading: payload,
      };

    case CITY_RESULT_FETCH_DATA_SUCCESS:
      return {
        type: action,
        cityResult: payload,
      };

    default:
      return;
  }
}

// Async action creator function to fetch city result data from the server
export function CityResultFetchData(city) {
  return (dispatch) => {
    try {
      return new Promise((resolve, reject) => {
        dispatch(CityResultAction(CITY_RESULT_ARE_LOADING, true)); // Dispatch loading action
        // Fetch city result data from the server
        Get(HttpService.cityResult + city).then(
          (result) => {
            // Dispatch success action with the fetched data
            dispatch(CityResultAction(CITY_RESULT_FETCH_DATA_SUCCESS, result));
            resolve(result); // Resolve promise with the fetched data
          },
          (error) => {
            reject(error); // Reject promise if there is an error
          },
        );
      });
    } catch (error) {
      dispatch(CityResultAction(CITY_RESULT_HAVE_ERROR, true)); // Dispatch error action if an exception occurs
    }
  };
}
