// Import action types related to e-paper category data
import {
  EPAPER_CATEGORY_ARE_LOADING,
  EPAPER_CATEGORY_HAVE_ERROR,
  EPAPER_CATEGORY_FETCH_DATA_SUCCESS,
} from "../actionType";

// Define the initial state for the EPaperCategory reducer
const initialState = {
  data: [],
  loading: true,
  error: [], // Initialize error as null for better error handling
};

// Reducer function to handle e-paper category-related actions and update state accordingly
export function EPaperCategory(state = initialState, action) {
  switch (action.type) {
    // Handle error case when fetching e-paper category data
    case EPAPER_CATEGORY_HAVE_ERROR:
      return {
        ...state,
        error: action.hasError, // Update the error state with the error from the action
        loading: false, // Set loading to false since the request is complete
      };

    // Handle loading state when e-paper category data is being fetched
    case EPAPER_CATEGORY_ARE_LOADING:
      return {
        ...state,
        loading: action.isLoading, // Update the loading state with the loading status from the action
      };

    // Handle success case when e-paper category data is successfully fetched
    case EPAPER_CATEGORY_FETCH_DATA_SUCCESS:
      return {
        ...state,
        data: action.epaperCategory, // Update the data state with the fetched e-paper category data
        loading: false, // Set loading to false since the request is complete
        error: null, // Clear any previous errors
      };

    // Return the current state by default if action type is not recognized
    default:
      return state;
  }
}
