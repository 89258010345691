import React, { useEffect } from "react";
import InjectScript from "../CustomScript";

const HomeTaboola = () => {
  // Add event listener to handle scroll event
  useEffect(() => {
    const handleScroll = () => {
      const taboolaElement = document.getElementsByClassName("taboola")[0];
      const footerBottomElement = document.getElementById("footer_bottom");
      if (checkVisible(taboolaElement)) {
        footerBottomElement.classList.add("fixed");
      } else {
        footerBottomElement.classList.remove("fixed");
      }
    };

    document.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Define the Taboola script content
  const content = `
    <script type="text/javascript">
      window._taboola = window._taboola || [];
      _taboola.push({
        mode: 'alternating-thumbnails-h',
        container: 'taboola-feed-below-home-page-thumbnails',
        placement: 'Feed below Home page Thumbnails',
        target_type: 'mix'
      });
    </script>
  `;

  return (
    <div className="container my-4 mb-10 taboola">
      <div id="taboola-feed-below-home-page-thumbnails"></div>
      <InjectScript type="taboola" script={content} key={window.location.href} />
    </div>
  );
};

// Define the checkVisible function
function checkVisible(elm) {
  if (elm) {
    const rect = elm.getBoundingClientRect();
    const viewHeight = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight
    );
    return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
  } else {
    return false;
  }
}

export default HomeTaboola;