import { combineReducers } from "redux";
import { MenuReducer } from "./menu";
import { FooterReducer } from "./footer";
import { PageReducer } from "./page";
import { InvRelReducer } from "./inv-rel";
import { GallaryReducer } from "./gallary";
import { SettingReducer } from "./setting";
import { GallaryInnerReducer } from "./gallaryInner";
import { HomeSecondaryReducer } from "./homeSecondary";
import { HomePrimaryReducer } from "./homePrimary";
import { PostReducer } from "./post";
import { VideoInnerReducer } from "./videoInner";
import { CategoryReducer, EntertainmentReducer } from "./category";
import { CategoryAstroReducer } from "./categoryAstro";
import { GujaratSamagraCityReducer } from "./gujaratSamagraCity";
import { VideoReducer } from "./video";
import { LogingReducer } from "./login";
import { CityReducer } from "./city";
import { CityResultReducer } from "./cityResult";
import { TithiReducer } from "./tithi";
import { ChildmenuReducer } from "./childmenu";
import { HoroscopeReducer } from "./horoscope";
import { widgetReducer } from "./widgetReducer";
import { EPaperCategory } from "./EPaperCategory";
import { ePaperMenuReducer } from "./ePaperMenu";
import { searchResult } from "./searchResultAction";
import { inputSearchReducer } from "./searchValue";
import { ePaperImageReducer } from "./EPaperImage";
import { CommentReducer } from "./comment";
import { VideoCategoryReducer } from "./videoCategory";
import { SportScoreReducer } from "./sportScore";
import { MarqueeReducer } from "./marquee";

// Combine all reducers into a single root reducer
export default combineReducers({
  SettingReducer, // Setting reducer for managing application settings
  MenuReducer, // Menu reducer for managing menu state
  FooterReducer, // Footer reducer for managing footer state
  PageReducer, // Page reducer for managing page data
  InvRelReducer, // Invest Relation reducer for managing invest relation data
  GallaryReducer, // Gallery reducer for managing gallery data
  SettingReducer, // Setting reducer for managing application settings
  GallaryInnerReducer, // Inner gallery reducer for managing inner gallery data
  HomeSecondaryReducer, // Home secondary reducer for managing secondary home data
  HomePrimaryReducer, // Home primary reducer for managing primary home data
  PostReducer, // Post reducer for managing post data
  VideoInnerReducer, // Inner video reducer for managing inner video data
  CategoryReducer, // Category reducer for managing category data
  EntertainmentReducer, // Entertainment reducer for managing entertainment data
  CategoryAstroReducer, // Astro category reducer for managing astro category data
  ePaperImageReducer, // ePaper image reducer for managing ePaper image data
  GujaratSamagraCityReducer, // Gujarat Samagra City reducer for managing city data
  VideoReducer, // Video reducer for managing video data
  LogingReducer, // Login reducer for managing login state
  CityReducer, // City reducer for managing city data
  CityResultReducer, // City result reducer for managing city result data
  TithiReducer, // Tithi reducer for managing tithi data
  ChildmenuReducer, // Child menu reducer for managing child menu data
  HoroscopeReducer, // Horoscope reducer for managing horoscope data
  widgetReducer, // Widget reducer for managing widget data
  EPaperCategory, // ePaper category reducer for managing ePaper category data
  ePaperMenuReducer, // ePaper menu reducer for managing ePaper menu data
  searchResult, // Search result reducer for managing search result data
  searchValue: inputSearchReducer, // Search value reducer for managing search value data
  CommentReducer, // Comment reducer for managing comment data
  VideoCategoryReducer, // Video category reducer for managing video category data
  SportScoreReducer, // Sport score reducer for managing sport score data
  MarqueeReducer, // Marquee reducer for managing marquee data
});
