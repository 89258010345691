import {
  FOOTER_ARE_LOADING,
  FOOTER_FETCH_DATA_SUCCESS,
  FOOTER_HAVE_ERROR,
} from "../actionType";
import HttpService from "../../utils/httpService";
import { Get } from "../../services/api.service";

// Action creator function to handle different types of footer actions
export function FooterAction(action, payload) {
  switch (action) {
    case FOOTER_HAVE_ERROR:
      return {
        type: action,
        hasError: payload,
      };

    case FOOTER_ARE_LOADING:
      return {
        type: action,
        isLoading: payload,
      };

    case FOOTER_FETCH_DATA_SUCCESS:
      return {
        type: action,
        Footer: payload,
      };

    default:
      return;
  }
}

// Async action creator function to fetch footer data
export function footerFetchData() {
  return (dispatch) => {
    try {
      // Dispatch action indicating data is loading
      dispatch(FooterAction(FOOTER_ARE_LOADING, true));

      // Fetch footer data using GET request
      return new Promise((resolve, reject) => {
        Get(HttpService.footer).then(
          (result) => {
            // Dispatch action with fetched footer data
            dispatch(FooterAction(FOOTER_FETCH_DATA_SUCCESS, result.data));
            resolve(result);
          },
          (error) => {
            reject(error); // Reject promise with error if fetching fails
          },
        );
      });
    } catch (error) {
      // Dispatch action if there's an error in fetching data
      dispatch(FooterAction(FOOTER_HAVE_ERROR, true));
    }
  };
}
