import {
  HOMESECONDARY_FETCH_DATA_SUCCESS,
  HOMESECONDARY_HAVE_ERROR,
  HOMESECONDARY_ARE_LOADING,
} from "../actionType";
import HttpService from "../../utils/httpService";
import { Get } from "../../services/api.service";

// Action creator function to handle different types of home secondary actions
export function homeSecondaryAction(action, payload) {
  switch (action) {
    case HOMESECONDARY_HAVE_ERROR:
      return {
        type: action,
        hasError: payload,
      };

    case HOMESECONDARY_ARE_LOADING:
      return {
        type: action,
        isLoading: payload,
      };

    case HOMESECONDARY_FETCH_DATA_SUCCESS:
      return {
        type: action,
        homeSecondary: payload,
      };

    default:
      return;
  }
}

// Async action creator function to fetch home secondary data
export function homeSecondaryFetchData() {
  return (dispatch) => {
    try {
      // Dispatch action indicating data is loading
      dispatch(homeSecondaryAction(HOMESECONDARY_ARE_LOADING, true));

      // Check if home secondary data is available in local storage
      if (localStorage.getItem("SandeshHomeSub")) {
        // If available, dispatch action with data from local storage
        dispatch(
          homeSecondaryAction(
            HOMESECONDARY_FETCH_DATA_SUCCESS,
            JSON.parse(localStorage.getItem("SandeshHomeSub")),
          ),
        );
      }

      // Fetch home secondary data using GET request
      return new Promise((resolve, reject) => {
        Get(HttpService.homeSecondary).then(
          (result) => {
            // Store fetched data in local storage
            localStorage.setItem("SandeshHomeSub", JSON.stringify(result.data));
            // Dispatch action with fetched home secondary data
            dispatch(homeSecondaryAction(HOMESECONDARY_FETCH_DATA_SUCCESS, result.data));
            resolve(result);
          },
          (error) => {
            reject(error); // Reject promise with error if fetching fails
          },
        );
      });
    } catch (error) {
      // Dispatch action if there's an error in fetching data
      dispatch(homeSecondaryAction(HOMESECONDARY_HAVE_ERROR, true));
    }
  };
}
