import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import CategoryTaboola from '../subcomponents/CategoryTaboola';
import HomeTaboola from "../subcomponents/HomeTaboola";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { CategoryFetchData } from "../../redux/actions/categoryAction";
import { Helmet } from "react-helmet";
import InjectScript from "../CustomScript";
import "../../styles/custom.css";
import ReactGA from "react-ga4";
import CommonContent from "../CommonContent";
// import YouTube from 'react-youtube';
import Dailymotion from "react-dailymotion";

const totalNum_recored = 24; // 24

const AssemblyElection = (props) => {
  const urlParam = new URLSearchParams(window.location.search);
  const myParamPage = parseInt(urlParam.get("page"));

  const [activePage, setActivePage] = React.useState(
    myParamPage ? parseInt(myParamPage) : 1,
  );

  const [vidId, setvidId] = useState("");
  const [vidTitle, setvidTitle] = useState("");
  const [vidTime, setvidTime] = useState("");
  // console.log('activePageactivePageactivePage', activePage);
  const [pageArray, setPageArray] = React.useState([]);
  const [Topten, setTopten] = React.useState([]);
  const homePrimary = useSelector((state) => state?.HomePrimaryReducer.data);
  const innerData = useSelector((state) => state?.CategoryReducer?.data?.posts);
  // const innerDataTopTen = useSelector((state) => state?.CategoryReducer?.data?.topten)
  const pageCount = useSelector((state) => state?.CategoryReducer?.data?.count);
  const pageName = useSelector((state) => state?.CategoryReducer?.data?.name);
  // const pagemessage = useSelector((state) => state?.CategoryReducer?.data?.message)
  const loading = useSelector((state) => state?.CategoryReducer?.loading);
  const { 21: assetbaseUrl } = useSelector(
    (state) => state?.SettingReducer?.data,
  );

  // const { 43: Categoryheader } = useSelector((state) => state?.widgetReducer?.data);
  // const { 47: CategoryFooter } = useSelector((state) => state?.widgetReducer?.data);

  // const { 57: firstright } = useSelector((state) => state?.widgetReducer?.data);
  // const { 58: secondright } = useSelector((state) => state?.widgetReducer?.data);
  // const { 59: thirdright } = useSelector((state) => state?.widgetReducer?.data);
  // const { 100: fourthright } = useSelector((state) => state?.widgetReducer?.data);

  // const { 51: threepost } = useSelector((state) => state?.widgetReducer?.data);
  // const { 52: sixpost } = useSelector((state) => state?.widgetReducer?.data);
  // const { 53: ninepost } = useSelector((state) => state?.widgetReducer?.data);
  // const { 54: twelvepost } = useSelector((state) => state?.widgetReducer?.data);

  const { country, state, city } = useParams();
  const url = "assembly-elections";
  let dispatch = useDispatch();
  const config = {
    ...(country && { country: country }),
    ...(state && { state: state }),
    ...(city && { city: city }),
    page: activePage,
    limit: totalNum_recored,
  };
  useEffect(() => {
    if (state || city || country || url) {
      // const urlParam = new URLSearchParams(window.location.search);
      // const myParamPage = parseInt(urlParam.get('page'));
      // setActivePage(myParamPage ? parseInt(myParamPage) : 1);

      // setActivePage(1);
      dispatch(
        CategoryFetchData(
          props.type,
          config,
          url + `?page=${activePage}&limit=${totalNum_recored}`,
        ),
      ).then((res) => {
        setTopten(res.topten);
        // setActivePage(1);
      });
      // setActivePage(1);
    }
  }, [state, city, country, url]);

  useEffect(() => {
    // dispatch(homePrimaryFetchData());
    if (pageCount) {
      const checkPageSize = Math.ceil(pageCount / totalNum_recored);
      const array = Array.from(Array(checkPageSize).keys());
      setPageArray(array);
    }
    ReactGA.event({
      category: "Category Page",
      action: "Category",
      label: innerData?.name,
    });
  }, [innerData, pageCount, url]);

  let navigate = useNavigate();
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = parseInt(urlParams.get("page")) || 1;
    if (myParam !== parseInt(activePage)) {
      if (parseInt(activePage) === 1) {
        navigate(window.location.pathname);
      } else {
        navigate(window.location.pathname + "?page=" + activePage);
      }
    }
    window.scrollTo(0, 0);
    dispatch(
      CategoryFetchData(
        props.type,
        config,
        url + `?page=${activePage}&limit=${totalNum_recored}`,
      ),
    );
    ReactGA.event({
      category: "Category Page",
      action: "Category",
      label: innerData?.name,
    });
  }, [activePage]);

  const VideoModalOpen = (VidDataId, VidDataTitle, VidDataTime) => {
    setvidId(VidDataId);
    setvidTitle(VidDataTitle);
    setvidTime(VidDataTime);
    document.getElementById("videoPoPup").classList.add("active-modal");
    document.body.style.overflow = "hidden";
  };

  const VideoModalClose = () => {
    setvidId("");
    document.body.style.overflow = "auto";
    document.getElementById("videoPoPup").classList.remove("active-modal");
  };
  const optsModal = {
    height: "100%",
    width: "100%",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
      modestbranding: 1,
    },
  };

  const taboolacontent =
    "" +
    "" +
    // '<div id="taboola-right-rail-thumbnails"></div>' +
    "" +
    '<script type="text/javascript">' +
    "window._taboola = window._taboola || [];" +
    "_taboola.push({" +
    "mode: 'thumbnails-rr'," +
    "container: 'taboola-right-rail-thumbnails'," +
    "placement: 'Right Rail Thumbnails'," +
    "target_type: 'mix'" +
    "});" +
    "</script>" +
    "" +
    "";

  const businessWidget1 =
    "" +
    '<div style="height:433px; background-color: #FFFFFF; overflow:hidden; box-sizing: border-box; border: 1px solid #56667F; border-radius: 4px; text-align: right; line-height:14px; font-size: 12px; font-feature-settings: normal; text-size-adjust: 100%; box-shadow: inset 0 -20px 0 0 #56667F; padding: 0px; margin: 0px; width: 100%;"><div style="height:413px; padding:0px; margin:0px; width: 100%;"><iframe loading="lazy" src="https://widget.coinlib.io/widget?type=full_v2&theme=light&cnt=6&pref_coin_id=1505&graph=yes" width="100%" height="409px" scrolling="auto" marginwidth="0" marginheight="0" frameborder="0" border="0" style="border:0;margin:0;padding:0;"></iframe></div><div style="color: #FFFFFF; line-height: 14px; font-weight: 400; font-size: 11px; box-sizing: border-box; padding: 2px 6px; width: 100%; font-family: Verdana, Tahoma, Arial, sans-serif;"><a href="https://coinlib.io" target="_blank" style="font-weight: 500; color: #FFFFFF; text-decoration:none; font-size:11px">Cryptocurrency Prices</a>&nbsp;by Coinlib</div></div>' +
    "";

  const businessWidget2 =
    "" +
    '<div class="tradingview-widget-container"><div class="tradingview-widget-container__widget"></div><div class="tradingview-widget-copyright"><a href="https://in.tradingview.com/markets/indices/" rel="noopener" target="_blank"><span class="blue-text">Indices</span></a> <span class="blue-text">and</span> <a href="https://in.tradingview.com/markets/currencies/" rel="noopener" target="_blank"><span class="blue-text">Forex</span></a> by TradingView</div>' +
    '<script type="text/javascript" src="https://s3.tradingview.com/external-embedding/embed-widget-market-overview.js" async> {"colorTheme": "light","dateRange": "12M","showChart": false,"locale": "in","largeChartUrl": "","isTransparent": false,"showSymbolLogo": true,"showFloatingTooltip": false,"width": "400","height": "465","tabs": [ { "title": "Indices", "symbols": [ { "s": "FOREXCOM:SPXUSD","d": "S&P 500" }, {"s": "FOREXCOM:NSXUSD", "d": "US 100" },' +
    '{ "s": "FOREXCOM:DJI", "d": "Dow 30" }, { "s": "INDEX:NKY", "d": "Nikkei 225"  }, { "s": "INDEX:DEU40", "d": "DAX Index"},' +
    '{ "s": "FOREXCOM:UKXGBP", "d": "UK 100" },{"s": "BSE:SENSEX" }, { "s": "CAPITALCOM:NIFTY50" } ], "originalTitle": "Indices" },' +
    '{"title": "Forex","symbols": [ { "s": "FX:EURUSD", "d": "EUR/USD" }, { "s": "FX:GBPUSD", "d": "GBP/USD" }, {"s": "FX:USDJPY", "d": "USD/JPY" },' +
    '{"s": "FX:USDCHF","d": "USD/CHF"},{"s": "FX:AUDUSD","d": "AUD/USD"},{"s": "FX:USDCAD","d": "USD/CAD"}], "originalTitle": "Forex"}]}' +
    "</script> </div>" +
    "" +
    "";

  const businessWidget3 =
    "" +
    '<div class="tradingview-widget-container"><div class="tradingview-widget-container__widget"></div>' +
    '<div class="tradingview-widget-copyright"><a href="https://in.tradingview.com/markets/stocks-india/" rel="noopener" target="_blank"><span class="blue-text">Stock Market Today</span></a> by TradingView</div>' +
    '<script type="text/javascript" src="https://s3.tradingview.com/external-embedding/embed-widget-hotlists.js" async>' +
    '{ "colorTheme": "light","dateRange": "12M","exchange": "BSE","showChart": false,"locale": "in","largeChartUrl": "",' +
    ' "isTransparent": false,"showSymbolLogo": true,"showFloatingTooltip": false, "width": "400","height": "407"} </script> </div>' +
    "" +
    "";

  const Titlecapitalize = (str, lower = false) =>
    (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, (match) =>
      match.toUpperCase(),
    );
  const gallery57 =
    " <div id='div-gpt-ad-1646037902231-0' style='min-width: 300px; min-height: 250px;'> <script> window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() { googletag.defineSlot('/22678428348/SDPL_CP_Desktop_ATF_300X250', [300, 250], 'div-gpt-ad-1646037902231-0').addService(googletag.pubads()); googletag.pubads().enableSingleRequest(); googletag.enableServices(); }); </script>  <script> googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646037902231-0'); }); </script> </div>";
  const gallery58 =
    " <div id='div-gpt-ad-1646037931655-0' style='min-width: 300px; min-height: 250px;'><script> window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() { googletag.defineSlot('/22678428348/SDPL_CP_Desktop_BTF1_300X250', [300, 250], 'div-gpt-ad-1646037931655-0').addService(googletag.pubads()); googletag.pubads().enableSingleRequest(); googletag.enableServices(); }); </script>  <script> googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646037931655-0'); }); </script> </div>";
  // const gallery201 = " <div id='div-gpt-ad-1646388561181-0' style='min-width: 300px; min-height: 250px;'> <script> window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() { googletag.defineSlot('/22678428348/Mweb_CP_Desktop_ATF_300X250', [300, 250], 'div-gpt-ad-1646388561181-0').addService(googletag.pubads()); googletag.pubads().enableSingleRequest(); googletag.pubads().disableInitialLoad(); googletag.enableServices(); }); </script> <script> googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646388561181-0'); }); </script> </div>"
  const gallery201 =
    "" +
    " <div id='div-gpt-ad-1646388561181-0' style='min-width: 300px; min-height: 250px;'> <script> window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() { googletag.defineSlot('/22678428348/Mweb_CP_Desktop_ATF_300X250', [300, 250], 'div-gpt-ad-1646388561181-0').addService(googletag.pubads()); googletag.pubads().enableSingleRequest(); googletag.pubads().disableInitialLoad(); googletag.enableServices(); }); </script> <script> googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646388561181-0'); }); </script> </div>" +
    // '<div id="gpt-passback">'+
    // '<script>'+
    // 'window.googletag = window.googletag || {cmd: []};'+
    // 'googletag.cmd.push(function() {'+
    // "googletag.defineSlot('/21928950349,22624158342/sandesh_backfill_300x250', [300, 250], 'gpt-passback').addService(googletag.pubads());"+
    // 'googletag.enableServices();'+
    // "googletag.display('gpt-passback');"+
    // '});'+
    // '</script>'+
    // '</div>'+
    "";
  const gallery202 =
    " <div id='div-gpt-ad-1646388591019-0' style='min-width: 300px; min-height: 250px;'> <script> window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() { googletag.defineSlot('/22678428348/Mweb_CP_Desktop_BTF1_300X250', [300, 250], 'div-gpt-ad-1646388591019-0').addService(googletag.pubads()); googletag.pubads().enableSingleRequest(); googletag.pubads().disableInitialLoad(); googletag.enableServices(); }); </script> <script> googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646388591019-0'); }); </script> </div>";

  const afterPrimaryNewVDO =
    "" +
    // '<script defer id="vidcrunchJS632259138" src="https://cdn.vidcrunch.com/integrations/5f4fa0fc4f13d250c8dd1b3a/Sandesh.com_Both_ICF_Responsive_Playlist_190922.js">'+
    // '</script>'+
    "";
  // const afterPrimary  = "" +
  //     '<div id = "v-sandesh-v0"></div>'+
  //     ''+
  //     '<script>'+
  //     '(function(v,d,o,ai){'+
  //     "ai=d.createElement('script');"+
  //     'ai.defer=true;'+
  //     'ai.async=true;'+
  //     'ai.src=v.location.protocol+o;'+
  //     'd.head.appendChild(ai);'+
  //     '})'+
  //     "(window, document, '//a.vdo.ai/core/v-sandesh-v0/vdo.ai.js');"+
  //     "</script>"+
  //     ''

  const unibotssubhome =
    "" +
    "<div id='div-gpt-ad-1669617435638-0' style='min-width: 300px; min-height: 250px;'>" +
    "<script>" +
    "window.googletag = window.googletag || {cmd: []};" +
    "googletag.cmd.push(function() {" +
    "googletag.defineSlot('/22678428348/SDPL_CP_Desktop_ATF_300X250', [300, 250], 'div-gpt-ad-1669617435638-0').addService(googletag.pubads());" +
    "googletag.pubads().enableSingleRequest();" +
    "googletag.enableServices();" +
    "});" +
    "</script>" +
    "<script>" +
    "googletag.cmd.push(function() { googletag.display('div-gpt-ad-1669617435638-0'); });" +
    "</script>" +
    "</div>" +
    "";

  return (
    <>
      {props.type === "location" && (
        <Helmet>
          <title>Location | Sandesh</title>
        </Helmet>
      )}
      {pageName?.length && (
        <Helmet>
          <title>{Titlecapitalize(pageName)} | Sandesh</title>
        </Helmet>
      )}
      {/* {Categoryheader?.[0]?.script && */}
      <div className="ad-section">
        {/* <HelperScript argSmall={190} argLarge={43} /> */}
        {/* <InjectScript script={Categoryheader?.[0]?.script} /> */}
      </div>
      {/* } */}
      <section
        className="gujarat isCategoryPage"
        style={{ opacity: loading ? "0.5" : 1 }}
      >
        <div className="container">
          <div className="row">
            {window.innerWidth < 768 ? (
              <iframe
                loading="lazy"
                src="https://sandesh.pages.dev/"
                style={{
                  height: "800px",
                  width: "100%",
                  border: "none",
                  zIndex: 99,
                }}
                title="Election Table"
              />
            ) : (
              ""
            )}
            <div className="col-sm-12 col-md-7 col-lg-8 col-xl-9 gujarat-responsive-space-sm">
              {innerData?.length > 0 ? (
                <div className="shadow p-3 mb-3 bg-white rounded">
                  <div className="row">
                    <div className="col-xl-8 megapreView col-lg-12">
                      {innerData?.length > 0 &&
                        innerData?.map((els, idx) => (
                          <>
                            {idx === 0 && (
                              <>
                                {els?.post_type === 4 ? (
                                  <a
                                    className="story cursor-pointer"
                                    onClick={(e) => {
                                      VideoModalOpen(
                                        els?.video?.split("/")?.reverse()[0],
                                        els?.title,
                                        els?.publish_date,
                                        e,
                                      );
                                    }}
                                  >
                                    <div className="caption mb-0">
                                      <img
                                        loading="lazy"
                                        key={Math.random()}
                                        src={`${assetbaseUrl?.value}${els?.media}`}
                                        alt={`${els?.title}`}
                                      />
                                      {els?.video ? (
                                        <p className="playicon-subhome">
                                          <i className="fas fa-play"></i>
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                      {els?.tagline ? (
                                        <p className="button-hover colorspan-top-post">
                                          {els?.tagline}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                      <div className="categoty-main-post-title">
                                        <p className="text-white ellipsis">
                                          {els?.title}
                                        </p>
                                        <p className="text-white font-size-small blog-time">
                                          <i className="far fa-clock mr-2"></i>
                                          {els?.publish_date}
                                        </p>
                                      </div>
                                    </div>
                                  </a>
                                ) : (
                                  <Link
                                    target="_parent"
                                    to={`/${els?.url}`}
                                    className="story"
                                  >
                                    <div className="caption mb-0">
                                      <img
                                        loading="lazy"
                                        key={Math.random()}
                                        src={`${assetbaseUrl?.value}${els?.media}`}
                                        alt={`${els?.title}`}
                                      />
                                      {els?.video ? (
                                        <p className="playicon-subhome">
                                          <i className="fas fa-play"></i>
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                      {els?.tagline ? (
                                        <p className="button-hover colorspan-top-post">
                                          {els?.tagline}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                      <div className="categoty-main-post-title">
                                        <p className="text-white ellipsis">
                                          {els?.title}
                                        </p>
                                        <p className="text-white font-size-small blog-time">
                                          <i className="far fa-clock mr-2"></i>
                                          {els?.publish_date}
                                        </p>
                                      </div>
                                    </div>
                                  </Link>
                                )}
                              </>
                            )}
                          </>
                        ))}
                    </div>
                    <div className="col-xl-4 col-lg-12">
                      <div className="right-top-post">
                        {innerData?.length > 0 &&
                          innerData?.map((els, idx) => (
                            <>
                              {idx > 0 && idx < 5 ? (
                                <>
                                  {els?.post_type === 4 ? (
                                    <a
                                      className="related-blogs cursor-pointer"
                                      onClick={(e) => {
                                        VideoModalOpen(
                                          els?.video?.split("/")?.reverse()[0],
                                          els?.title,
                                          els?.publish_date,
                                          e,
                                        );
                                      }}
                                    >
                                      <p className="img-16-9-blogs eskeleton">
                                        <div
                                          className="col p-0"
                                          style={{ width: "100px" }}
                                        >
                                          <span className="skeleton">
                                            <img
                                              loading="lazy"
                                              key={Math.random()}
                                              src={`${assetbaseUrl?.value}${els?.media}`}
                                              alt=""
                                              className="img-responsive"
                                            />
                                            {els?.video ? (
                                              <p className="playicon-subhome-subcategory">
                                                <i className="fas fa-play"></i>
                                              </p>
                                            ) : (
                                              ""
                                            )}
                                          </span>
                                          <p className="blog-time mt-1 m-0">
                                            <i className="far fa-clock mr-2"></i>
                                            {els?.publish_date}
                                          </p>
                                        </div>
                                      </p>
                                      <div className="col-8 p-0">
                                        <div className="blog-details">
                                          <span>
                                            <b className="color-red">
                                              {els?.tagline}
                                            </b>
                                          </span>
                                          <p className="ellipsis">
                                            {els?.title}
                                          </p>
                                        </div>
                                      </div>
                                    </a>
                                  ) : (
                                    <Link
                                      target="_parent"
                                      to={`/${els?.url}`}
                                      className="related-blogs"
                                    >
                                      <p className="img-16-9-blogs eskeleton">
                                        <div
                                          className="col p-0"
                                          style={{ width: "100px" }}
                                        >
                                          <span className="skeleton">
                                            <img
                                              loading="lazy"
                                              key={Math.random()}
                                              src={`${assetbaseUrl?.value}${els?.media}`}
                                              alt=""
                                              className="img-responsive"
                                            />
                                            {els?.video ? (
                                              <p className="playicon-subhome-subcategory">
                                                <i className="fas fa-play"></i>
                                              </p>
                                            ) : (
                                              ""
                                            )}
                                          </span>
                                          <p className="blog-time mt-1 m-0">
                                            <i className="far fa-clock mr-2"></i>
                                            {els?.publish_date}
                                          </p>
                                        </div>
                                      </p>
                                      <div className="col-8 p-0">
                                        <div className="blog-details">
                                          <span>
                                            <b className="color-red">
                                              {els?.tagline}
                                            </b>
                                          </span>
                                          <p className="ellipsis">
                                            {els?.title}
                                          </p>
                                        </div>
                                      </div>
                                    </Link>
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                            </>
                          ))}
                      </div>
                    </div>
                  </div>
                  <div className="row substory">
                    {innerData?.length > 0 &&
                      innerData?.map((els, idx) => (
                        <>
                          {idx > 4 && idx < 8 && (
                            <>
                              {els?.post_type === 4 ? (
                                <div
                                  className="col-lg-6 col-xl-4"
                                  onClick={(e) => {
                                    VideoModalOpen(
                                      els?.video?.split("/")?.reverse()[0],
                                      els?.title,
                                      els?.publish_date,
                                      e,
                                    );
                                  }}
                                >
                                  <a className="story cursor-pointer">
                                    <div className="caption mb-0">
                                      <img
                                        loading="lazy"
                                        key={Math.random()}
                                        src={`${assetbaseUrl?.value}${els?.media}`}
                                        alt={`${els?.title}`}
                                      />
                                      {els?.video ? (
                                        <p className="playicon-subhome">
                                          <i className="fas fa-play"></i>
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div className="categoty-padding">
                                      <p className="button-hover topleft colorspan-top-trending">
                                        {els?.post_type === 1 ||
                                        els?.post_type === 2
                                          ? els?.tagline
                                          : els?.time}
                                      </p>
                                      <p className="ellipsis">{els?.title}</p>
                                      <span className="text-secondary blog-time">
                                        <i className="far fa-clock mr-2"></i>
                                        {els?.publish_date}
                                      </span>
                                    </div>
                                  </a>
                                </div>
                              ) : (
                                <div className="col-lg-6 col-xl-4">
                                  <Link
                                    target="_parent"
                                    to={`/${els?.url}`}
                                    className="story"
                                  >
                                    <div className="caption mb-0">
                                      <img
                                        loading="lazy"
                                        key={Math.random()}
                                        src={`${assetbaseUrl?.value}${els?.media}`}
                                        alt={`${els?.title}`}
                                      />
                                      {els?.video ? (
                                        <p className="playicon-subhome">
                                          <i className="fas fa-play"></i>
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div className="categoty-padding">
                                      <p className="button-hover topleft colorspan-top-trending">
                                        {els?.post_type === 1 ||
                                        els?.post_type === 2
                                          ? els?.tagline
                                          : els?.time}
                                      </p>
                                      <p className="ellipsis">{els?.title}</p>
                                      <span className="text-secondary blog-time">
                                        <i className="far fa-clock mr-2"></i>
                                        {els?.publish_date}
                                      </span>
                                    </div>
                                  </Link>
                                </div>
                              )}
                            </>
                          )}
                        </>
                      ))}
                  </div>
                </div>
              ) : (
                <div>
                  {!loading ? (
                    <>
                      {
                        // window.location.href = '/'
                      }
                      {/* <p className="message-font-size">
                                                {pagemessage}
                                            </p>
                                            <div className='text-center'>
                                                <a href='/' className="btn-close redirect-home"><i class="fas fa-arrow-left mr-2"></i> Back to Homepage</a>
                                            </div> */}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              )}

              {/* <InjectScript script={afterPrimary} /> */}
              {window.location.pathname === "/sports" ? (
                ""
              ) : (
                <InjectScript script={afterPrimaryNewVDO} />
              )}
              {/* {threepost?.[0]?.script &&  */}
              {/* <div className="ad-section"> */}
              {/* <HelperScript argSmall={195} argLarge={51} /> */}
              {/* <InjectScript script={threepost?.[0]?.script} /> */}
              {/* </div> */}
              {/* } */}

              {innerData?.length > 8 ? (
                <div className="shadow p-3 mb-3 bg-white rounded">
                  <div className="row substory">
                    {innerData?.length > 0 &&
                      innerData?.map((els, idx) => (
                        <>
                          {idx > 7 && idx < 14 && (
                            <div className="col-lg-6 col-xl-4">
                              <Link
                                target="_parent"
                                to={`/${els?.url}`}
                                className="story"
                              >
                                <div className="caption mb-0">
                                  <img
                                    loading="lazy"
                                    key={Math.random()}
                                    src={`${assetbaseUrl?.value}${els?.media}`}
                                    alt={`${els?.title}`}
                                  />
                                  {els?.video ? (
                                    <p className="playicon-subhome">
                                      <i className="fas fa-play"></i>
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>

                                <div className="categoty-padding">
                                  <p className="button-hover topleft colorspan-top-trending">
                                    {els?.post_type === 1 ||
                                    els?.post_type === 2
                                      ? els?.tagline
                                      : els?.time}
                                  </p>
                                  <p className="ellipsis">{els?.title}</p>
                                  <span className="text-secondary blog-time">
                                    <i className="far fa-clock mr-2"></i>
                                    {els?.publish_date}
                                  </span>
                                </div>
                              </Link>
                            </div>
                          )}
                        </>
                      ))}
                  </div>
                </div>
              ) : (
                ""
              )}
              {/* {sixpost?.[0]?.script && */}
              <div className="ad-section">
                {/* <HelperScript argSmall={196} argLarge={52} /> */}
                {/* <InjectScript script={sixpost?.[0]?.script} /> */}
              </div>
              {/* } */}
              {innerData?.length > 14 ? (
                <div className="shadow p-3 mb-3 bg-white rounded">
                  <div className="row substory">
                    {innerData?.length > 0 &&
                      innerData?.map((els, idx) => (
                        <>
                          {idx > 13 && idx < 17 && (
                            <div className="col-lg-6 col-xl-4">
                              <Link
                                target="_parent"
                                to={`/${els?.url}`}
                                className="story"
                              >
                                <div className="caption mb-0">
                                  <img
                                    loading="lazy"
                                    key={Math.random()}
                                    src={`${assetbaseUrl?.value}${els?.media}`}
                                    alt={`${els?.title}`}
                                  />
                                  {els?.video ? (
                                    <p className="playicon-subhome">
                                      <i className="fas fa-play"></i>
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>

                                <div className="categoty-padding">
                                  <p className="button-hover topleft colorspan-top-trending">
                                    {els?.post_type === 1 ||
                                    els?.post_type === 2
                                      ? els?.tagline
                                      : els?.time}
                                  </p>
                                  <p className="ellipsis">{els?.title}</p>
                                  <span className="text-secondary blog-time">
                                    <i className="far fa-clock mr-2"></i>
                                    {els?.publish_date}
                                  </span>
                                </div>
                              </Link>
                            </div>
                          )}
                        </>
                      ))}
                  </div>
                </div>
              ) : (
                ""
              )}
              {/* {ninepost?.[0]?.script && */}
              <div className="ad-section">
                {/* <HelperScript argSmall={197} argLarge={53} /> */}
                {/* <InjectScript script={ninepost?.[0]?.script} /> */}
              </div>
              {/* } */}

              {innerData?.length > 18 ? (
                <div className="shadow p-3 mb-3 bg-white rounded">
                  <div className="row substory">
                    {innerData?.length > 0 &&
                      innerData?.map((els, idx) => (
                        <>
                          {idx > 17 ? (
                            <div className="col-lg-6 col-xl-4">
                              <Link
                                target="_parent"
                                to={`/${els?.url}`}
                                className="story"
                              >
                                <div className="caption mb-0">
                                  <img
                                    loading="lazy"
                                    key={Math.random()}
                                    src={`${assetbaseUrl?.value}${els?.media}`}
                                    alt={`${els?.title}`}
                                  />
                                  {els?.video ? (
                                    <p className="playicon-subhome">
                                      <i className="fas fa-play"></i>
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>

                                <div className="categoty-padding">
                                  <p className="button-hover topleft colorspan-top-trending">
                                    {els?.post_type === 1 ||
                                    els?.post_type === 2
                                      ? els?.tagline
                                      : els?.time}
                                  </p>
                                  <p className="ellipsis">{els?.title}</p>
                                  <span className="text-secondary blog-time">
                                    <i className="far fa-clock mr-2"></i>
                                    {els?.publish_date}
                                  </span>
                                </div>
                              </Link>
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      ))}
                  </div>
                </div>
              ) : (
                ""
              )}
              {/* {twelvepost?.[0]?.script &&  */}
              <div className="ad-section">
                {/* <HelperScript argSmall={198} argLarge={54} /> */}
                {/* <InjectScript script={twelvepost?.[0]?.script} /> */}
              </div>
              {/* } */}

              <div>
                <nav
                  aria-label="Page navigation example"
                  style={{ display: pageCount ? "block" : "none" }}
                >
                  <div className="justify-content-center text-center">
                    <ul
                      className={`pagination myActive${pageArray?.length === activePage ? `last` : activePage} myActiveFirst${pageArray?.length === 1 ? `last` : activePage}`}
                    >
                      <li
                        onClick={() => {
                          setActivePage(1);
                        }}
                        className="firstpage page-item"
                      >
                        <a className="page-link">
                          {"<"}
                          {"<"} First
                        </a>
                      </li>
                      <li
                        onClick={() => {
                          if (activePage > 1) {
                            setActivePage(activePage - 1);
                          }
                        }}
                        className="firstpage page-item"
                      >
                        <a className="page-link">Previous</a>
                      </li>

                      {pageArray?.length > 0 &&
                        pageArray?.map(
                          (els, index) =>
                            activePage - 3 <= index &&
                            activePage + 2 > index && (
                              <li
                                onClick={() => setActivePage(els + 1)}
                                key={Math.random()}
                                className={`page-item ${els + 1 === activePage ? "active" : ""}`}
                              >
                                <a className="page-link">{els + 1}</a>
                              </li>
                            ),
                        )}

                      <li
                        onClick={() => {
                          if (pageArray?.length !== activePage) {
                            setActivePage(activePage + 1);
                          }
                        }}
                        className="lastpage page-item"
                      >
                        <a className="page-link">Next</a>
                      </li>
                      <li
                        onClick={() => setActivePage(pageArray?.length)}
                        className="lastpage page-item"
                      >
                        <a className="page-link">
                          Last {">"}
                          {">"}
                        </a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              {window.innerWidth > 991 ? (
                <HomeTaboola key={window.location.href} />
              ) : (
                ""
              )}
            </div>

            {/* <div className="insta-head-responsive col-12 col-sm-12 col-md-5 col-lg-4 col-xl-3"> */}

            <div className="col-md-5 col-lg-3 offset-lg-0 col-xl-3 offset-xl-0 p-0 iframe-election page">
              {window.innerWidth > 768 ? (
                <iframe
                  loading="lazy"
                  src="https://sandesh.pages.dev/"
                  style={{ height: "700px", border: "none", zIndex: 99 }}
                  title="Election Table"
                />
              ) : (
                ""
              )}

              <div className="d-flex justify-content-center">
                <div className="ad-side-head">
                  {/* {secondright?.[0]?.script &&  */}
                  <div className="ad-section mb-3">
                    {/* <HelperScript argSmall={202} argLarge={58} /> */}
                    {window.innerWidth < 991 ? (
                      <InjectScript script={gallery202} />
                    ) : (
                      <InjectScript script={gallery58} />
                    )}
                    {/* <InjectScript script={secondright?.[0]?.script} /> */}
                  </div>
                  {/* } */}
                </div>
              </div>
              {/* </div> */}

              <div className="text-center">
                <div className="ad-side-head ">
                  <div className="ad-side m-0">
                    {/* <p>જાહેરાત</p> */}
                    {/* {thirdright?.[0]?.script &&  */}
                    <div className="ad-section">
                      {/* <HelperScript argSmall={203} argLarge={59} /> */}
                      {/* <InjectScript script={thirdright?.[0]?.script} /> */}
                    </div>
                    {/* } */}

                    {/* <img loading="lazy" key={Math.random()} src="https://i.imgur.com/uakoBY9.png" alt="" /> */}
                  </div>
                  <div className="ad-side">
                    {/* <p>જાહેરાત</p> */}
                    {/* <img loading="lazy" key={Math.random()} src={youmaylike} alt="" /> */}
                    {/* {fourthright?.[0]?.script && */}
                    <div className="ad-section">
                      {/* <HelperScript argSmall={205} argLarge={100} /> */}
                      {/* <InjectScript script={fourthright?.[0]?.script} /> */}
                    </div>
                    {/* } */}

                    {/* <a className="twitter-timeline" href="https://twitter.com/sandeshnews?ref_src=twsrc%5Etfw">Tweets by sandeshnews</a>
                                        <Helmet>
                                            <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
                                        </Helmet> */}
                  </div>
                </div>
              </div>

              <div className="my-2 sidebar-taboola" key={window.location.href}>
                {/* Sidbar Taboola with script */}
                <div id="taboola-right-rail-thumbnails"></div>
                <InjectScript
                  type="taboola"
                  script={taboolacontent}
                  key={window.location.href}
                />
                {/* Categoty Sidbar Taboola with NPM
                                <TaboolaComponent
                                    mode="thumbnails-rr"
                                    containerId="taboola-right-rail-thumbnails"
                                    placement="Right Rail Thumbnails'"
                                    targetType="mix"
                                    pageType="category"
                                    publisherId="sandeshdigital-sandesh"
                                /> */}
                <div className="ad-sticky-top">
                  {/* <HelperScript argLarge={261} /> */}
                </div>
              </div>
            </div>
          </div>

          {window.innerWidth < 991 ? (
            <HomeTaboola key={window.location.href} />
          ) : (
            ""
          )}

          {/* <AdTwo /> */}

          {/* <div className='mt-4 mb-4'>
                        <AdTwo />
                    </div> */}
        </div>
      </section>
      {/* {CategoryFooter?.[0]?.script && */}
      <div className="ad-section">
        {/* <HelperScript argSmall={194} argLarge={47} /> */}
        {/* <InjectScript script={CategoryFooter?.[0]?.script} /> */}
      </div>
      {/* } */}
      <div id="videoPoPup" className="videoSidebar">
        <div className="closebtn" onClick={() => VideoModalClose()}>
          <i className="fas fa-times"></i>
        </div>
        <Dailymotion
          className="dailymotion-videoPoPup-page"
          video={`${vidId}`}
          showEndScreen={true}
          uiTheme="light"
          autoplay
          uiShowLogo="false"
          origin=""
        />
        {/* <YouTube className="dailymotion-videoPoPup-page" videoId="CVxjMPK8sFU" opts={optsModal} /> */}

        {/* <div className="videoPoPup-details">
                        <p>{vidTitle}</p>
                        <span><i className="far fa-clock"></i>&nbsp;{vidTime}</span>
                    </div> */}
      </div>
    </>
  );
};

export default AssemblyElection;
