import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import { useDispatch, useSelector } from "react-redux";
import { sharecount } from "../../redux/actions/commonAction";

const ShareWith = (props) => {
  // Extract necessary data from Redux store
  const { 21: assetbaseUrl, 26: sharemsg } = useSelector((state) => state?.SettingReducer?.data);
  const { 24: Android } = useSelector((state) => state?.SettingReducer?.data);
  const { 25: Apple } = useSelector((state) => state?.SettingReducer?.data);

  // Construct URL paths and messages for sharing
  const urlshare = "https://" + window.location.hostname + "/" + props.url + "\r\n \r\n";
  const media = assetbaseUrl?.value + props.media + "?resize=1200,630";
  const title = props.title + "\r\n";
  const ShareValue = sharemsg?.value + "\r\n \r\n";

  useEffect(() => {
    // Update local storage with share count
    if (props?.url) {
      const localCount = localStorage.getItem("SandeshLike")
        ? JSON.parse(localStorage.getItem("SandeshLike"))
        : {};
      const myCategory = props?.url.split("/")[0];
      localCount[myCategory] = (localCount[myCategory] || 0) + 1;
      if (localCount[myCategory] > 9999) {
        localCount[myCategory] = 1000;
      }
      localStorage.setItem("SandeshLike", JSON.stringify(localCount));
    }
  }, [props?.url]);

  const dispatch = useDispatch();

  // Function to handle share events and dispatch share count
  const returnfalse = async (name) => {
    await dispatch(sharecount(name, props?.dataid));
  };

  // Function to handle copy event
  const copy = (e) => {
    // Copy URL to clipboard
    returnfalse("copy");
    var textArea = document.createElement("textarea");
    document.body.className += " copied";

    textArea.style.position = "fixed";
    textArea.style.top = 0;
    textArea.style.left = 0;
    textArea.style.width = "2em";
    textArea.style.height = "2em";
    textArea.style.padding = 0;
    textArea.style.border = "none";
    textArea.style.outline = "none";
    textArea.style.boxShadow = "none";
    textArea.style.background = "transparent";

    textArea.value = window.location.href;

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand("copy");

      // Display confirmation message
      const node = document.createElement("span");
      const textnode = document.createTextNode("Link copied.");
      node.className = "copyMsg";
      node.setAttribute("id", "copyMsg");
      node.appendChild(textnode);
      document.getElementById("root").appendChild(node);
      setTimeout(() => {
        document.getElementById("copyMsg").outerHTML = "";
      }, 1000);
    } catch (err) {}

    document.body.removeChild(textArea);
    document.querySelector("body").classList.remove("copied");
    e.stopPropagation();
    return false;
  };

  // Function to handle email share
  const returnStop4 = (e) => {
    document.getElementById(props.id).classList.add("d-none");
    ReactGA.event({ category: "Share", action: "Email", label: urlshare });
    e.stopPropagation();
    return false;
  };

  // JSX for share buttons
  return (
    <>
      {/* WhatsApp share button */}
      <p
        className="social-icon"
        onClickCapture={() =>
          ReactGA.event({
            category: "Share",
            action: "Whatsapp",
            label: urlshare,
          })
        }
      >
        <a
          onClick={() => returnfalse("wa")}
          aria-label="Whatsapp"
          href={`${"https://api.whatsapp.com/send?text=" + encodeURIComponent(title) + encodeURIComponent(urlshare) + encodeURIComponent(ShareValue)}`}
          target="_blank"
          rel="noreferrer"
        >
          <i className="fab fa-whatsapp menu-page-social-icon-whatsapp"></i>
        </a>
      </p>
      {/* Facebook share button */}
      <p
        className="social-icon"
        onClickCapture={() =>
          ReactGA.event({
            category: "Share",
            action: "Facebook",
            label: urlshare,
          })
        }
      >
        <a
          onClick={() => returnfalse("fb")}
          aria-label="Facebook"
          href={`${"https://facebook.com/sharer/sharer.php?display=page&picture=" + encodeURIComponent(media) + "&quote=" + encodeURIComponent(title + urlshare) + "&u=" + encodeURIComponent(urlshare)}`}
          target="_blank"
          rel="noreferrer"
        >
          <i className="fab fa-facebook menu-page-social-icon-facebook"></i>
        </a>
      </p>
      {/* Twitter share button */}
      <p
        className="social-icon"
        onClickCapture={() =>
          ReactGA.event({
            category: "Share",
            action: "Twitter",
            label: urlshare,
          })
        }
      >
        <a
          onClick={() => returnfalse("tw")}
          aria-label="Twitter"
          href={`${"https://twitter.com/share?text=" + encodeURIComponent(title) + "&url=" + encodeURIComponent(urlshare) + encodeURIComponent(ShareValue)}`}
          target="_blank"
          rel="noreferrer"
        >
          <i className="fa-size-footer fab fa-twitter"></i>
        </a>
      </p>
      {/* Email share button */}
      <p
        className="social-icon cursor-pointer"
        onClickCapture={() =>
          ReactGA.event({ category: "Share", action: "Email", label: urlshare })
        }
      >
        <a
          onClick={returnStop4}
          target="_blank"
          href={`mailto:?subject=${title}&body=${title}%0D%0A%0D%0A${urlshare}%0D%0A%0D%0A${ShareValue}`} rel="noreferrer"
        >
          <i className="fa fa-envelope"></i>
        </a>
      </p>
    </>
  );
};

export default ShareWith;
