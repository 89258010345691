import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
// import { ePaperMenuFetchData } from '../../redux/actions/ePaperMenuAction';
import { ePaperMenuFetchData } from "../../../redux/actions/ePaperMenu";
import sandeshlogo from "../../../assets/logoog.jpg";
import ReactGA from "react-ga4";
import Login from "../Login";

const EpaperMenu = () => {
  const dispatch = useDispatch();
  // const menuList = useSelector((state) => state?.MenuReducer);
  const [loginStateEnable, setLoginEnableState] = useState(false);
  const ePaperMenuData = useSelector(
    (state) => state?.ePaperMenuReducer?.data?.data,
  );
  const tithi = useSelector((state) => state?.TithiReducer);
  let navigate = useNavigate();
  const { 24: Android } = useSelector((state) => state?.SettingReducer?.data);
  const { 25: Apple } = useSelector((state) => state?.SettingReducer?.data);

  const [app_redirection, setapp_redirection] = React.useState(
    "/app-redirection.html",
  );
  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (userAgent.includes("Android")) {
      if (Android?.value) {
        setapp_redirection(Android?.value);
      }
    } else if (userAgent.includes("iPhone")) {
      if (Apple?.value) {
        setapp_redirection(Apple?.value);
      }
    }
  }, [Android]);

  // const [menu, setMenuItems] = React.useState('');
  // useEffect(() => {
  //     setMenuItems(menuList.data)
  // }, [menuList]);

  useEffect(() => {
    dispatch(ePaperMenuFetchData());
  }, []);

  const Logout = () => {
    localStorage.removeItem("userInfo");
    localStorage.removeItem("access_token");
    localStorage.removeItem("userID");
    localStorage.removeItem("socialLogin");
    navigate("/");
  };

  const OpenAPP = () => {
    // window.location = "Sandesh://launch";
    window.location = app_redirection;
    return false;
  };

  document.addEventListener("DOMContentLoaded", function () {
    window.addEventListener("scroll", function () {
      if (document.getElementById("sticky_menu_logo_epaper")) {
        if (window.scrollY > 450) {
          document
            .getElementById("sticky_menu_logo_epaper")
            .classList.add("dis-block");
        } else {
          document
            .getElementById("sticky_menu_logo_epaper")
            .classList.remove("dis-block");
        }
      }
    });
  });

  const showLoginModel = () => {
    setLoginEnableState(!loginStateEnable);
  };

  const checklocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(successFunction, errorFunction);
    }
  };
  function successFunction(position) {
    const lat = position.coords.latitude;
    const long = position.coords.longitude;
    localStorage.setItem(
      "SandeshLocation",
      JSON.stringify({ lat: lat, long: long }),
    );
    document.getElementById("locationCheck").style.display = "none";
  }

  function errorFunction(err) {
    // console.log('Location Error:', err.message);
    // alert('Location Error: ' + err.message);
  }

  return (
    <>
      <section className="menu">
        <div className="container col-md-12 p-0 epaperMenuspace">
          <nav className="navbar menu navbar-expand-lg navbar-light bg-transperent">
            <div className="left-menu-mobile">
              <button
                className="navbar-toggler collapsed remove-border"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
              <Link
                className="liveTVMenu"
                onClickCapture={() =>
                  ReactGA.event({
                    category: "Header",
                    action: "E-Paper Menu",
                    label: "Videos",
                  })
                }
                to="/videos"
              >
                <i className="fa fa-tv live-tv-icon"></i>
              </Link>
            </div>

            <a
              className="navbar-brand"
              href="/"
              onClickCapture={() =>
                ReactGA.event({
                  category: "Header",
                  action: "E-Paper Menu",
                  label: "Logo",
                })
              }
            >
              <img
                loading="lazy"
                className="sandesh-nav-logo"
                width={100}
                src="https://assets.sandesh.com/im/logo-b.png"
                alt=""
              />
              <span
                className="innerLogoText"
                dangerouslySetInnerHTML={{ __html: tithi?.data?.text }}
              />
            </a>

            <div className="right-menu-mobile">
              {/* {
                localStorage.getItem('access_token') ? '' : (
                  <li className='v-hidden'><i onClick={() => { Logout() }} style={{ fontSize: '18px', padding: '0 11px', cursor: 'pointer' }} className="fas fa-power-off icon-menu-logout"></i></li>
                )
              }
              {
                localStorage.getItem('access_token') ? '' : (
                  <li className='v-hidden'><i onClick={() => { Logout() }} style={{ fontSize: '18px', padding: '0 11px', cursor: 'pointer' }} className="fas fa-power-off icon-menu-logout"></i></li>
                )
              } */}
              {/* <a href={app_redirection} onClick={() => { OpenAPP(app_redirection) }} target="_blank" className='open-app-title'>Open App</a> */}
              {/* <a href="#" onClick={() => { OpenAPP() }} className='open-app-title'>Open App</a> */}
              {localStorage.getItem("access_token") ? (
                <li>
                  <Link
                    target="_parent"
                    to="/profile"
                    title="profile"
                    className="signin cursor-pointer icon-menu"
                  >
                    <i
                      style={{
                        fontSize: "18px",
                        padding: "0 5px",
                        cursor: "pointer",
                      }}
                      className=" fas fa-user"
                    ></i>
                  </Link>
                </li>
              ) : (
                <li>
                  <a
                    onClick={showLoginModel}
                    className="signin cursor-pointer icon-menu"
                  >
                    <i
                      style={{
                        fontSize: "18px",
                        padding: "0 5px",
                        cursor: "pointer",
                      }}
                      className=" fas fa-user"
                    ></i>
                  </a>
                </li>
              )}
              {localStorage.getItem("access_token") ? (
                <li className="icon-menu">
                  <i
                    onClick={() => {
                      Logout();
                    }}
                    style={{
                      fontSize: "18px",
                      padding: "0 5px",
                      cursor: "pointer",
                    }}
                    className=" fas fa-power-off icon-menu-logout"
                  ></i>
                </li>
              ) : (
                ""
              )}
              <li id="locationCheck">
                <a
                  href="javascript:void(0)"
                  onClick={checklocation}
                  className="location icon-menu"
                >
                  <i
                    style={{
                      fontSize: "18px",
                      padding: "0 5px",
                      cursor: "pointer",
                    }}
                    className="fas  fa-map-marker-alt"
                  ></i>
                </a>
              </li>

              {/* <a href={app_redirection} onClick={() => { OpenAPP(app_redirection) }} target="_blank" className='open-app-title'>Open App</a> */}
              {/* <a href="#" onClick={() => { OpenAPP() }} className='open-app-title'>Open App</a> */}
              {/* {
                localStorage.getItem('access_token') ? (
                  <li><i onClick={() => { Logout() }} style={{ fontSize: '18px', padding: '0 11px', cursor: 'pointer' }} className="fas fa-power-off icon-menu-logout"></i></li>
                ) : ''
              } */}
            </div>
            {/* <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            > */}

            <div
              className="collapse navbar-collapse"
              data-toggle="collapse"
              data-target="#navbarSupportedContent.show"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav mr-auto">
                <li className="nav-item" id="sticky_menu_logo_epaper">
                  <a
                    href="/"
                    target="_parent"
                    className="p-0"
                    onClickCapture={() =>
                      ReactGA.event({
                        category: "Header",
                        action: "E-Paper Menu",
                        label: "Logo",
                      })
                    }
                  >
                    <img
                      loading="lazy"
                      alt=""
                      src={sandeshlogo}
                      className="sticky-header-logo d-none"
                    />
                  </a>
                </li>
                <li className="nav-item">
                  <Link
                    key={Math.random()}
                    target="_parent"
                    to="/epaper"
                    onClickCapture={() =>
                      ReactGA.event({
                        category: "Header",
                        action: "E-Paper Menu",
                        label: "Home",
                      })
                    }
                  >
                    {/* <i className="fas fa-home mr-1"></i> */}
                    Home
                  </Link>
                </li>
                {ePaperMenuData &&
                  Object.values(ePaperMenuData).map((els, idx) => (
                    <>
                      <li
                        className="nav-item dropdown"
                        key={idx}
                        onClickCapture={() =>
                          ReactGA.event({
                            category: "Header",
                            action: "E-Paper Menu",
                            label: els?.name,
                          })
                        }
                      >
                        {els?.open === 1 ? (
                          <>
                            <a
                              key={Math.random()}
                              className="dropdown"
                              target="_blank"
                              href={`${els?.url}`}
                              rel="noreferrer"
                            >
                              {els?.name === "Play Games" ? "" : els?.name}{" "}
                              {`${els?.submenu}` ? (
                                <i className="fas fa-sort-down"></i>
                              ) : null}
                            </a>
                          </>
                        ) : (
                          <>
                            <a
                              className="nav-link dropdown-toggle  d-flex justify-center align-items-start"
                              href="#"
                              id="navbarDropdown"
                              role="button"
                              data-toggle="dropdown"
                              aria-expanded="false"
                            >
                              {/* <a href="/"> */}
                              {els?.name}{" "}
                              {els?.submenu &&
                              Object.values(els?.submenu)?.length ? (
                                <i
                                  className="fas fa-sort-down"
                                  style={{ marginLeft: "5px" }}
                                ></i>
                              ) : (
                                ""
                              )}
                              {/* </a> */}
                            </a>
                            <>
                              <ul
                                className="dropdown-menu p-0"
                                aria-labelledby="navbarDropdown"
                              >
                                {els?.submenu &&
                                  Object.values(els?.submenu).map((el, idx) => (
                                    <li
                                      onClickCapture={() =>
                                        ReactGA.event({
                                          category: "Header",
                                          action: "E-Paper Menu",
                                          label: el?.name,
                                        })
                                      }
                                    >
                                      {/* <a className="dropdown-item" href="#"> */}
                                      <Link
                                        target="_parent"
                                        className="dropdown-item left-side"
                                        to={`/epaper/${el?.category}`}
                                      >
                                        {el?.name}{" "}
                                      </Link>
                                      {/* </a> */}
                                    </li>
                                  ))}
                              </ul>
                            </>
                          </>
                        )}
                      </li>
                    </>
                  ))}
              </ul>
            </div>
          </nav>
        </div>
      </section>
      {loginStateEnable && <Login />}
    </>
  );
};

export default EpaperMenu;
