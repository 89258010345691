// Import action types related to login data
import {
  LOGING_ARE_LOADING,
  LOGING_FETCH_DATA_SUCCESS,
  LOGING_HAVE_ERROR,
} from "../actionType";

// Define the initial state for the Login reducer
const initialState = {
  data: [],
  loading: true,
  error: [],
};

// Reducer function to handle login-related actions and update state accordingly
export function LogingReducer(state = initialState, action) {
  switch (action.type) {
    // Handle error case when fetching login data
    case LOGING_HAVE_ERROR:
      return {
        ...state,
        error: action.hasError, // Update the error state with the error from the action
        loading: false, // Set loading to false since the request is complete
      };

    // Handle loading state when login data is being fetched
    case LOGING_ARE_LOADING:
      return {
        ...state,
        loading: action.isLoading, // Update the loading state with the loading status from the action
      };

    // Handle success case when login data is successfully fetched
    case LOGING_FETCH_DATA_SUCCESS:
      return {
        ...state,
        data: action.Login, // Update the data state with the fetched login data
        loading: false, // Set loading to false since the request is complete
        error: [], // Clear any previous errors (assuming the success case means no error)
      };

    // Return the current state by default if action type is not recognized
    default:
      return state;
  }
}
