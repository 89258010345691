// Import action types related to child menu data
import {
  CHILDMENU_ARE_LOADING,
  CHILDMENU_FETCH_DATA_SUCCESS,
  CHILDMENU_HAVE_ERROR,
} from "../actionType";

// Define the initial state for the ChildmenuReducer
const initialState = {
  data: [],
  loading: true,
  error: [], // Initialize error as null for better error handling
};

// Reducer function to handle child menu-related actions and update state accordingly
export function ChildmenuReducer(state = initialState, action) {
  switch (action.type) {
    // Handle error case when fetching child menu data
    case CHILDMENU_HAVE_ERROR:
      return {
        ...state,
        error: action.hasError, // Update the error state with the error from the action
        loading: false, // Set loading to false since the request is complete
      };

    // Handle loading state when child menu data is being fetched
    case CHILDMENU_ARE_LOADING:
      return {
        ...state,
        loading: action.isLoading, // Update the loading state with the loading status from the action
      };

    // Handle success case when child menu data is successfully fetched
    case CHILDMENU_FETCH_DATA_SUCCESS:
      return {
        ...state,
        data: action.childmenu, // Update the data state with the fetched child menu data
        loading: false, // Set loading to false since the request is complete
        error: null, // Clear any previous errors
      };

    // Return the current state by default if action type is not recognized
    default:
      return state;
  }
}
