import {
  HOMEPRIMARY_FETCH_DATA_SUCCESS,
  HOMEPRIMARY_HAVE_ERROR,
  HOMEPRIMARY_ARE_LOADING,
} from "../actionType";
import HttpService from "../../utils/httpService";
import { Get } from "../../services/api.service";

// Action creator function to handle different types of home primary actions
export function homePrimaryAction(action, payload) {
  switch (action) {
    case HOMEPRIMARY_HAVE_ERROR:
      return {
        type: action,
        hasError: payload,
      };

    case HOMEPRIMARY_ARE_LOADING:
      return {
        type: action,
        isLoading: payload,
      };

    case HOMEPRIMARY_FETCH_DATA_SUCCESS:
      return {
        type: action,
        homePrimary: payload,
      };

    default:
      return;
  }
}

// Async action creator function to fetch home primary data
export function homePrimaryFetchData() {
  return (dispatch) => {
    try {
      // Dispatch action indicating data is loading
      dispatch(homePrimaryAction(HOMEPRIMARY_ARE_LOADING, true));

      // Check if home primary data is available in local storage
      if (localStorage.getItem("SandeshHome")) {
        // If available, dispatch action with data from local storage
        dispatch(
          homePrimaryAction(
            HOMEPRIMARY_FETCH_DATA_SUCCESS,
            JSON.parse(localStorage.getItem("SandeshHome")),
          ),
        );
      }

      // Fetch home primary data using GET request
      return new Promise((resolve, reject) => {
        Get(HttpService.homePrimary).then(
          (result) => {
            // Store fetched data in local storage
            localStorage.setItem("SandeshHome", JSON.stringify(result.data));
            // Dispatch action with fetched home primary data
            dispatch(homePrimaryAction(HOMEPRIMARY_FETCH_DATA_SUCCESS, result.data));
            resolve(result);
          },
          (error) => {
            reject(error); // Reject promise with error if fetching fails
          },
        );
      });
    } catch (error) {
      // Dispatch action if there's an error in fetching data
      dispatch(homePrimaryAction(HOMEPRIMARY_HAVE_ERROR, true));
    }
  };
}
