import React from "react";
import Header from "../subcomponents/Header";

const Topbar = (props) => {
  return (
    <>
      <Header onDarkMode={props.onDarkMode} mode={props.mode} />
    </>
  );
};

export default Topbar;
