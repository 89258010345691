import {
  EPAPER_CATEGORY_FETCH_DATA_SUCCESS,
  EPAPER_CATEGORY_HAVE_ERROR,
  EPAPER_CATEGORY_ARE_LOADING,
} from "../actionType";
import HttpService from "../../utils/httpService";
import { Get } from "../../services/api.service";

// Action creator function to handle different types of ePaper category actions
export function ePaperCategoryAction(action, payload) {
  switch (action) {
    case EPAPER_CATEGORY_HAVE_ERROR:
      return {
        type: action,
        hasError: payload,
      };

    case EPAPER_CATEGORY_ARE_LOADING:
      return {
        type: action,
        isLoading: payload,
      };

    case EPAPER_CATEGORY_FETCH_DATA_SUCCESS:
      return {
        type: action,
        epaperCategory: payload,
      };

    default:
      return; // Return nothing if action type is unknown
  }
}

// Async action creator function to fetch ePaper category data
export function epaperCategoryFetchData(ePaperDetails) {
  return (dispatch) => {
    try {
      // Dispatch action indicating data is loading
      dispatch(ePaperCategoryAction(EPAPER_CATEGORY_ARE_LOADING, true));

      // Fetch ePaper category data using GET request
      return new Promise((resolve, reject) => {
        Get(HttpService.epaperCategory + ePaperDetails).then(
          (result) => {
            // Dispatch action with fetched ePaper category data
            dispatch(
              ePaperCategoryAction(EPAPER_CATEGORY_FETCH_DATA_SUCCESS, result),
            );
            resolve(result);
          },
          (error) => {
            reject(error); // Reject promise with error if fetching fails
          },
        );
      });
    } catch (error) {
      // Dispatch action if there's an error in fetching data
      dispatch(ePaperCategoryAction(EPAPER_CATEGORY_HAVE_ERROR, true));
    }
  };
}
