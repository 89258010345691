import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const BreadCrumb = () => {
  // Get data from Redux store
  const innerData = useSelector((state) => state?.PostReducer?.data);

  // Function to manage breadcrumb logic
  const breadcrumbManage = (params) => {
    const urlPath = window.location.href;
    const breadCrumb = urlPath?.split("/");
    if (breadCrumb?.[3] === "page" || breadCrumb?.[3] === "tag") {
      // If URL path contains "page" or "tag", return false
      return false;
    } else {
      // Otherwise, return breadcrumb at specified index
      return breadCrumb?.[params] ? breadCrumb?.[params].replace("-", "-") : "";
    }
  };

  return (
    <div className={`container article-${innerData?.id}`}>
      {/* Breadcrumb navigation */}
      <nav aria-label="breadcrumb" className="breadcrumbNav">
        <ol className="breadcrumb breadcrumb-bg m-0">
          {/* Home breadcrumb */}
          <li className="breadcrumb-item text-capitalize">
            <Link target="_parent" to={`/`} className="text-secondary">
              Home
            </Link>
          </li>
          {/* Dynamic breadcrumb for category */}
          {breadcrumbManage(3) && (
            <li className="breadcrumb-item text-capitalize">
              <Link
                target="_parent"
                className="text-secondary"
                to={`/${breadcrumbManage(3)}`}
              >
                {breadcrumbManage(3)}
              </Link>
            </li>
          )}
          {/* Breadcrumb for inner page */}
          {breadcrumbManage(4) && (
            <li
              className="breadcrumb-item text-capitalize active"
              aria-current="page"
            >
              {/* Title of inner page */}
              <a
                className="breadcrumb-item active"
                href="javascript:void(0)"
              >
                {innerData?.title}
              </a>
            </li>
          )}
        </ol>
      </nav>
    </div>
  );
};

export default BreadCrumb;
