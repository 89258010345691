// Import action types related to posts
import {
  POST_FETCH_DATA_SUCCESS,
  POST_HAVE_ERROR,
  POST_ARE_LOADING,
} from "../actionType";

// Define the initial state for the Post reducer
const initialState = {
  data: [], // Initialize data as an empty array
  loading: true, // Set loading to true initially
  error: [], // Initialize error as an empty array
};

// Reducer function to handle post-related actions and update state accordingly
export function PostReducer(state = initialState, action) {
  switch (action.type) {
    // Handle error case when fetching posts
    case POST_HAVE_ERROR:
      return {
        ...state,
        error: action.hasError, // Update the error state with the error from the action
        loading: false, // Set loading to false since the request is complete
      };

    // Handle loading state when posts are being fetched
    case POST_ARE_LOADING:
      return {
        ...state,
        loading: action.isLoading, // Update the loading state with the loading status from the action
        data: action.Post, // Update the data state with the post data from the action
      };

    // Handle success case when posts are successfully fetched
    case POST_FETCH_DATA_SUCCESS:
      return {
        ...state,
        data: action.Post, // Update the data state with the fetched posts
        loading: false, // Set loading to false since the request is complete
        error: [], // Clear any previous errors (assuming the success case means no error)
      };

    // Return the current state by default if action type is not recognized
    default:
      return state;
  }
}
