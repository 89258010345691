import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import CategoryTaboola from '../subcomponents/CategoryTaboola';
import HomeTaboola from "../subcomponents/HomeTaboola";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { CategoryFetchData } from "../../redux/actions/categoryAction";
import { homePrimaryFetchData } from "../../redux/actions/homePrimaryAction";
import { Helmet } from "react-helmet";
import InjectScript from "../CustomScript";
import "../../styles/custom.css";
import HelperScript from "../../utils/helper";
import ReactGA from "react-ga4";
import CommonContent from "../CommonContent";
// import YouTube from 'react-youtube';

const totalNum_recored = 24; // 24

const GujaratMetro = (props) => {
  const urlParam = new URLSearchParams(window.location.search);
  const myParamPage = parseInt(urlParam.get("page"));

  const [activePage, setActivePage] = React.useState(
    myParamPage ? parseInt(myParamPage) : 1,
  );

  const [ActiveTab, setActiveTab] = useState("metro");
  const [pageArray, setPageArray] = React.useState([]);
  const homePrimary = useSelector(
    (state) => state?.HomePrimaryReducer.data?.Trending,
  );
  const innerData = useSelector((state) => state?.CategoryReducer?.data?.posts);
  const pageCount = useSelector((state) => state?.CategoryReducer?.data?.count);
  const pageName = useSelector((state) => state?.CategoryReducer?.data?.name);
  const loading = useSelector((state) => state?.CategoryReducer?.loading);
  const { 21: assetbaseUrl } = useSelector(
    (state) => state?.SettingReducer?.data,
  );
  // console.log("ppppageCount", pageCount);
  // console.log("ppppageName", pageName);
  // console.log("ppploading", loading);
  // console.log("pppinnerData", innerData);
  // console.log("ppphomePrimary", homePrimary);

  let dispatch = useDispatch();
  const config = {
    city: ActiveTab,
    country: "india",
    state: "gujarat",
    page: activePage,
    limit: totalNum_recored,
  };
  useEffect(() => {
    const urlParam = new URLSearchParams(window.location.search);
    const myParamPage = parseInt(urlParam.get("page"));
    setActivePage(myParamPage ? parseInt(myParamPage) : 1);
    setActivePage(1);
    dispatch(CategoryFetchData(props.type, config));
  }, [ActiveTab]);

  useEffect(() => {
    dispatch(homePrimaryFetchData());
    if (pageCount) {
      const checkPageSize = Math.ceil(pageCount / totalNum_recored);
      const array = Array.from(Array(checkPageSize).keys());
      setPageArray(array);
    }
    ReactGA.event({
      category: "GujaratMetro Page",
      action: "GujaratMetro",
      label: innerData?.name,
    });
  }, [innerData, pageCount]);

  let navigate = useNavigate();
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = parseInt(urlParams.get("page")) || 1;
    if (myParam !== parseInt(activePage)) {
      if (parseInt(activePage) === 1) {
        navigate(window.location.pathname);
      } else {
        navigate(window.location.pathname + "?page=" + activePage);
      }
    }
    window.scrollTo(0, 0);
    dispatch(CategoryFetchData(props.type, config));
    ReactGA.event({
      category: "GujaratMetro Page",
      action: "GujaratMetro",
      label: innerData?.name,
    });
  }, [activePage]);

  const taboolacontent =
    "" +
    "" +
    '<div id="taboola-right-rail-thumbnails"></div>' +
    "" +
    '<script type="text/javascript">' +
    "window._taboola = window._taboola || [];" +
    "_taboola.push({" +
    "mode: 'thumbnails-rr'," +
    "container: 'taboola-right-rail-thumbnails'," +
    "placement: 'Right Rail Thumbnails'," +
    "target_type: 'mix'" +
    "});" +
    "</script>" +
    "" +
    "";

  const Titlecapitalize = (str, lower = false) =>
    (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, (match) =>
      match.toUpperCase(),
    );
  // const afterPrimary  = "" +
  //     '<div id = "v-sandesh-v0"></div>'+
  //     ''+
  //     '<script>'+
  //     '(function(v,d,o,ai){'+
  //     "ai=d.createElement('script');"+
  //     'ai.defer=true;'+
  //     'ai.async=true;'+
  //     'ai.src=v.location.protocol+o;'+
  //     'd.head.appendChild(ai);'+
  //     '})'+
  //     "(window, document, '//a.vdo.ai/core/v-sandesh-v0/vdo.ai.js');"+
  //     "</script>"+
  //     ''
  const afterPrimary =
    "" +
    // '<script defer id="vidcrunchJS632259138" src="https://cdn.vidcrunch.com/integrations/5f4fa0fc4f13d250c8dd1b3a/Sandesh.com_Both_ICF_Responsive_Playlist_190922.js">'+
    // '</script>'+
    "";
  const IBVcategory =
    "" +
    "<div id='div-gpt-ad-1669617435638-0' style='min-width: 300px; min-height: 250px;'>" +
    "<script>" +
    "window.googletag = window.googletag || {cmd: []};" +
    "googletag.cmd.push(function() {" +
    "googletag.defineSlot('/22678428348/SDPL_CP_Desktop_ATF_300X250', [300, 250], 'div-gpt-ad-1669617435638-0').addService(googletag.pubads());" +
    "googletag.pubads().enableSingleRequest();" +
    "googletag.enableServices();" +
    "});" +
    "</script>" +
    "<script>" +
    "googletag.cmd.push(function() { googletag.display('div-gpt-ad-1669617435638-0'); });" +
    "</script>" +
    "</div>" +
    "";

  return (
    <>
      <Helmet>
        <title>GujaratMetro | Sandesh</title>
      </Helmet>
      {pageName?.length && (
        <Helmet>
          <title>{Titlecapitalize(pageName)} | Sandesh</title>
        </Helmet>
      )}
      <div className="ad-section">
        <HelperScript argSmall={190} argLarge={43} />
      </div>

      <section
        className="gujarat-metro gujarat mb-3 isCategoryPage"
        style={{ opacity: loading ? "0.5" : 1 }}
      >
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-7 col-lg-8 col-xl-9 gujarat-responsive-space-sm">
              <div className="metro-tabpane mb-3">
                <div className="single-blog mt-2">
                  {/* <span onClick={() => setActiveTab('metro')} className={`metro-tab-bubble ${ActiveTab === "metro" ? 'active' : ''}`}>Metro</span>
                                    <span onClick={() => setActiveTab('ahmedabad')} className={`metro-tab-bubble ${ActiveTab === "ahmedabad" ? 'active' : ''}`}>Ahmedabad</span>
                                    <span onClick={() => setActiveTab('surat')} className={`metro-tab-bubble ${ActiveTab === "surat" ? 'active' : ''}`}>Surat</span>
                                    <span onClick={() => setActiveTab('rajkot')} className={`metro-tab-bubble ${ActiveTab === "rajkot" ? 'active' : ''}`}>Rajkot</span>
                                    <span onClick={() => setActiveTab('vadodara')} className={`metro-tab-bubble ${ActiveTab === "vadodara" ? 'active' : ''}`}>Vadodara</span>
                                    <span onClick={() => setActiveTab('bhavnagar')} className={`metro-tab-bubble ${ActiveTab === "bhavnagar" ? 'active' : ''}`}>Bhavnagar</span>
                                    <span onClick={() => setActiveTab('bhuj')} className={`metro-tab-bubble ${ActiveTab === "bhuj" ? 'active' : ''}`}>Bhuj</span> */}

                  <span
                    onClick={() => setActiveTab("metro")}
                    className={`metro-inner  ${ActiveTab === "metro" ? "active" : ""}`}
                  >
                    Metro
                  </span>
                  <span
                    onClick={() => setActiveTab("ahmedabad")}
                    className={`metro-inner  ${ActiveTab === "ahmedabad" ? "active" : ""}`}
                  >
                    Ahmedabad
                  </span>
                  <span
                    onClick={() => setActiveTab("surat")}
                    className={`metro-inner  ${ActiveTab === "surat" ? "active" : ""}`}
                  >
                    Surat
                  </span>
                  <span
                    onClick={() => setActiveTab("rajkot")}
                    className={`metro-inner  ${ActiveTab === "rajkot" ? "active" : ""}`}
                  >
                    Rajkot
                  </span>
                  <span
                    onClick={() => setActiveTab("vadodara")}
                    className={`metro-inner  ${ActiveTab === "vadodara" ? "active" : ""}`}
                  >
                    Vadodara
                  </span>
                  <span
                    onClick={() => setActiveTab("bhavnagar")}
                    className={`metro-inner  ${ActiveTab === "bhavnagar" ? "active" : ""}`}
                  >
                    Bhavnagar
                  </span>
                  <span
                    onClick={() => setActiveTab("bhuj")}
                    className={`metro-inner  ${ActiveTab === "bhuj" ? "active" : ""}`}
                  >
                    Bhuj
                  </span>
                </div>
              </div>
              {innerData?.length > 0 ? (
                <div className="shadow p-3 mb-3 bg-white rounded">
                  <div className="row">
                    <div className="col-xl-8 megapreView col-lg-12">
                      {innerData?.length > 0 &&
                        innerData?.map((els, idx) => (
                          <>
                            {idx === 0 && (
                              <Link
                                target="_parent"
                                to={`/${els?.url}`}
                                className="story"
                              >
                                <div className="caption mb-0">
                                  <img
                                    loading="lazy"
                                    key={Math.random()}
                                    src={`${assetbaseUrl?.value}${els?.media}`}
                                    alt={`${els?.title}`}
                                  />
                                  {els?.tagline ? (
                                    <p className="button-hover colorspan-top-post">
                                      {els?.tagline}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                  {els?.video ? (
                                    <p className="playicon-subhome">
                                      <i className="fas fa-play"></i>
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                  <div className="categoty-main-post-title">
                                    <p className="text-white ellipsis">
                                      {els?.title}
                                    </p>
                                    <p className="text-white font-size-small blog-time">
                                      <i className="far fa-clock mr-2"></i>
                                      {els?.publish_date}
                                    </p>
                                  </div>
                                </div>
                              </Link>
                            )}
                          </>
                        ))}
                    </div>
                    <div className="col-xl-4 col-lg-12">
                      <div className="right-top-post">
                        {innerData?.length > 0 &&
                          innerData?.map((els, idx) => (
                            <>
                              {idx > 0 && idx < 5 ? (
                                <Link
                                  target="_parent"
                                  to={`/${els?.url}`}
                                  className="related-blogs"
                                >
                                  <p className="img-16-9-blogs eskeleton">
                                    <div
                                      className="col p-0"
                                      style={{ width: "100px" }}
                                    >
                                      <span className="skeleton">
                                        <img
                                          loading="lazy"
                                          key={Math.random()}
                                          src={`${assetbaseUrl?.value}${els?.media}`}
                                          alt=""
                                          className="img-responsive"
                                        />
                                        {els?.video ? (
                                          <p className="playicon-subhome-subcategory">
                                            <i className="fas fa-play"></i>
                                          </p>
                                        ) : (
                                          ""
                                        )}
                                      </span>
                                      <p className="blog-time mt-1 m-0">
                                        <i className="far fa-clock mr-2"></i>
                                        {els?.publish_date}
                                      </p>
                                    </div>
                                  </p>
                                  <div className="col-8 p-0">
                                    <div className="blog-details">
                                      <span>
                                        <b className="color-red">
                                          {els?.tagline}
                                        </b>
                                      </span>
                                      <p className="ellipsis">{els?.title}</p>
                                    </div>
                                  </div>
                                </Link>
                              ) : (
                                ""
                              )}
                            </>
                          ))}
                      </div>
                    </div>
                  </div>
                  <div className="row substory">
                    {innerData?.length > 0 &&
                      innerData?.map((els, idx) => (
                        <>
                          {idx > 4 && idx < 8 && (
                            <div className="col-lg-6 col-xl-4">
                              <Link
                                target="_parent"
                                to={`/${els?.url}`}
                                className="story"
                              >
                                <div className="caption mb-0">
                                  <img
                                    loading="lazy"
                                    key={Math.random()}
                                    src={`${assetbaseUrl?.value}${els?.media}`}
                                    alt={`${els?.title}`}
                                  />
                                  {els?.video ? (
                                    <p className="playicon-subhome">
                                      <i className="fas fa-play"></i>
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="categoty-padding">
                                  <p className="buttoipn-hover topleft colorspan-top-trending">
                                    {els?.post_type === 1 ||
                                    els?.post_type === 2
                                      ? els?.tagline
                                      : els?.time}
                                  </p>
                                  <p className="ellipsis">{els?.title}</p>
                                  <span className="text-secondary blog-time">
                                    <i className="far fa-clock mr-2"></i>
                                    {els?.publish_date}
                                  </span>
                                </div>
                              </Link>
                            </div>
                          )}
                        </>
                      ))}
                  </div>
                </div>
              ) : (
                <p className="post-not-found">Loading...</p>
              )}
              <div className="ad-section">
                <HelperScript argSmall={195} argLarge={51} />
              </div>
              <InjectScript script={afterPrimary} />

              {innerData?.length > 8 ? (
                <div className="shadow p-3 mb-3 bg-white rounded">
                  <div className="row substory">
                    {innerData?.length > 0 &&
                      innerData?.map((els, idx) => (
                        <>
                          {idx > 7 && idx < 14 && (
                            <div className="col-lg-6 col-xl-4">
                              <Link
                                target="_parent"
                                to={`/${els?.url}`}
                                className="story"
                              >
                                <div className="caption mb-0">
                                  <img
                                    loading="lazy"
                                    key={Math.random()}
                                    src={`${assetbaseUrl?.value}${els?.media}`}
                                    alt={`${els?.title}`}
                                  />
                                  {els?.video ? (
                                    <p className="playicon-subhome">
                                      <i className="fas fa-play"></i>
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>

                                <div className="categoty-padding">
                                  <p className="button-hover topleft colorspan-top-trending">
                                    {els?.post_type === 1 ||
                                    els?.post_type === 2
                                      ? els?.tagline
                                      : els?.time}
                                  </p>
                                  <p className="ellipsis">{els?.title}</p>
                                  <span className="text-secondary blog-time">
                                    <i className="far fa-clock mr-2"></i>
                                    {els?.publish_date}
                                  </span>
                                </div>
                              </Link>
                            </div>
                          )}
                        </>
                      ))}
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="ad-section">
                <HelperScript argSmall={196} argLarge={52} />
              </div>
              {innerData?.length > 14 ? (
                <div className="shadow p-3 mb-3 bg-white rounded">
                  <div className="row substory">
                    {innerData?.length > 0 &&
                      innerData?.map((els, idx) => (
                        <>
                          {idx > 13 && idx < 17 && (
                            <div className="col-lg-6 col-xl-4">
                              <Link
                                target="_parent"
                                to={`/${els?.url}`}
                                className="story"
                              >
                                <div className="caption mb-0">
                                  <img
                                    loading="lazy"
                                    key={Math.random()}
                                    src={`${assetbaseUrl?.value}${els?.media}`}
                                    alt={`${els?.title}`}
                                  />
                                  {els?.video ? (
                                    <p className="playicon-subhome">
                                      <i className="fas fa-play"></i>
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>

                                <div className="categoty-padding">
                                  <p className="button-hover topleft colorspan-top-trending">
                                    {els?.post_type === 1 ||
                                    els?.post_type === 2
                                      ? els?.tagline
                                      : els?.time}
                                  </p>
                                  <p className="ellipsis">{els?.title}</p>
                                  <span className="text-secondary blog-time">
                                    <i className="far fa-clock mr-2"></i>
                                    {els?.publish_date}
                                  </span>
                                </div>
                              </Link>
                            </div>
                          )}
                        </>
                      ))}
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="ad-section">
                <HelperScript argSmall={197} argLarge={53} />
              </div>

              {innerData?.length > 18 ? (
                <div className="shadow p-3 mb-3 bg-white rounded">
                  <div className="row substory">
                    {innerData?.length > 0 &&
                      innerData?.map((els, idx) => (
                        <>
                          {idx > 17 ? (
                            <div className="col-lg-6 col-xl-4">
                              <Link
                                target="_parent"
                                to={`/${els?.url}`}
                                className="story"
                              >
                                <div className="caption mb-0">
                                  <img
                                    loading="lazy"
                                    key={Math.random()}
                                    src={`${assetbaseUrl?.value}${els?.media}`}
                                    alt={`${els?.title}`}
                                  />
                                  {els?.video ? (
                                    <p className="playicon-subhome">
                                      <i className="fas fa-play"></i>
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>

                                <div className="categoty-padding">
                                  <p className="button-hover topleft colorspan-top-trending">
                                    {els?.post_type === 1 ||
                                    els?.post_type === 2
                                      ? els?.tagline
                                      : els?.time}
                                  </p>
                                  <p className="ellipsis">{els?.title}</p>
                                  <span className="text-secondary blog-time">
                                    <i className="far fa-clock mr-2"></i>
                                    {els?.publish_date}
                                  </span>
                                </div>
                              </Link>
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      ))}
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="ad-section">
                <HelperScript argSmall={198} argLarge={54} />
              </div>

              <div>
                <nav
                  aria-label="Page navigation example"
                  style={{ display: pageCount ? "block" : "none" }}
                >
                  <div className="justify-content-center text-center">
                    <ul
                      className={`pagination myActive${pageArray?.length === activePage ? `last` : activePage} myActiveFirst${pageArray?.length === 1 ? `last` : activePage}`}
                    >
                      <li
                        onClick={() => {
                          setActivePage(1);
                        }}
                        className="firstpage page-item"
                      >
                        <a className="page-link">
                          {"<"}
                          {"<"} First
                        </a>
                      </li>
                      <li
                        onClick={() => {
                          if (activePage > 1) {
                            setActivePage(activePage - 1);
                          }
                        }}
                        className="firstpage page-item"
                      >
                        <a className="page-link">Previous</a>
                      </li>

                      {pageArray?.length > 0 &&
                        pageArray?.map(
                          (els, index) =>
                            activePage - 3 <= index &&
                            activePage + 2 > index && (
                              <li
                                onClick={() => setActivePage(els + 1)}
                                key={Math.random()}
                                className={`page-item ${els + 1 === activePage ? "active" : ""}`}
                              >
                                <a className="page-link">{els + 1}</a>
                              </li>
                            ),
                        )}

                      <li
                        onClick={() => {
                          if (pageArray?.length !== activePage) {
                            setActivePage(activePage + 1);
                          }
                        }}
                        className="lastpage page-item"
                      >
                        <a className="page-link">Next</a>
                      </li>
                      <li
                        onClick={() => setActivePage(pageArray?.length)}
                        className="lastpage page-item"
                      >
                        <a className="page-link">
                          Last {">"}
                          {">"}
                        </a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              {window.innerWidth > 991 ? (
                <HomeTaboola key={window.location.href} />
              ) : (
                ""
              )}
            </div>

            <div className="insta-head-responsive col-12 col-sm-12 col-md-5 col-lg-4 col-xl-3">
              <div className="text-center">
                <div className="ad-side-head ">
                  <div className="ad-side m-0">
                    <div className="ad-section">
                      {/* <HelperScript argSmall={201} argLarge={57} /> */}
                      <InjectScript script={IBVcategory} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="shadow rightBar p-x2 mt-4 mb-2 bg-f7f7f7 rounded">
                <div className="featured ">
                  <a href="javascript:void(0)" className="title">
                    Top 10 News
                  </a>
                  <div className="scroll s-story">
                    {homePrimary?.length > 0 &&
                      homePrimary?.map((els, idx) => (
                        <>
                          <CommonContent
                            url={els?.url}
                            bgcolorclass="bgcolorcard"
                            dataclass="top10"
                            src={`${assetbaseUrl?.value}${els?.media}`}
                            post_type={els?.post_type}
                            publish_date={els?.publish_date}
                            category={els?.category}
                            title={els?.title}
                          />
                          <hr className="border-bt" />
                        </>
                      ))}
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-center">
                <div className="ad-side-head">
                  <div className="ad-section">
                    <HelperScript argSmall={202} argLarge={58} />
                  </div>
                </div>
              </div>

              <div className="text-center">
                <div className="ad-side-head ">
                  <div className="ad-side m-0">
                    <div className="ad-section">
                      <HelperScript argSmall={203} argLarge={59} />
                    </div>
                  </div>
                  <div className="ad-side">
                    <div className="ad-section">
                      <HelperScript argSmall={205} argLarge={100} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="my-2 sidebar-taboola" key={window.location.href}>
                <InjectScript
                  type="taboola"
                  script={taboolacontent}
                  key={window.location.href}
                />
                <div className="ad-sticky-top">
                  <HelperScript argLarge={261} />
                </div>
              </div>
            </div>
          </div>

          {window.innerWidth < 991 ? (
            <HomeTaboola key={window.location.href} />
          ) : (
            ""
          )}
        </div>
      </section>
      <div className="ad-section">
        <HelperScript argSmall={194} argLarge={47} />
      </div>
    </>
  );
};

export default GujaratMetro;
