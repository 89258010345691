// Import action types related to search results
import {
  SEARCH_RESULT_ARE_LOADING,
  SEARCH_RESULT_HAVE_ERROR,
  SEARCH_RESULT_FETCH_DATA_SUCCESS,
} from "../actionType";

// Define the initial state for the search result reducer
const initialState = {
  data: [], // Initialize data as an empty array
  loading: true, // Set loading to true initially
  error: [], // Initialize error as an empty array
};

// Reducer function to handle search result-related actions and update state accordingly
export function searchResult(state = initialState, action) {
  switch (action.type) {
    // Handle error case when fetching search results
    case SEARCH_RESULT_HAVE_ERROR:
      return {
        ...state,
        error: action.hasError, // Update the error state with the error from the action
        loading: false, // Set loading to false since the request is complete
      };

    // Handle loading state when search results are being fetched
    case SEARCH_RESULT_ARE_LOADING:
      return {
        ...state,
        loading: action.isLoading, // Update the loading state with the loading status from the action
      };

    // Handle success case when search results are successfully fetched
    case SEARCH_RESULT_FETCH_DATA_SUCCESS:
      return {
        ...state,
        data: action.searchResult, // Update the data state with the fetched search results
        loading: false, // Set loading to false since the request is complete
        error: [], // Clear any previous errors (assuming the success case means no error)
      };

    // Return the current state by default if action type is not recognized
    default:
      return state;
  }
}
