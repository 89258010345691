import React from "react";
import { Link } from "react-router-dom";

function CommonvideoContent({ url, src, title, time, dataclass2 }) {
  // Use optional chaining for safer prop access
  const hasUrl = url !== undefined;

  const content = (
    <div className="border-video">
      <div className="skeleton-sub">
        <img loading="lazy" src={src} alt="" key={Math.random()} />
        <span className="button-hover sub-video">{time}</span>
        <p className="playicon">
          <i className="fas fa-play"></i>
        </p>
      </div>
      <div className="video-content">
        <div className="blog-details font-size-gujrat-title">
          <p className="font-size-video m-0 pt-1 ellipsis">{title}</p>
        </div>
      </div>
    </div>
  );

  return (
    <div className={`${dataclass2 ? "share-common-home-video" : ""}`}>
      {hasUrl ? (
        <Link target="_parent" to={`/${url}`}>
          {content}
        </Link>
      ) : (
        <a href="#" className="modal">
          {content}
        </a>
      )}
    </div>
  );
}

export default CommonvideoContent;
