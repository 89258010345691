// Import action types related to e-paper image data
import {
  EPAPERIMAGE_ARE_LOADING,
  EPAPERIMAGE_HAVE_ERROR,
  EPAPERIMAGE_FETCH_DATA_SUCCESS,
} from "../actionType";

// Define the initial state for the ePaperImageReducer
const initialState = {
  data: [],
  loading: true,
  error: [], // Initialize error as null for better error handling
};

// Reducer function to handle e-paper image-related actions and update state accordingly
export function ePaperImageReducer(state = initialState, action) {
  switch (action.type) {
    // Handle error case when fetching e-paper image data
    case EPAPERIMAGE_HAVE_ERROR:
      return {
        ...state,
        error: action.hasError, // Update the error state with the error from the action
        loading: false, // Set loading to false since the request is complete
      };

    // Handle loading state when e-paper image data is being fetched
    case EPAPERIMAGE_ARE_LOADING:
      return {
        ...state,
        loading: action.isLoading, // Update the loading state with the loading status from the action
      };

    // Handle success case when e-paper image data is successfully fetched
    case EPAPERIMAGE_FETCH_DATA_SUCCESS:
      return {
        ...state,
        data: action.ePaperImage, // Update the data state with the fetched e-paper image data
        loading: false, // Set loading to false since the request is complete
        error: null, // Clear any previous errors
      };

    // Return the current state by default if action type is not recognized
    default:
      return state;
  }
}
