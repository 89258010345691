// Import necessary action types, HttpService, and Get function
import {
  SEARCH_RESULT_FETCH_DATA_SUCCESS,
  SEARCH_RESULT_HAVE_ERROR,
  SEARCH_RESULT_ARE_LOADING,
} from "../actionType";
import HttpService from "../../utils/httpService";
import { Get } from "../../services/api.service";

// Action creator function to handle different types of search result actions
export function searchResultAction(action, payload) {
  switch (action) {
    case SEARCH_RESULT_HAVE_ERROR:
      // Return action object indicating error
      return {
        type: action,
        hasError: payload,
      };

    case SEARCH_RESULT_ARE_LOADING:
      // Return action object indicating loading state
      return {
        type: action,
        isLoading: payload,
      };

    case SEARCH_RESULT_FETCH_DATA_SUCCESS:
      // Return action object containing fetched search result data
      return {
        type: action,
        searchResult: payload,
      };

    default:
      return;
  }
}

// Async action creator function to fetch search result data based on provided search parameters
export function searchResult(searchParams = "") {
  return (dispatch) => {
    // Dispatch action to indicate data loading
    dispatch(searchResultAction(SEARCH_RESULT_ARE_LOADING, true));

    // Make a GET request to fetch search result data based on provided search parameters
    return Get(HttpService.searchResult + searchParams)
      .then((result) => {
        // Dispatch success action with fetched search result data
        dispatch(searchResultAction(SEARCH_RESULT_FETCH_DATA_SUCCESS, result));
        // Resolve the promise with the result
        return result;
      })
      .catch((error) => {
        // Dispatch error action if request fails and reject the promise
        dispatch(searchResultAction(SEARCH_RESULT_HAVE_ERROR, true));
        return Promise.reject(error);
      });
  };
}
