import {
  GUJARATSAMAGRACITY_FETCH_DATA_SUCCESS,
  GUJARATSAMAGRACITY_HAVE_ERROR,
  GUJARATSAMAGRACITY_ARE_LOADING,
} from "../actionType";

import HttpService from "../../utils/httpService";
import { Get } from "../../services/api.service";

// Action creator function to handle different types of Gujarat Samagra City actions
export function GujaratSamagraCityAction(action, payload) {
  switch (action) {
    case GUJARATSAMAGRACITY_HAVE_ERROR:
      return {
        type: action,
        hasError: payload,
      };

    case GUJARATSAMAGRACITY_ARE_LOADING:
      return {
        type: action,
        isLoading: payload,
      };

    case GUJARATSAMAGRACITY_FETCH_DATA_SUCCESS:
      return {
        type: action,
        GujaratSamagraCity: payload,
      };

    default:
      return;
  }
}

// Async action creator function to fetch Gujarat Samagra City data
export function GujaratSamagraCityFetchData() {
  return (dispatch) => {
    try {
      // Dispatch action indicating data is loading
      dispatch(GujaratSamagraCityAction(GUJARATSAMAGRACITY_ARE_LOADING, true));

      // Fetch Gujarat Samagra City data using GET request
      return new Promise((resolve, reject) => {
        Get(HttpService.gujaratSamagraCity).then(
          (result) => {
            // Dispatch action with fetched Gujarat Samagra City data
            dispatch(
              GujaratSamagraCityAction(
                GUJARATSAMAGRACITY_FETCH_DATA_SUCCESS,
                result.data,
              ),
            );
            resolve(result);
          },
          (error) => {
            reject(error); // Reject promise with error if fetching fails
          },
        );
      });
    } catch (error) {
      // Dispatch action if there's an error in fetching data
      dispatch(GujaratSamagraCityAction(GUJARATSAMAGRACITY_HAVE_ERROR, true));
    }
  };
}
