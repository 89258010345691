import React from "react";
import ReactGA from "react-ga4";
import { useDispatch, useSelector } from "react-redux";
import { sharecount } from "../../redux/actions/commonAction";

const Share = (props) => {
  // Get necessary data from Redux store
  const { 21: assetbaseUrl, 26: sharemsg } = useSelector((state) => state?.SettingReducer?.data);
  const dispatch = useDispatch();

  // Construct URL paths for sharing
  const urlshares = "https://" + window.location.hostname + "/" + props.url;
  const urlshare = "https://" + window.location.hostname + "/" + props.url + "\r\n \r\n";
  const media = assetbaseUrl?.value + props.media + "?resize=1200,630";
  const title = props.title + "\r\n";
  const ShareValue = sharemsg?.value + "\r\n \r\n";

  // Function to handle share events and dispatch share count
  const handleShare = (name) => {
    document.getElementById(props.id).classList.add("d-none");
    dispatch(sharecount(name, props?.dataid));
    ReactGA.event({ category: "Share", action: name, label: urlshare });
    return false;
  };

  // Function to handle copy event
  const handleCopy = (e) => {
    handleShare("copy");
    var textArea = document.createElement("textarea");
    document.body.className += " copied";

    textArea.style.position = "fixed";
    textArea.style.top = 0;
    textArea.style.left = 0;
    textArea.style.width = "2em";
    textArea.style.height = "2em";
    textArea.style.padding = 0;
    textArea.style.border = "none";
    textArea.style.outline = "none";
    textArea.style.boxShadow = "none";
    textArea.style.background = "transparent";

    textArea.value = urlshares;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand("copy");
      const node = document.createElement("span");
      const textnode = document.createTextNode("Link copied.");
      node.className = "copyMsg";
      node.setAttribute("id", "copyMsg");
      node.appendChild(textnode);
      document.getElementById("root").appendChild(node);
      setTimeout(() => {
        document.getElementById("copyMsg").outerHTML = "";
      }, 1000);
    } catch (err) {}

    document.getElementById(props.id).classList.add("d-none");
    document.body.removeChild(textArea);
    document.querySelector("body").classList.remove("copied");
    e.stopPropagation();
    return false;
  };

  // JSX for share buttons
  return (
    <>
      {/* Facebook share button */}
      <a
        id="dropdown"
        onClickCapture={(e) => handleShare("fb", e)}
        onClick={() => handleShare("fb")}
        className="social-share"
        aria-label="Facebook"
        href={`${"https://facebook.com/sharer/sharer.php?display=page&picture=" + encodeURIComponent(media) + "&quote=" + encodeURIComponent(title + urlshare) + "&u=" + encodeURIComponent(urlshare)}`}
        target="_blank"
        rel="noreferrer"
      >
        <i className="fab fa-facebook"></i>
      </a>
      {/* Twitter share button */}
      <a
        id="dropdown"
        onClickCapture={(e) => handleShare("tw", e)}
        onClick={() => handleShare("tw")}
        className="social-share"
        aria-label="Twitter"
        href={`${"https://twitter.com/share?text=" + encodeURIComponent(title) + "&url=" + encodeURIComponent(urlshare) + encodeURIComponent(ShareValue)}`}
        target="_blank"
        rel="noreferrer"
      >
        <i className="fab fa-twitter"></i>
      </a>
      {/* WhatsApp share button */}
      <a
        id="dropdown"
        onClickCapture={(e) => handleShare("wa", e)}
        onClick={() => handleShare("wa")}
        className="social-share"
        aria-label="Whatsapp"
        href={`${"https://api.whatsapp.com/send?text=" + encodeURIComponent(title) + encodeURIComponent(urlshare) + encodeURIComponent(ShareValue)}`}
        target="_blank"
        rel="noreferrer"
      >
        <i className="fab fa-whatsapp"></i>
      </a>
      {/* Email share button */}
      <a
        id="dropdown"
        target="_blank"
        onClickCapture={(e) => handleShare("Email", e)}
        href={`mailto:?subject=${title}&body=${title}%0D%0A%0D%0A${urlshare}%0D%0A%0D%0A${ShareValue}`}
        className="social-share cursor-pointer" rel="noreferrer"
      >
        <i className="fa fa-envelope"></i>
      </a>
    </>
  );
};

export default Share;
