// Import action types related to comment data
import {
  COMMENT_ARE_LOADING,
  COMMENT_HAVE_ERROR,
  COMMENT_FETCH_DATA_SUCCESS,
} from "../actionType";

// Define the initial state for the CommentReducer
const initialState = {
  data: [],
  loading: true,
  error: [], // Initialize error as null for better error handling
};

// Reducer function to handle comment-related actions and update state accordingly
export function CommentReducer(state = initialState, action) {
  switch (action.type) {
    // Handle error case when fetching comment data
    case COMMENT_HAVE_ERROR:
      return {
        ...state,
        error: action.hasError, // Update the error state with the error from the action
        loading: false, // Set loading to false since the request is complete
      };

    // Handle loading state when comment data is being fetched
    case COMMENT_ARE_LOADING:
      return {
        ...state,
        loading: action.isLoading, // Update the loading state with the loading status from the action
      };

    // Handle success case when comment data is successfully fetched
    case COMMENT_FETCH_DATA_SUCCESS:
      return {
        ...state,
        data: action.data, // Update the data state with the fetched comment data
        loading: false, // Set loading to false since the request is complete
        error: null, // Clear any previous errors
      };

    // Return the current state by default if action type is not recognized
    default:
      return state;
  }
}
