// Import necessary action types and services
import {
  SETTING_ARE_LOADING,
  SETTING_FETCH_DATA_SUCCESS,
  SETTING_HAVE_ERROR,
} from "../actionType";
import HttpService from "../../utils/httpService";
import { Get } from "../../services/api.service";

// Action creator function to handle different types of setting actions
export function settingAction(action, payload) {
  switch (action) {
    case SETTING_HAVE_ERROR:
      // Return action object indicating error
      return {
        type: action,
        hasError: payload,
      };

    case SETTING_ARE_LOADING:
      // Return action object indicating loading state
      return {
        type: action,
        isLoading: payload,
      };

    case SETTING_FETCH_DATA_SUCCESS:
      // Return action object containing setting data
      return {
        type: action,
        Setting: payload,
      };

    default:
      return;
  }
}

// Action creator function to fetch setting data
export function settingFetchData() {
  return (dispatch) => {
    // Dispatch loading action
    dispatch(settingAction(SETTING_ARE_LOADING, true));
    try {
      // Fetch setting data
      return new Promise((resolve, reject) => {
        Get(HttpService.setting).then(
          (result) => {
            // Extract settings data and store it in local storage
            const settings = result.data;
            localStorage.setItem("SandeshCDN", settings[21].value);
            // Dispatch success action with setting data
            dispatch(settingAction(SETTING_FETCH_DATA_SUCCESS, settings));
            resolve(result);
          },
          (error) => {
            // Dispatch error action if request fails
            reject(error);
          }
        );
      });
    } catch (error) {
      // Dispatch error action if an error occurs during fetch
      dispatch(settingAction(SETTING_HAVE_ERROR, true));
    }
  };
}
