import React from "react"; // Importing React
import ReactGA from "react-ga4"; // Importing ReactGA for Google Analytics
import Carousel from "react-multi-carousel"; // Importing Carousel component from react-multi-carousel
import "react-multi-carousel/lib/styles.css"; // Importing styles for react-multi-carousel
import { useSelector } from "react-redux"; // Importing useSelector from react-redux for state management
import { Link } from "react-router-dom"; // Importing Link from react-router-dom for client-side routing
import CommonGalleryContent from "../CommonGalleryContent"; // Importing CommonGalleryContent component
import InjectScript from "../CustomScript"; // Importing InjectScript component

// Defining the Gallery component
const Gallery = () => {
  // Using useSelector to get the asset base URL from the SettingReducer state
  const { 21: assetbaseUrl } = useSelector((state) => state?.SettingReducer?.data);

  // Using useSelector to get the Gallery data from the HomeSecondaryReducer state
  const Gallerylist = useSelector((state) => state?.HomeSecondaryReducer?.data?.Gallery);

  // Defining responsive breakpoints for the Carousel component
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1200 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 1200, min: 991 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 991, min: 515 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 515, min: 0 },
      items: 1,
    },
  };

  // Defining a script for Google AdSense ads
  const galleryHeader =
    "" +
    '<script async src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"></script>' +
    "<script>" +
    "window.googletag = window.googletag || {cmd: []};" +
    "googletag.cmd.push(function () {" +
    "googletag.defineSlot('/22678428348/SDPL_Desktop_HP_belowphotogallery_970x66', [970, 66], 'div-gpt-ad-1657342450482-0').addService(googletag.pubads());" +
    "googletag.pubads().enableSingleRequest();" +
    "googletag.enableServices();" +
    "});" +
    "</script >" +
   
    "" +
    "";

  // Defining a script for Google AdSense ads
  const galleryBody =
    "" +
    "<div id='div-gpt-ad-1672815373860-0' style='min-width: 970px; min-height: 66px;'>" +
    "<script>" +
    "window.googletag = window.googletag || {cmd: []};" +
    "googletag.cmd.push(function () {" +
    " googletag.defineSlot('/22678428348/SDPL_Desktop_HP_belowphotogallery_970x66', [[970, 66], [970, 90]], 'div-gpt-ad-1672815373860-0').addService(googletag.pubads());" +
    "googletag.pubads().enableSingleRequest();" +
    "googletag.enableServices();" +
    "});" +
    "</script >" +
    "" +
    "<script>" +
    "googletag.cmd.push(function() {googletag.display('div-gpt-ad-1672815373860-0'); });" +
    " </script>" +
    "</div>" +
   
    "" +
    "";

  // Defining a script for Google AdSense ads
  const belowGalleryMweb =
    "" +
    "<div id='div-gpt-ad-1657874239314-0' style='min-width: 300px; min-height: 100px;'>" +
    " <script>" +
    "window.googletag = window.googletag || {cmd: []};" +
    "googletag.cmd.push(function() {" +
    "googletag.defineSlot('/22678428348/Mweb_HP_BTF_BelowPhotogallery_300x100', [300, 100], 'div-gpt-ad-1657874239314-0').addService(googletag.pubads());" +
    "googletag.pubads().enableSingleRequest();" +
    "googletag.enableServices();" +
    "});" +
    " </script>" +
    "" +
    "<script>" +
    "googletag.cmd.push(function() { googletag.display('div-gpt-ad-1657874239314-0'); });" +
    "</script>" +
    "</div>" +
    "" +
    "";

  // Returning the JSX for the Gallery component
  return (
    <>
      <section className="gallery ">
        <div className="container mb-4-half">
          <span className="badge badge-info">Photo Gallery</span>
          <Link
            target="_parent"
            to="/gallery"
            onClickCapture={() =>
              ReactGA.event({
                category: "Homepage",
                action: "Photo Gallery",
                label: "Read More",
              })
            }
            className="readmore"
          >
            View All
          </Link>
          <div className="row">
            <div className="col-md-12">
              <Carousel
                swipeable={true}
                draggable={false}
                infinite={false}
                showDots={false}
                responsive={responsive}
                autoPlaySpeed={5000000}
                transitionDuration={10}
                renderArrowsWhenDisabled={true}
                customTransition="transform 500ms ease"
                ssr={true}
                className="gallery-sec newgallery"
              >
                {Gallerylist?.length > 0 &&
                  Gallerylist?.map((els, idx) => (
                    <CommonGalleryContent
                      url={els?.url}
                      src={`${assetbaseUrl?.value}${els?.media.replace("?resize=800,450", "")}?resize=249,390`}
                      title={els?.title}
                    />
                  ))}
              </Carousel>
            </div>
          </div>
        </div>
      </section>
      <div className="">
        {window.innerWidth > 991? (
          <div className="">
            <div className="text-center">
              <InjectScript script={galleryBody} key={galleryBody} />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      {window.innerWidth < 991? (
        <div className="">
          <div className="text-center">
            <InjectScript script={belowGalleryMweb} key={belowGalleryMweb} />
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Gallery;