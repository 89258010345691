// This is a React component that displays a list of blogs categorized into Business, Writers, and Lifestyle. It fetches data from Redux store and renders the blogs with images, titles, and share buttons.

import React from "react";
import ReactGA from "react-ga4";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import sandeshske from "../../assets/sandeshske.png";
import CommonContent from "../CommonContent";
import InjectScript from "../CustomScript";
import ShareCommon from "../component/ShareCommon";

const Blogs = () => {
  // Redux state variables for different blog categories
  const { 21: assetbaseUrl } = useSelector(
    (state) => state?.SettingReducer?.data
  );
  const Lifestylelist = useSelector(
    (state) => state?.HomeSecondaryReducer?.data?.Lifestyle
  );
  const Columnistlist = useSelector(
    (state) => state?.HomeSecondaryReducer?.data?.Columnist
  );
  const Businesslist = useSelector(
    (state) => state?.HomeSecondaryReducer?.data?.Business
  );

  // Function to handle share button click event
  const onShareClick = (e) => {
    e.preventDefault();
    document.getElementById("common-share-dropdown").classList.remove("d-none");
    e.preventDefault();
    return false;
  };

  // HTML content for displaying advertisement below Business section
  const belowBusinessMweb = `
  <div id='div-gpt-ad-1657877625751-0' style='min-width: 300px; min-height: 100px;'>
    <script>
      window.googletag = window.googletag || {cmd: []};
      googletag.cmd.push(function() {
        googletag.defineSlot('/22678428348/Mweb_HP_BTF_BelowBusiness_300x100', [300, 100], 'div-gpt-ad-1657877625751-0').addService(googletag.pubads());
        googletag.pubads().enableSingleRequest();
        googletag.enableServices();
      });
    </script>
    <script>
      googletag.cmd.push(function() { googletag.display('div-gpt-ad-1657877625751-0'); });
    </script>
  </div>
  `;

  return (
    <>
      <section className="blogs">
        <div className="container">
          <div className="row">
            {/* Business Section */}
            <div className="col-md-12 col-lg-4 col-xl-4 gujarat-responsive-space-sm">
              <div className="single-blog">
                {/* Display Business badge */}
                <span className="badge badge-info">Business</span>
                {/* Read More link for Business */}
                <Link
                  target="_parent"
                  to="/business"
                  onClickCapture={() =>
                    ReactGA.event({
                      category: "Homepage",
                      action: "Business",
                      label: "Read More",
                    })
                  }
                  className="readmore"
                >
                  Read More
                </Link>
                {/* Display main Business blog */}
                {Businesslist?.length > 0 && (
                  <>
                    <Link
                      target="_parent"
                      to={`/${Businesslist[0]?.url}`}
                      className="main-blogs share-blog"
                    >
                      {/* Blog image */}
                      <div className="skeleton-sub">
                        <img
                          loading="lazy"
                          src={
                            !Businesslist?.[0]?.media
                              ? sandeshske
                              : `${assetbaseUrl?.value}${Businesslist?.[0]?.media.replace("?resize=800,450", "")}?resize=425,240`
                          }
                          alt={`${Businesslist?.[0]?.title}`}
                          className="img-responsive"
                        />
                      </div>
                      {/* Display video timer if applicable */}
                      {Businesslist?.[0]?.post_type === 3 ||
                      Businesslist?.[0]?.post_type === 4 ? (
                        <p className="button-hover timer_ subvideo-tagline">
                          {Businesslist?.[0]?.time}
                        </p>
                      ) : (
                        ""
                      )}
                      {/* Blog details */}
                      <div className="blog-details">
                        <p className="ellipsis">{Businesslist[0]?.title}</p>
                      </div>
                      {/* Share button */}
                      <div class="dropleft">
                        <button
                          onClick={(e) => onShareClick(e)}
                          className="dropbtn ml-2"
                        >
                          <i class="fa fa-share-alt"></i>
                        </button>
                        {/* Share dropdown menu */}
                        <div class="dropdown-menu">
                          <ShareCommon
                            id="common-share-dropdown"
                            url={Businesslist[0].url}
                            media={`${assetbaseUrl?.value}${Businesslist?.[0]?.media}`}
                            title={Businesslist[0]?.title}
                            dataid={Businesslist[0]?.id}
                          />
                        </div>
                      </div>
                    </Link>
                  </>
                )}
                {/* Display additional Business blogs */}
                <div>
                  {Businesslist?.length > 0 &&
                    Businesslist?.map((els, idx) => (
                      <>
                        {idx > 0 && idx <= 3 && (
                          <div className="row col-12 p-0 m-0">
                            <CommonContent
                              url={els?.url}
                              src={`${assetbaseUrl?.value}${els?.media.replace("?resize=800,450", "")}?resize=90,51`}
                              post_type={els?.post_type}
                              time={els?.time}
                              category={els?.tagline}
                              title={els?.title}
                            />
                          </div>
                        )}
                      </>
                    ))}
                </div>
              </div>
            </div>
            {/* Advertisement for mobile view */}
            {window.innerWidth < 991 ? (
              <div className="w-100">
                <div className="text-center">
                  <InjectScript
                    script={belowBusinessMweb}
                    key={belowBusinessMweb}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            {/* Writers Section */}
            <div className="col-md-12 col-lg-4 col-xl-4 gujarat-responsive-space-sm">
              <div className="single-blog">
                <span className="badge badge-danger">Writers</span>
                <Link
                  target="_parent"
                  to="/opinion"
                  onClickCapture={() =>
                    ReactGA.event({
                      category: "Homepage",
                      action: "Opinion",
                      label: "Read More",
                    })
                  }
                  className="readmore"
                >
                  Read More
                </Link>
                {/* Display main Writers blog */}
                {Columnistlist?.length > 0 && (
                  <>
                    <Link
                      target="_parent"
                      to={`/${Columnistlist[0]?.url}`}
                      className="main-blogs w-100 share-blog"
                    >
                      <div className="skeleton-sub">
                        <img
                          loading="lazy"
                          src={
                            !Columnistlist?.[0]?.media
                              ? sandeshske
                              : `${assetbaseUrl?.value}${Columnistlist?.[0]?.media.replace("?resize=800,450", "")}?resize=425,240`
                          }
                          alt={`${Columnistlist?.[0]?.title}`}
                          className="img-responsive"
                        />
                      </div>
                      {Columnistlist?.[0]?.post_type === 3 ||
                      Columnistlist?.[0]?.post_type === 4 ? (
                        <p className="button-hover timer_ subvideo-tagline">
                          {Columnistlist?.[0]?.time}
                        </p>
                      ) : (
                        ""
                      )}
                      <div className="blog-details">
                        <p className="ellipsis">{Columnistlist[0]?.title}</p>
                      </div>
                      <div class="dropleft">
                        <button
                          onClick={(e) => onShareClick(e)}
                          className="dropbtn ml-2"
                        >
                          <i class="fa fa-share-alt"></i>
                        </button>
                        <div class="dropdown-menu">
                          <ShareCommon
                            id="common-share-dropdown"
                            url={Businesslist[0].url}
                            media={`${assetbaseUrl?.value}${Businesslist?.[0]?.media}`}
                            title={Businesslist[0]?.title}
                            dataid={Businesslist[0]?.id}
                          />
                        </div>
                      </div>
                    </Link>
                  </>
                )}
                {/* Display additional Writers blogs */}
                {Columnistlist?.length > 0 &&
                  Columnistlist?.map((els, idx) => (
                    <>
                      {idx > 0 && idx <= 3 && (
                        <CommonContent
                          url={els?.url}
                          src={`${assetbaseUrl?.value}${els?.media.replace("?resize=800,450", "")}?resize=90,51`}
                          post_type={els?.post_type}
                          time={els?.time}
                          category={els?.tagline}
                          title={els?.title}
                        />
                      )}
                    </>
                  ))}
              </div>
            </div>
            {/* Lifestyle Section */}
            <div className="col-md-12 col-lg-4 col-xl-4">
              <div className="single-blog">
                <span className="badge badge-warning">Lifestyle</span>
                <Link
                  target="_parent"
                  to="/lifestyle"
                  onClickCapture={() =>
                    ReactGA.event({
                      category: "Homepage",
                      action: "Lifestyle",
                      label: "Read More",
                    })
                  }
                  className="readmore"
                >
                  Read More
                </Link>
                {/* Display main Lifestyle blog */}
                {Lifestylelist?.length > 0 && (
                  <>
                    <Link
                      target="_parent"
                      to={`/${Lifestylelist[0]?.url}`}
                      className="main-blogs share-blog"
                    >
                      <div className="skeleton-sub">
                        <img
                          loading="lazy"
                          src={
                            !Lifestylelist?.[0]?.media
                              ? sandeshske
                              : `${assetbaseUrl?.value}${Lifestylelist?.[0]?.media.replace("?resize=800,450", "")}?resize=425,240`
                          }
                          alt={`${Lifestylelist?.[0]?.title}`}
                          className="img-responsive"
                        />
                      </div>
                      {Lifestylelist[0]?.post_type === 3 ||
                      Lifestylelist[0]?.post_type === 4 ? (
                        <p className="button-hover timer_ subvideo-tagline">
                          {Lifestylelist[0]?.time}
                        </p>
                      ) : (
                        ""
                      )}
                      <div className="blog-details">
                        <p className="ellipsis">{Lifestylelist[0]?.title}</p>
                      </div>
                      <div class="dropleft">
                        <button
                          onClick={(e) => onShareClick(e)}
                          className="dropbtn ml-2"
                        >
                          <i class="fa fa-share-alt"></i>
                        </button>
                        <div class="dropdown-menu">
                          <ShareCommon
                            id="common-share-dropdown"
                            url={Businesslist[0].url}
                            media={`${assetbaseUrl?.value}${Businesslist?.[0]?.media}`}
                            title={Businesslist[0]?.title}
                            dataid={Businesslist[0]?.id}
                          />
                        </div>
                      </div>
                    </Link>
                  </>
                )}
                {/* Display additional Lifestyle blogs */}
                <div>
                  {Lifestylelist?.length > 0 &&
                    Lifestylelist?.map((els, idx) => (
                      <>
                        {idx > 0 && idx <= 3 && (
                          <div className="row col-12 p-0 m-0">
                            <CommonContent
                              url={els?.url}
                              src={`${assetbaseUrl?.value}${els?.media.replace("?resize=800,450", "")}?resize=90,51`}
                              post_type={els?.post_type}
                              time={els?.time}
                              category={els?.tagline}
                              title={els?.title}
                            />
                          </div>
                        )}
                      </>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Blogs;