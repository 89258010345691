// Import action types related to footer data
import {
  FOOTER_ARE_LOADING,
  FOOTER_FETCH_DATA_SUCCESS,
  FOOTER_HAVE_ERROR,
} from "../actionType";

// Define the initial state for the FooterReducer
const initialState = {
  data: [],
  loading: true,
  error: [], // Initialize error as null for better error handling
};

// Reducer function to handle footer-related actions and update state accordingly
export function FooterReducer(state = initialState, action) {
  switch (action.type) {
    // Handle error case when fetching footer data
    case FOOTER_HAVE_ERROR:
      return {
        ...state,
        error: action.hasError, // Update the error state with the error from the action
        loading: false, // Set loading to false since the request is complete
      };

    // Handle loading state when footer data is being fetched
    case FOOTER_ARE_LOADING:
      return {
        ...state,
        loading: action.isLoading, // Update the loading state with the loading status from the action
      };

    // Handle success case when footer data is successfully fetched
    case FOOTER_FETCH_DATA_SUCCESS:
      return {
        ...state,
        data: action.Footer, // Update the data state with the fetched footer data
        loading: false, // Set loading to false since the request is complete
        error: null, // Clear any previous errors
      };

    // Return the current state by default if action type is not recognized
    default:
      return state;
  }
}
