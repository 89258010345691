import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CityFetchData } from "../../redux/actions/cityAction";

/**
 * LocationSelect component
 * 
 * This component is responsible for rendering a modal popup that allows users to select cities.
 * It uses Redux to fetch city data and store the selected cities in the state.
 */
const LocationSelect = (props) => {
  /**
   * Get the city data from the Redux store
   */
  const citiesName = useSelector((state) => state.CityReducer.data.data);
  
  /**
   * Get the dispatch function from Redux
   */
  const dispatch = useDispatch();
  
  /**
   * Initialize the state for the city names and active cities
   */
  const [cityName, setCityName] = useState([]);
  const [activeCity, setActiveCity] = useState([]);

  /**
   * Fetch the city data when the component mounts
   */
  useEffect(() => {
    dispatch(CityFetchData());
  }, []);

  /**
   * Update the active cities when the component updates
   */
  useEffect(() => {
    if (localStorage.getItem("activeCity")) {
      const activeCitiesName = JSON.parse(localStorage.getItem("activeCity")) || [];
      setActiveCity(activeCitiesName.map((els) => els.name));
    }
    citiesName && setCityName(citiesName);
  }, [citiesName]);

  /**
   * Handle the save city button click
   */
  const saveCityHandler = () => {
    document.getElementsByClassName("close")[0]?.click();
    const citiesURL = citiesName.filter((els) => activeCity.includes(els.name));
    props.visibleCity(citiesURL);
    searchHandler("");
    document.getElementsByClassName("searchsamgra")[0].value = "";
  };

  /**
   * Handle the city selection
   */
  const cityHandler = (cityName) => {
    if (activeCity.includes(cityName)) {
      setActiveCity(activeCity.filter((els) => els!== cityName));
    } else {
      setActiveCity([...activeCity, cityName]);
    }
  };

  /**
   * Handle the search input
   */
  const searchHandler = (searchValue) => {
    const filteredValues = citiesName.filter((els) =>
      els.name.toLowerCase().includes(searchValue.toLowerCase().trim())
    );
    setCityName(filteredValues);
  };

  return (
    <section className="popup">
      <div className="row">
        <div className="col-sm-2">
          <li className="nav-item">
            <a data-toggle="modal" data-target="#exampleModal">
              <i className="fas fa-plus plusIcon"></i>
            </a>
          </li>

          <div
            className="modal fade"
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <form>
                    <div className="row">
                      <div className="col-11">
                        <div className="form-group">
                          <input
                            onChange={(e) => searchHandler(e.target.value)}
                            type="text"
                            className="form-control searchsamgra"
                            placeholder="Find City"
                          />
                        </div>
                      </div>
                      <div className="col-1">
                        <div className="form-group has-search">
                          <span className="fa fa-search form-control-feedback"></span>
                        </div>
                      </div>
                    </div>
                  </form>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body scroll-body">
                  <nav className="nav nav-pills">
                    {cityName.length > 0 &&
                      cityName.map((els) => (
                        <a
                          key={els.name}
                          onClick={() => cityHandler(els.name)}
                          className={`text-sm-center nav-link ${activeCity.includes(els.name) && `active`}`}
                          href="javascript:void(0)"
                        >
                          {els.name}{" "}
                          {activeCity.includes(els.name) && (
                            <span className="badge badge-pill badge-primary badge-close">
                              &times;
                            </span>
                          )}
                        </a>
                      ))}
                  </nav>
                </div>
                <div className="modal-footer">
                  <button
                    onClick={saveCityHandler}
                    type="button"
                    className="btn btn-primary"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LocationSelect;