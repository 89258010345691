import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
// import sandeshlogo from '../../assets/logo11.jpg';
import sandeshlogo from "../../assets/logoog.jpg";
import ReactGA from "react-ga4";
import Login from "../pages/Login";

const Menu = () => {
  const menuList = useSelector((state) => state?.MenuReducer);
  const [menu, setMenuItems] = React.useState("");
  const [loginStateEnable, setLoginEnableState] = useState(false);
  const [myAT, setmyAT] = React.useState("");
  const tithi = useSelector((state) => state?.TithiReducer);
  const { 24: Android } = useSelector((state) => state?.SettingReducer?.data);
  const { 25: Apple } = useSelector((state) => state?.SettingReducer?.data);
  const { 46: HeaderGIFMenus } = useSelector(
    (state) => state?.SettingReducer?.data,
  );

  const [app_redirection, setapp_redirection] = React.useState(
    "/app-redirection.html",
  );
  const [headerGIF, setHeaderGIF] = useState([]);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (userAgent.includes("Android")) {
      if (Android?.value) {
        setapp_redirection(Android?.value);
      }
    } else if (userAgent.includes("iPhone")) {
      if (Apple?.value) {
        setapp_redirection(Apple?.value);
      }
    }
  }, [Android]);

  let navigate = useNavigate();
  useEffect(() => {
    setMenuItems(menuList.data);
    setHeaderGIF(
      HeaderGIFMenus?.value ? JSON.parse(HeaderGIFMenus?.value) : "",
    );
    const timer = setInterval(() => {
      if (myAT !== localStorage.getItem("access_token")) {
        setmyAT(localStorage.getItem("access_token"));
      }
    }, 500);
    return () => {
      clearInterval(timer);
    };
  }, [menuList]);

  document.addEventListener("DOMContentLoaded", function () {
    window.addEventListener("scroll", function () {
      if (document.getElementById("sticky_menu_logo")) {
        if (window.scrollY > 450) {
          document
            .getElementById("sticky_menu_logo")
            .classList.add("dis-block");
        } else {
          document
            .getElementById("sticky_menu_logo")
            .classList.remove("dis-block");
        }
      }
    });
  });

  const Logout = () => {
    localStorage.removeItem("userInfo");
    localStorage.removeItem("access_token");
    localStorage.removeItem("userID");
    localStorage.removeItem("socialLogin");
    navigate("/");
  };

  const OpenAPP = () => {
    // window.location = "Sandesh://launch";
    window.location = app_redirection;
    return false;
  };
  const showLoginModel = () => {
    setLoginEnableState(!loginStateEnable);
  };

  const checklocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(successFunction, errorFunction);
    }
  };
  function successFunction(position) {
    const lat = position.coords.latitude;
    const long = position.coords.longitude;
    localStorage.setItem(
      "SandeshLocation",
      JSON.stringify({ lat: lat, long: long }),
    );
    document.getElementById("locationCheck").style.display = "none";
  }

  function errorFunction(err) {
    // console.log('Location Error:', err.message);
    // alert('Location Error: ' + err.message);
  }

  const dropdownclick = (name) => {
    if (document.getElementById(name).classList.contains("d-block")) {
      document.getElementById(name).classList.remove("d-block");
    } else {
      document.getElementById(name).classList.add("d-block");
    }
  };
  const subdropdownclick = (name) => {
    if (document.getElementById(name).classList.contains("d-block")) {
      document.getElementById(name).classList.remove("d-block");
    } else {
      document.getElementById(name).classList.add("d-block");
    }
  };

  const [toggle, setToggle] = useState(false);
  useEffect(() => {
    document.body.style.overflow = "auto";
  }, [window.location.pathname]);
  useEffect(() => {
    const id = document.getElementById("btn-menu");
    const classContain = id.classList.contains("collapsed");
    if (classContain) {
      document.body.style.overflow = "auto";
    } else {
      document.body.style.overflow = "hidden";
    }
  }, [toggle]);

  var months = [
    "જાન્યુઆરી",
    "ફેબ્રુઆરી",
    "માર્ચ",
    "એપ્રિલ",
    "મે",
    "જૂન",
    "જુલાઈ",
    "ઓગસ્ટ",
    "સપ્ટેમ્બર",
    "ઓક્ટોબર",
    "નવેમ્બર",
    "ડિસેમ્બર",
  ];
  var now = new Date();
  var thisMonth = months[now.getMonth()];

  var newdate = new Date();
  var thisDate = newdate.getDate();

  var days = [
    "રવિવાર",
    "સોમવાર",
    "મંગળવાર",
    "બુધવાર",
    "ગુરૂવાર",
    "શુક્રવાર",
    "શનિવાર",
  ];
  var newday = new Date();
  var thisDay = days[newday.getDay()];

  var year = new Date();
  var thisYear = year.getFullYear();

  return (
    <>
      <section id="menu" className="menu webMenu">
        <div className="container col-md-12 p-0">
          <nav
            className="navbar navbar-expand-lg navbar-light bg-AE0A0B menu-nav"
            style={{ maxHeight: "41px" }}
          >
            <div
              className={
                window.innerWidth < 991
                  ? "d-flex justify-content-center w-100"
                  : ""
              }
            >
              <div className="left-menu-mobile">
                <button
                  onClick={() => setToggle(!toggle)}
                  id="btn-menu"
                  className="navbar-toggler collapsed remove-border"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </button>
                <Link
                  className="liveTVMenu"
                  onClickCapture={() =>
                    ReactGA.event({
                      category: "Header",
                      action: "Menu",
                      label: "Videos",
                    })
                  }
                  to="/videos"
                >
                  <i className="fa fa-tv live-tv-icon"></i>
                </Link>
              </div>

              <a
                className="navbar-brand"
                href="/"
                onClickCapture={() =>
                  ReactGA.event({
                    category: "Header",
                    action: "Menu",
                    label: "Logo",
                  })
                }
              >
                <img
                  loading="lazy"
                  className="sandesh-nav-logo"
                  width={100}
                  src="https://assets.sandesh.com/im/logo-b.png"
                  alt=""
                />
                <span
                  className="innerLogoText"
                  dangerouslySetInnerHTML={{
                    __html:
                      thisDay +
                      ", " +
                      thisDate +
                      " " +
                      thisMonth +
                      " " +
                      thisYear +
                      ",&nbsp;&nbsp;" +
                      tithi?.data?.text,
                  }}
                />
              </a>

              <div className="right-menu-mobile">
                {/* {
                                localStorage.getItem('access_token') ? '' : (
                                    <li className='v-hidden'><i onClick={() => { Logout() }} style={{ fontSize: '18px', padding: '0 11px', cursor: 'pointer' }} className="fas fa-power-off icon-menu-logout"></i></li>
                                )
                            } */}
                {/* <a href={app_redirection} onClick={() => { OpenAPP(app_redirection) }} target="_blank" className='open-app-title'>Open App</a> */}
                {/* <a href="#" onClick={() => { OpenAPP() }} className='open-app-title'>Open App</a> */}
                {/* {
                                localStorage.getItem('access_token') ? (
                                    <li><Link target="_parent" to='/profile' title='profile' className="signin cursor-pointer icon-menu"><i style={{ fontSize: '18px', padding: '0 5px', cursor: 'pointer' }} className=" fas fa-user"></i></Link></li>
                                ) : (
                                    <li><a onClick={showLoginModel} className="signin cursor-pointer icon-menu"><i style={{ fontSize: '18px', padding: '0 5px', cursor: 'pointer' }} className=" fas fa-user"></i></a></li>
                                )
                            } */}
                {headerGIF?.length > 0 ? (
                  headerGIF.map((el) => {
                    return (
                      <a
                        target="_blank"
                        href={el?.url}
                        onClickCapture={() =>
                          ReactGA.event({
                            category: "Header",
                            action: "Icon",
                            label: "Google News",
                          })
                        }
                        className="menu-page-social-gif-rathyatra-m" rel="noreferrer"
                      >
                        <img
                          loading="lazy"
                          style={{ width: "52px" }}
                          src={el?.mobile_img}
                          alt=""
                        />
                      </a>
                    );
                  })
                ) : (
                  <>
                    {window.innerWidth < 991 ? (
                      <div style={{ width: "52px" }} />
                    ) : (
                      ""
                    )}
                  </>
                )}
                {/* <Link target="_parent" to="/asia-cup-2022" onClickCapture={() => ReactGA.event({ category: 'Header', action: 'Icon', label: 'Monsoon' })} className='menu-page-social-gif-rathyatra-m'>
                                <img loading="lazy" style={{ width: '45px' }} src={require('../../assets/ac-m.jpeg')} alt="" />
                            </Link>
                            <Link target="_parent" to="/ganesh-chaturthi-2022" onClickCapture={() => ReactGA.event({ category: 'Header', action: 'Icon', label: 'Monsoon' })} className='menu-page-social-gif-rathyatra-m'>
                                <img loading="lazy" style={{ width: '45px' }} src={require('../../assets/gc-m.gif')} alt="" />
                            </Link> */}
                {localStorage.getItem("access_token") ? (
                  <li className="icon-menu">
                    <i
                      onClick={() => {
                        Logout();
                      }}
                      style={{
                        fontSize: "18px",
                        padding: "0 5px",
                        cursor: "pointer",
                      }}
                      className=" fas fa-power-off icon-menu-logout"
                    ></i>
                  </li>
                ) : (
                  ""
                )}
                {/* <li id='locationCheck'><a href="javascript:void(0)" onClick={checklocation} className="location icon-menu"><i style={{ fontSize: '18px', padding: '0 5px', cursor: 'pointer' }} className="fas  fa-map-marker-alt"></i></a></li> */}
              </div>
            </div>
            {/* <div id="menu-color nav-main" className="collapse navbar-collapse" data-toggle="collapse" data-target="#navbarSupportedContent.show" id="navbarSupportedContent"> */}

            <div
              className="collapse navbar-collapse"
              data-toggle="collapse"
              // data-target="#navbarSupportedContent.show"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav mr-auto">
                <li className="nav-item" id="sticky_menu_logo">
                  <a
                    href="/"
                    className="p-0"
                    onClickCapture={() =>
                      ReactGA.event({
                        category: "Header",
                        action: "Menu",
                        label: "Logo",
                      })
                    }
                  >
                    <img
                      loading="lazy"
                      alt=""
                      src={sandeshlogo}
                      className="sticky-header-logo d-none"
                    />
                  </a>
                </li>
                <li className="nav-item">
                  <Link
                    target="_parent"
                    key={Math.random()}
                    to="/"
                    onClickCapture={() =>
                      ReactGA.event({
                        category: "Header",
                        action: "Menu",
                        label: "Home",
                      })
                    }
                  >
                    {/* <i className="fas fa-home mr-1"></i> */}
                    Home
                  </Link>
                </li>
                {menu &&
                  Object.values(menu).map((els, idx) =>
                    els?.submenu?.length === 0 ? (
                      <li
                        className="nav-item"
                        onClickCapture={() =>
                          ReactGA.event({
                            category: "Header",
                            action: "Menu",
                            label: els?.name,
                          })
                        }
                      >
                        {els?.open === 1 ? (
                          <a
                            key={Math.random()}
                            className="dropdown"
                            target="_blank"
                            href={`${els?.url}`}
                            rel="noreferrer"
                          >
                            {els?.name}{" "}
                            {`${els?.submenu}` ? (
                              <i className="fas fa-sort-down"></i>
                            ) : null}
                          </a>
                        ) : (
                          <Link
                            target="_parent"
                            key={Math.random()}
                            className="dropdown"
                            to={`/${els?.category}`}
                          >
                            {els?.name}{" "}
                            {`${els?.submenu}` ? (
                              <i className="fas fa-sort-down"></i>
                            ) : null}
                          </Link>
                        )}
                      </li>
                    ) : (
                      <li
                        className="nav-item"
                        onClickCapture={() =>
                          ReactGA.event({
                            category: "Header",
                            action: "Menu",
                            label: els?.name,
                          })
                        }
                      >
                        <a
                          className="nav-link d-flex justify-center align-items-center p-0"
                          role="button"
                        >
                          <Link target="_parent" to={`${els?.category}`}>
                            {els?.name}{" "}
                          </Link>
                          <i
                            className="fas fa-sort-down dd-icon-menu-padding"
                            onClick={() => dropdownclick(els?.name)}
                          ></i>
                        </a>
                        <ul id={els?.name} className="dropdown-menu p-0">
                          {Object.values(els?.submenu).map((els, idx) =>
                            els?.submenu?.length === 0 ? (
                              <li
                                onClickCapture={() =>
                                  ReactGA.event({
                                    category: "Header",
                                    action: "Menu",
                                    label: els?.name,
                                  })
                                }
                              >
                                <span className="">
                                  {els?.open === 1 ? (
                                    <a
                                      key={Math.random()}
                                      className="dropdown-item left-side"
                                      target="_blank"
                                      href={`${els?.url}`} rel="noreferrer"
                                    >
                                      {els?.name}{" "}
                                      {`${els?.submenu}` ? (
                                        <i className="fas fa-caret-right"></i>
                                      ) : null}
                                    </a>
                                  ) : (
                                    <Link
                                      key={Math.random()}
                                      className="w-100 d-block"
                                      to={`${els?.category}`}
                                    >
                                      {els?.name}
                                    </Link>
                                  )}
                                </span>
                              </li>
                            ) : (
                              <li
                                onClickCapture={() =>
                                  ReactGA.event({
                                    category: "Header",
                                    action: "Menu",
                                    label: els?.name,
                                  })
                                }
                              >
                                <span className="">
                                  {els?.open === 1 ? (
                                    <a
                                      key={Math.random()}
                                      className="dropdown-item left-side"
                                      target="_blank"
                                      href={`${els?.url}`} rel="noreferrer"
                                    >
                                      {els?.name}{" "}
                                      {`${els?.submenu}` ? (
                                        <i className="fas fa-caret-right"></i>
                                      ) : null}
                                    </a>
                                  ) : (
                                    <span className="d-flex align-items-baseline p-0">
                                      <div>
                                        <Link
                                          key={Math.random()}
                                          className="w-100"
                                          to={`${els?.category}`}
                                        >
                                          {els?.name}
                                        </Link>
                                      </div>
                                      <span>
                                        {`${els?.submenu}` ? (
                                          <i
                                            className="fas fa-sort-down dd-icon-menu-padding"
                                            onClick={() =>
                                              subdropdownclick(els?.name)
                                            }
                                            style={{
                                              color: "#fff",
                                              cursor: "pointer",
                                            }}
                                          ></i>
                                        ) : null}
                                      </span>
                                    </span>
                                  )}
                                </span>
                                <ul
                                  id={els?.name}
                                  className="dropdown-menu dropdown-submenu p-0"
                                >
                                  {Object.values(els?.submenu).map(
                                    (els, idx) => (
                                      <li
                                        onClickCapture={() =>
                                          ReactGA.event({
                                            category: "Header",
                                            action: "Menu",
                                            label: els?.name,
                                          })
                                        }
                                      >
                                        <span className="dropdown-item">
                                          {els?.open === 1 ? (
                                            <a
                                              key={Math.random()}
                                              className="dropdown-item left-side"
                                              target="_blank"
                                              href={`${els?.url}`} rel="noreferrer"
                                            >
                                              {els?.name}{" "}
                                              {`${els?.submenu}` ? (
                                                <i className="fas fa-sort-down"></i>
                                              ) : null}
                                            </a>
                                          ) : (
                                            <Link
                                              key={Math.random()}
                                              className="dropdown-item left-side"
                                              to={`${els?.category}`}
                                            >
                                              {els?.name}{" "}
                                              {`${els?.submenu}` ? (
                                                <i className="fas fa-sort-down"></i>
                                              ) : null}
                                            </Link>
                                          )}

                                          {/* <Link key={Math.random()} className="dropdown-item left-side" to={els.open === 1 ? `${els?.url}` : ''}>{els?.name} {`${els?.submenu}` ? <i className="fas fa-sort-down"></i> : ''}</Link> */}
                                        </span>
                                        <ul className="dropdown-menu dropdown-submenu p-0">
                                          {Object.values(els?.submenu).map(
                                            (els, idx) => (
                                              <li
                                                onClickCapture={() =>
                                                  ReactGA.event({
                                                    category: "Header",
                                                    action: "Menu",
                                                    label: els?.name,
                                                  })
                                                }
                                              >
                                                <span className="dropdown-item">
                                                  {els?.open === 1 ? (
                                                    <a
                                                      key={Math.random()}
                                                      className="dropdown-item left-side"
                                                      target="_blank"
                                                      href={`${els?.url}`} rel="noreferrer"
                                                    >
                                                      {els?.name}{" "}
                                                      {`${els?.submenu}` ? (
                                                        <i className="fas fa-sort-down"></i>
                                                      ) : null}
                                                    </a>
                                                  ) : (
                                                    <Link
                                                      key={Math.random()}
                                                      className="dropdown-item left-side"
                                                      to={`${els?.category}`}
                                                    >
                                                      {els?.name}{" "}
                                                      {`${els?.submenu}` ? (
                                                        <i className="fas fa-sort-down"></i>
                                                      ) : null}
                                                    </Link>
                                                  )}
                                                </span>
                                                <ul className="dropdown-menu dropdown-submenu p-0">
                                                  {Object.values(
                                                    els?.submenu,
                                                  ).map((els, idx) => (
                                                    <li
                                                      onClickCapture={() =>
                                                        ReactGA.event({
                                                          category: "Header",
                                                          action: "Menu",
                                                          label: els?.name,
                                                        })
                                                      }
                                                    >
                                                      <a
                                                        className="dropdown-item"
                                                        href="#"
                                                      >
                                                        {els?.open === 1 ? (
                                                          <a
                                                            key={Math.random()}
                                                            className="dropdown-item left-side"
                                                            target="_blank"
                                                            href={`${els?.url}`} rel="noreferrer"
                                                          >
                                                            {els?.name}
                                                          </a>
                                                        ) : (
                                                          <Link
                                                            key={Math.random()}
                                                            className="dropdown-item left-side"
                                                            to={`${els?.category}`}
                                                          >
                                                            {els?.name}
                                                          </Link>
                                                        )}
                                                      </a>
                                                    </li>
                                                  ))}
                                                </ul>
                                              </li>
                                            ),
                                          )}
                                        </ul>
                                      </li>
                                    ),
                                  )}
                                </ul>
                              </li>
                            ),
                          )}
                        </ul>
                      </li>
                    ),
                  )}

                <li
                  className="rightsite-epaper"
                  onClickCapture={() =>
                    ReactGA.event({
                      category: "Header",
                      action: "Menu",
                      label: "E-Paper",
                    })
                  }
                >
                  <Link target="_blank" className="dropdown1" to="/epaper">
                    E-Paper
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </section>
      {loginStateEnable && <Login />}
    </>
  );
};

export default Menu;
