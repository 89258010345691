import React from "react";
import { useNavigate } from "react-router-dom";
import Share from "./component/Share";

const CommonHeadCardContent = ({ url, mediaimg, sandeshske, title, time, post_type, id, dataClass, showShareDropdown, setShowShareDropdown }) => {
  const navigate = useNavigate();

  const handleShareClick = () => {
    setShowShareDropdown(true); // Update state directly
  };

  const hasMedia = mediaimg || sandeshske; // Use short-circuiting for media check

  return (
    <div className={`img-in-txt ${dataClass}`} onClick={() => navigate(`/${url}`)}>
      <div className="skeleton-sub">
        <img
          loading="lazy"
          src={hasMedia ? (mediaimg || sandeshske) : sandeshske} // Use ternary for media
          alt={title}
          key={Math.random()}
        />
      </div>
      {post_type === 3 || post_type === 4 ? (
        <p className="button-hover timer_ subvideo-tagline">{time}</p>
      ) : null}
      <div className="content">
        <div className="blog-details pt-2 px-0 pb-0 font-size-gujrat-title common-card-content-title">
          <h6 className="ellipsis mb-2">{title}</h6>
          <div className="cap-post-head-business px-0 mb-0 row d-flex justify-content-between">
            <a>
              <div className="dropup">
                <button onClick={handleShareClick} className="dropbtn ml-2">
                  <i className="fas fa-share"></i> Share
                </button>
                {showShareDropdown && (
                  <div className="dropup-content">
                    <Share
                      id="common-share-dropdown"
                      dataid={id}
                      url={url}
                      title={title}
                      media={hasMedia ? (mediaimg || sandeshske) : null} // Handle media in Share component
                    />
                  </div>
                )}
              </div>
            </a>
            <a className="px-2">
              <i className="icone-color-white far fa-clock"></i> Placeholder for publish date
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommonHeadCardContent;
