// Import action types related to settings
import {
  SETTING_ARE_LOADING,
  SETTING_FETCH_DATA_SUCCESS,
  SETTING_HAVE_ERROR,
} from "../actionType";

// Get the CDN URL from local storage
const cdnUrl = localStorage.getItem("SandeshCDN");
// Define default assets with CDN URL fallback
const defaultAssets = {
  21: { meta: "Media CDN", value: cdnUrl || "https://assets.sandesh.com/" },
};
// Define the initial state for the Setting reducer
const initialState = {
  data: defaultAssets, // Initialize data with default assets
  loading: true, // Set loading to true initially
  error: [], // Initialize error as an empty array
};

// Reducer function to handle setting-related actions and update state accordingly
export function SettingReducer(state = initialState, action) {
  switch (action.type) {
    // Handle error case when fetching settings
    case SETTING_HAVE_ERROR:
      return {
        ...state,
        error: action.hasError, // Update the error state with the error from the action
        loading: false, // Set loading to false since the request is complete
      };

    // Handle loading state when settings are being fetched
    case SETTING_ARE_LOADING:
      return {
        ...state,
        loading: action.isLoading, // Update the loading state with the loading status from the action
      };

    // Handle success case when settings are successfully fetched
    case SETTING_FETCH_DATA_SUCCESS:
      return {
        ...state,
        data: action.Setting, // Update the data state with the fetched settings
        loading: false, // Set loading to false since the request is complete
        error: [], // Clear any previous errors (assuming the success case means no error)
      };

    // Return the current state by default if action type is not recognized
    default:
      return state;
  }
}
