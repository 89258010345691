import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/logoog.jpg";
import { profileFetchData } from "../../redux/actions/commonAction";
import { searchValue } from "../../redux/actions/searchValue";
import HelperScript from "../../utils/helper";
import Login from "../pages/Login";

const Header = (props) => {
  const [loginStateEnable, setLoginEnableState] = useState(false);
  const [userName, setUserName] = useState("");
  const [login, setlogin] = useState("");
  const tithi = useSelector((state) => state?.TithiReducer);
  const showLoginModel = () => {
    setLoginEnableState(!loginStateEnable);
  };
  let navigate = useNavigate();

  let dispatch = useDispatch();
  var months = [
    "જાન્યુઆરી",
    "ફેબ્રુઆરી",
    "માર્ચ",
    "એપ્રિલ",
    "મે",
    "જૂન",
    "જુલાઈ",
    "ઓગસ્ટ",
    "સપ્ટેમ્બર",
    "ઓક્ટોબર",
    "નવેમ્બર",
    "ડિસેમ્બર",
  ];
  var now = new Date();
  var thisMonth = months[now.getMonth()];

  var newdate = new Date();
  var thisDate = newdate.getDate();

  var days = [
    "રવિવાર",
    "સોમવાર",
    "મંગળવાર",
    "બુધવાર",
    "ગુરૂવાર",
    "શુક્રવાર",
    "શનિવાર",
  ];
  var newday = new Date();
  var thisDay = days[newday.getDay()];

  var year = new Date();
  var thisYear = year.getFullYear();


  useEffect(() => {
    const timerId = setTimeout(checklocation, 1000);
    return function cleanup() {
      clearInterval(timerId);
    };
  }, []);

  const [InputSearchValue, setInputSearchValue] = useState("");

  const checklocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(successFunction, errorFunction);
    }
  };

  function successFunction(position) {
    const lat = position.coords.latitude;
    const long = position.coords.longitude;
    localStorage.setItem(
      "SandeshLocation",
      JSON.stringify({ lat: lat, long: long }),
    );
    const locationCheck = document.getElementById("locationCheck");
    if (locationCheck) locationCheck.style.display = "none";
  }

  function errorFunction(err) {
    console.log("Location Error:", err);
    const locationCheck = document.getElementById("locationCheck");
    if (locationCheck) locationCheck.style.display = "none";
  }

  useEffect(() => {
    const timer = setInterval(() => {
      dispatch(searchValue(InputSearchValue));
      if (InputSearchValue) {
        navigate("/search");
      }
    }, 3000);
    return () => {
      clearInterval(timer);
    };
  }, [InputSearchValue]);

  const Logout = () => {
    localStorage.removeItem("userInfo");
    localStorage.removeItem("access_token");
    localStorage.removeItem("userID");
    localStorage.removeItem("socialLogin");
    setUserName("");
    setlogin("clear");
    if (window.location.pathname === "/profile") {
      navigate("/");
    } else {
      navigate(window.location.pathname);
    }
  };
  if (localStorage.getItem("access_token") !== null) {
    dispatch(profileFetchData()).then((res) => {
      if (res) {
        setUserName(res?.user_data?.name);
      } else {
        setUserName("");
      }
    });
  }
  useEffect(() => {
    if (localStorage.getItem("access_token")) {
      setlogin("success");
    } else {
      setlogin("clear");
    }
  });

  const success = (success) => {
    setlogin(success);
  };

  const headerweb =
    "" +
    "<div id='div-gpt-ad-1646031348333-0' style='min-width: 728px; min-height: 90px;'> " +
    "<script>" +
    "window.googletag = window.googletag || { cmd: [] };" +
    "googletag.cmd.push(function () {" +
    "googletag.defineSlot('/22678428348/Test_HP_header_728X90', [728, 90], 'div-gpt-ad-1646031348333-0').addService(googletag.pubads());" +
    "googletag.pubads().enableSingleRequest();" +
    "googletag.pubads().disableInitialLoad();" +
    "googletag.enableServices();" +
    "});" +
    "</script>" +
    "" +
    "<script>" +
    "  googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646031348333-0'); });" +
    "</script>" +
    "</div>" +

    "" +
    "";
  return (
    <>
      <div className="text-center">
        <div className="ad-section">
          <HelperScript addstyle={1} argSmall={164} argLarge={2} />
        </div>
      </div>
      <section className="header">
        <div className="topbar">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex justify-content-between">
                  <div className="d-flex align-items-center">
                    {
                      <div className="time" id="demo">
                        <span className="date-top-header">
                          {thisDay}, {thisDate} {thisMonth}, {thisYear}{" "}
                        </span>
                        <span
                          className="ml-3"
                          dangerouslySetInnerHTML={{
                            __html: tithi?.data?.text,
                          }}
                        />

                      </div>
                    }
                    <a
                      href="/epaper"
                      target="_blank"
                      className="e-paper-header"
                    >
                      E-PAPER
                    </a>{" "}

                    <div className="contacts">

                    </div>
                  </div>
                  <div>
                    <div>
                      <div className="rightbar">
                        <ul>
                          {login === "success" &&
                            localStorage.getItem("access_token") ? (
                            // ''
                            <li>
                              <Link
                                target="_parent"
                                to="/profile"
                                title="profile"
                                className="signin signin-user-icon cursor-pointer"
                              >
                                <i className="fas fa-user"></i>
                              </Link>
                            </li>
                          ) : (
                            <li>
                              <a
                                onClick={showLoginModel}
                                className="signin cursor-pointer"
                              >
                                <i className="fas fa-user"></i>
                              </a>
                            </li>
                          )}
                          {login === "success" ? (
                            <li>
                              <Link
                                target="_parent"
                                to="/profile"
                                title="profile"
                                className="signin cursor-pointer"
                              >
                                Hi,{" "}
                                {userName?.length
                                  ? userName.split(" ")[0]
                                  : "There"}
                              </Link>
                            </li>
                          ) : (
                            ""
                          )}

                          {login === "success" &&
                            localStorage.getItem("access_token") ? (
                            <li>
                              <i
                                onClick={() => {
                                  Logout();
                                }}
                                style={{
                                  fontSize: "18px",
                                  padding: "9px",
                                  cursor: "pointer",
                                }}
                                className="fas fa-power-off icon-header-logout"
                              ></i>
                            </li>
                          ) : (
                            ""
                          )}
                          <li id="locationCheck">
                            <a
                              href="javascript:void(0)"
                              onClick={checklocation}
                              className="location"
                            >
                              <i className="fas fa-map-marker-alt"></i>
                            </a>
                          </li>
                          <li>
                            <div className="d-flex">
                              <div
                                className="collapse fade indextab"
                                id="searchForm"
                              >
                                <input
                                  value={InputSearchValue}
                                  onChange={(e) =>
                                    setInputSearchValue(e.target.value)
                                  }
                                  type="search"
                                  className="form-control border-0 bg-light"
                                  placeholder="search"
                                />
                              </div>
                              <Link
                                target="_parent"
                                to="/search"
                                className="nav-link ml-auto"
                                onClickCapture={() =>
                                  ReactGA.event({
                                    category: "Header",
                                    action: "Search",
                                    label: "Clicked",
                                  })
                                }
                              >
                                <i className="fas fa-search"></i>
                              </Link>
                            </div>
                          </li>
                          { }
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="logo">
          <div className="container">
            <div className="row ">
              <div className="col-md-4">
                <a href="/" className="brand">
                  <img
                    loading="lazy"
                    style={{ filter: "none", width: "263px" }}
                    src={logo}
                    alt=""
                  />
                </a>
              </div>

              <div className="col-md-8">
                <div className="row">
                  <div
                    className="col-md-10 ad-section"
                    style={{ marginRight: "auto", marginLeft: "-23px" }}
                  >
                    <HelperScript
                      argSmall={165}
                      argLarge={3}
                      key={window.location.pathname}
                    />

                  </div>
                  <div className="col-md-2 qr-code-app text-center">
                    <a href="/d" target="_blank">
                      <img
                        loading="lazy"
                        src={require("../../assets/qrcode-app.png")}
                        alt=""
                      />
                    </a>
                    <a href="/d" target="_blank">
                      <span>Download App</span>
                    </a>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>
      {loginStateEnable && <Login success={success} />}
    </>
  );
};

export default Header;
