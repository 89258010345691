import React, { useState } from "react";
import $ from "jquery";
import { useDispatch } from "react-redux";
import {
  profileFetchData,
  profileUpdateData,
} from "../../redux/actions/commonAction";

function Profile() {
  const currentDate = new Date().toISOString().slice(0, 10).replace(/-/g, "-");
  const token = document.body.getAttribute("data-token");
  const [user, setUser] = useState({
    userName: "",
    userEmail: "",
    userAddress: "",
    userMobileNumber: "",
    userDob: "",
    userGender: "",
    Residencetype: "",
    FatherName: "",
    FatherNumber: "",
    spousename: "",
    spousemobile: "",
    Relationshipstatus: "",
    FamilyMemberCount: "",
    anniversarydate: "",
    token: localStorage.getItem("SandeshToken"),
  });
  const dispatch = useDispatch();
  const [message, setMessage] = useState();
  const [loading, setLoading] = useState(true);
  React.useEffect(() => {
    $("#rstatus").change(function () {
      if (this.value === "3") {
        $("#infoshow").show();
      } else {
        $("#infoshow").hide();
      }
    });
    if (localStorage.getItem("access_token") !== null) {
      dispatch(profileFetchData()).then((res) => {
        if (res) {
          setLoading(false);
          setUser({
            ...user,
            userName: res?.user_data?.name,
            userEmail: res?.user_data?.email,
            userAddress: res?.user_data?.address,
            userMobileNumber: res?.user_data?.mobile,
            userDob: res?.user_data?.dob,
            userGender: +res?.user_data?.gender,
            Residencetype: res?.user_data?.residence_type,
            FatherName: res?.user_data?.father_name,
            FatherNumber: res?.user_data?.father_mobile,
            spousename: res?.user_data?.spouse_name,
            spousemobile: res?.user_data?.spouse_mobile,
            Relationshipstatus: res?.user_data?.relationship_status,
            FamilyMemberCount: res?.user_data?.family_members,
            anniversarydate: res?.user_data?.anniversary_date,
            gender: +res?.user_data?.gender,
            token: localStorage.getItem("SandeshToken"),
          });
        } else {
          setLoading(true);
        }
      });
    }

    document.body.classList.add("profile-page");
    return () => {
      document.body.classList.remove("profile-page");
    };
  }, []);
  const updateProfile = async (e) => {
    e.preventDefault();
    const response = await dispatch(profileUpdateData(user));
    // dispatch(profileUpdateData(user));
    if (response) setMessage(response?.message);
    setTimeout(() => {
      setMessage(undefined);
    }, 5000);
  };

  const uncheck = () => {
    document.getElementById("rent").checked = false;
    document.getElementById("own").checked = false;
    setUser({ Residencetype: "none" });
  };

  const maxLengthCheck = (e) => {
    if (e.target.value.length > e.target.maxLength) {
      e.target.value = e.target.value.slice(0, e.target.maxLength);
    }
  };

  const date = new Date();
  date.setFullYear(date.getFullYear() - 16);
  var mindates = date.toISOString().slice(0, 10);

  return (
    <>
      <section
        className={`profile ${loading === true ? "profile_opacity" : ""}`}
      >
        <div className="container">
          <div className="row my-5">
            <div className="col-md-10">
              <div className="sidebar">
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      href="#pro"
                      data-toggle="tab"
                    >
                      Profile Details
                    </a>
                  </li>
                  {/* <li className="nav-item">
                                        <a className="nav-link" href="#detail" data-toggle="tab">Additional Details</a>
                                    </li> */}
                </ul>
                <div className="tab-content clearfix">
                  <div className="tab-pane active" id="pro">
                    <div className="row bb">
                      <div className="col-lg-12">
                        <div className="mainhd">
                          <span className="color">Profile Details</span>
                        </div>
                        <form>
                          <div className="form-group">
                            <input
                              type="text"
                              value={user?.userName}
                              onChange={(e) =>
                                setUser({ ...user, userName: e.target.value })
                              }
                              className="form-control txtfield"
                              id="txtuser123"
                              placeholder="Name"
                              required
                            />
                          </div>
                          <div className="form-group">
                            {localStorage.getItem("socialLogin") ===
                              "Facebook" ||
                            localStorage.getItem("socialLogin") === "Google" ? (
                              <input
                                type="email"
                                value={user?.userEmail}
                                disabled
                                onChange={(e) =>
                                  setUser({
                                    ...user,
                                    userEmail: e.target.value,
                                  })
                                }
                                className="form-control txtfield"
                                id="email"
                                placeholder="Email"
                                required
                              />
                            ) : (
                              <input
                                type="email"
                                value={user?.userEmail}
                                disabled
                                onChange={(e) =>
                                  setUser({
                                    ...user,
                                    userEmail: e.target.value,
                                  })
                                }
                                className="form-control txtfield"
                                id="email"
                                placeholder="Email"
                                required
                              />
                            )}
                          </div>
                          <div className="form-group">
                            <input
                              type="number"
                              maxLength={11}
                              onInput={maxLengthCheck}
                              value={user?.userMobileNumber}
                              onChange={(e) =>
                                setUser({
                                  ...user,
                                  userMobileNumber: e.target.value,
                                })
                              }
                              className="form-control txtfield"
                              id="mobile"
                              placeholder="Mobile"
                              required
                            />
                          </div>
                          <div className="form-group">
                            {/* <input type="text" className="form-control txtfield textbox-n" id="txtbirthday" placeholder="Birthdate" onFocus="(this.type='date')" onblur="if(this.value==''){this.type='text'}" id="date" /> */}

                            <input
                              placeholder="Birthdate"
                              max={mindates}
                              value={user?.userDob}
                              onChange={(e) =>
                                setUser({ ...user, userDob: e.target.value })
                              }
                              style={{ boxShadow: "none" }}
                              type="date"
                              id="date-picker-example"
                              className="form-control datepicker date_input bdate"
                            ></input>
                          </div>
                          <div
                            className="form-group txtfield"
                            onChange={(e) =>
                              setUser({ ...user, userGender: e.target.value })
                            }
                          >
                            <label className="form-check-label">Gender :</label>
                            <div className="custom-control custom-radio">
                              {user?.userGender === 1 ? (
                                <input
                                  type="radio"
                                  id="customRadio1"
                                  checked
                                  value={1}
                                  name="gender"
                                  className="custom-control-input"
                                />
                              ) : (
                                <input
                                  type="radio"
                                  id="customRadio1"
                                  value={1}
                                  name="gender"
                                  className="custom-control-input"
                                />
                              )}
                              <label
                                className="custom-control-label"
                                for="customRadio1"
                              >
                                Male
                              </label>
                            </div>

                            <div className="custom-control custom-radio">
                              {user?.userGender === 2 ? (
                                <input
                                  type="radio"
                                  id="customRadio2"
                                  checked
                                  value={2}
                                  name="gender"
                                  className="custom-control-input"
                                />
                              ) : (
                                <input
                                  type="radio"
                                  id="customRadio2"
                                  value={2}
                                  name="gender"
                                  className="custom-control-input"
                                />
                              )}
                              <label
                                className="custom-control-label"
                                for="customRadio2"
                              >
                                Female
                              </label>
                            </div>
                            {/* {console.log('first ghg', typeof user?.gender)} */}
                            <div className="custom-control custom-radio">
                              {user?.userGender === 3 ? (
                                <input
                                  type="radio"
                                  id="customRadio3"
                                  checked
                                  value={3}
                                  name="gender"
                                  className="custom-control-input"
                                />
                              ) : (
                                <input
                                  type="radio"
                                  id="customRadio3"
                                  value={3}
                                  name="gender"
                                  className="custom-control-input"
                                />
                              )}
                              <label
                                className="custom-control-label"
                                for="customRadio3"
                              >
                                Other
                              </label>
                            </div>
                          </div>

                          <button
                            type="button"
                            onClick={(e) => {
                              updateProfile(e);
                            }}
                            className="btn btn-primary btn-lg btn-block BlueButton"
                          >
                            Complete Profile
                          </button>
                          {message && (
                            <span
                              class="msg-error error"
                              style={{
                                color: "green",
                                display: "block",
                                textAlign: "center",
                              }}
                            >
                              {message}
                            </span>
                          )}
                          {/* <button onClick={(e) => {updateProfile(e)}} type="submit" className="btn btn-primary btn-lg btn-block BlueButton">Complete Profile</button> */}
                          {/* <div><br />
                                                        <label className="TxtFeildBottomLeft form-check-label mt-2" for="exampleCheck1"> Fill in the fields above for seamless access</label>
                                                    </div> */}
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane" id="detail">
                    <div className="row bb">
                      <div className="col-lg-12">
                        <div className="mainhd">
                          <span className="color">Additional Details</span>
                        </div>
                        <form>
                          <div className="form-group">
                            <textarea
                              className="form-control"
                              value={user?.userAddress}
                              onChange={(e) =>
                                setUser({
                                  ...user,
                                  userAddress: e.target.value,
                                })
                              }
                              id="Address"
                              rows="3"
                              placeholder="Address"
                            ></textarea>
                          </div>
                          <div
                            className="form-group txtfield d-flex"
                            onChange={(e) =>
                              setUser({
                                ...user,
                                Residencetype: e.target.value,
                              })
                            }
                          >
                            <label className="form-check-label">
                              Resident type
                            </label>
                            <div className="custom-control custom-radio custom-control-inline">
                              {user?.Residencetype === "Rent" ? (
                                <input
                                  type="radio"
                                  id="rent"
                                  checked
                                  value="Rent"
                                  name="rtype"
                                  className="custom-control-input"
                                />
                              ) : (
                                <input
                                  type="radio"
                                  id="rent"
                                  value="Rent"
                                  name="rtype"
                                  className="custom-control-input"
                                />
                              )}
                              <label
                                className="custom-control-label"
                                for="rent"
                              >
                                Rent
                              </label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                              {user?.Residencetype === "Own" ? (
                                <input
                                  type="radio"
                                  id="own"
                                  checked
                                  value="Own"
                                  name="rtype"
                                  className="custom-control-input"
                                />
                              ) : (
                                <input
                                  type="radio"
                                  id="own"
                                  value="Own"
                                  name="rtype"
                                  className="custom-control-input"
                                />
                              )}
                              <label className="custom-control-label" for="own">
                                Own
                              </label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline p-0 margin-icone-close">
                              <span onClick={() => uncheck()}>
                                {" "}
                                <i className="fas fa-times"></i>
                              </span>
                            </div>
                          </div>

                          <div className="form-group">
                            <input
                              type="text"
                              value={user?.FatherName}
                              onChange={(e) =>
                                setUser({ ...user, FatherName: e.target.value })
                              }
                              className="form-control txtfield"
                              id="faname"
                              placeholder="Father Name"
                            />
                          </div>
                          <div className="form-group">
                            <input
                              type="number"
                              maxLength={11}
                              onInput={maxLengthCheck}
                              value={user?.FatherNumber}
                              onChange={(e) =>
                                setUser({
                                  ...user,
                                  FatherNumber: e.target.value,
                                })
                              }
                              className="form-control txtfield"
                              id="fanum"
                              placeholder="Father Number"
                            />
                          </div>
                          <div className="form-group txtfield">
                            <select
                              className="form-control"
                              id="rstatus"
                              value={user?.Relationshipstatus}
                              onChange={(e) =>
                                setUser({
                                  ...user,
                                  Relationshipstatus: e.target.value,
                                })
                              }
                            >
                              <option value="1" className="ddd">
                                Relationship status
                              </option>
                              <option value="2" className="ddd">
                                Single
                              </option>
                              <option value="3">Married</option>
                              <option value="4">Divorced</option>
                              <option value="5">Widowed</option>
                            </select>
                          </div>
                          {user.Relationshipstatus === "3" ? (
                            <div id="infoshow">
                              <div className="form-group">
                                <input
                                  type="text"
                                  value={user?.spousename}
                                  onChange={(e) =>
                                    setUser({
                                      ...user,
                                      spousename: e.target.value,
                                    })
                                  }
                                  className="form-control txtfield"
                                  id="spname"
                                  placeholder="Spouse name"
                                />
                              </div>
                              <div className="form-group">
                                <input
                                  type="number"
                                  min="1000000000"
                                  max="9999999999"
                                  maxLength={11}
                                  onInput={maxLengthCheck}
                                  value={user?.spousemobile}
                                  onChange={(e) =>
                                    setUser({
                                      ...user,
                                      spousemobile: e.target.value,
                                    })
                                  }
                                  className="form-control txtfield"
                                  id="spnum"
                                  placeholder="Spouse number"
                                />
                              </div>
                              <div className="form-group">
                                <input
                                  placeholder="Anniversary date"
                                  max={currentDate}
                                  value={user?.anniversarydate}
                                  onChange={(e) =>
                                    setUser({
                                      ...user,
                                      anniversarydate: e.target.value,
                                    })
                                  }
                                  style={{ boxShadow: "none" }}
                                  type="date"
                                  id="date-picker-example"
                                  className="form-control datepicker date_input"
                                ></input>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="form-group txtfield">
                            <select
                              className="form-control"
                              id="exampleFormControlSelect1"
                              value={user?.FamilyMemberCount}
                              onChange={(e) =>
                                setUser({
                                  ...user,
                                  FamilyMemberCount: e.target.value,
                                })
                              }
                            >
                              <option value="0">Family Member Count</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                            </select>
                          </div>

                          <button
                            type="button"
                            onClick={(e) => {
                              updateProfile(e);
                            }}
                            className="btn btn-primary btn-lg btn-block BlueButton"
                          >
                            Complete Profile
                          </button>
                          <div>
                            <br />
                            <label
                              className="TxtFeildBottomLeft form-check-label mt-2"
                              for="exampleCheck1"
                            >
                              {" "}
                              Fill in the fields above for seamless access
                            </label>
                          </div>
                          {message && (
                            <span
                              class="msg-error error "
                              style={{
                                color: "green",
                                display: "block",
                                textAlign: "center",
                              }}
                            >
                              {message}
                            </span>
                          )}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Profile;
