import React from "react";
import ReactGA from "react-ga4";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CommonCardContent from "../CommonCardContent";

// Define the Entertainment component
function Entertainment() {
  // Get asset base URL from Redux state
  const { 21: assetbaseUrl } = useSelector(
    (state) => state?.SettingReducer?.data,
  );

  // Get Entertainment data from Redux state
  const Entertainment = useSelector(
    (state) => state?.HomeSecondaryReducer?.data?.Entertainment,
  );

  // Define responsive settings for carousel
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1200 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 1200, min: 991 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 991, min: 515 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 515, min: 0 },
      items: 1,
    },
  };

  // Render the entertainment section
  return (
    <>
      <section className="trending entertainment bg-gray">
        <div className="container">
          <span className="badge badge-danger">Entertainment</span>
          <Link
            // Track click event with Google Analytics
            onClickCapture={() =>
              ReactGA.event({
                category: "Homepage",
                action: "Entertainment",
                label: "Read More",
              })
            }
            to="/entertainment"
            className="readmore"
          >
            Read More
          </Link>

          <div className="row trendingheight">
            <div className="col-md-12">
              <Carousel
                // Configure carousel settings
                swipeable={true}
                draggable={false}
                infinite={false}
                showDots={false}
                autoPlay={false}
                responsive={responsive}
                autoPlaySpeed={5000000}
                renderArrowsWhenDisabled={true}
                customTransition="transform 500ms ease"
                ssr={true}
                className="gallery-sec"
              >
                {Entertainment?.length > 0 &&
                  Entertainment?.map((els) => {
                    return (
                      // Render CommonCardContent component for each item
                      <CommonCardContent
                        url={els?.url}
                        src={`${assetbaseUrl?.value}${els?.media.replace("?resize=800,450", "")}?resize=256,144`}
                        post_type={els?.post_type}
                        time={els?.time}
                        publish_date={els?.publish_date}
                        category={els?.category}
                        title={els?.title}
                      />
                    );
                  })}
              </Carousel>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Entertainment;