import {
  CATEGORY_FETCH_DATA_SUCCESS,
  CATEGORY_HAVE_ERROR,
  CATEGORY_ARE_LOADING,
} from "../actionType";

import HttpService from "../../utils/httpService";
import { Post, Get } from "../../services/api.service";

// Action creator function to create actions for category data
export function CategoryAction(action, payload) {
  switch (action) {
    case CATEGORY_HAVE_ERROR:
      return {
        type: action,
        hasError: payload,
      };

    case CATEGORY_ARE_LOADING:
      return {
        type: action,
        isLoading: payload,
      };

    case CATEGORY_FETCH_DATA_SUCCESS:
      return {
        type: action,
        Category: payload,
      };

    default:
      return; // Return undefined by default if action type is not recognized
  }
}

// Async action creator function to fetch category data from the server
export function CategoryFetchData(type = "", payload = {}, params = "") {
  return (dispatch) => {
    let myurl = HttpService.category + params;
    // Determine the API URL based on the type of category data being fetched
    if (type === "tag") {
      myurl = HttpService.tag;
    }
    if (type === "location") {
      myurl = HttpService.categoryLocation;
    }
    if (type === "category") {
      myurl = HttpService.category;
    }
    if (type === "ganesh") {
      myurl = HttpService.ganesh;
    }
    try {
      return new Promise((resolve, reject) => {
        dispatch(CategoryAction(CATEGORY_ARE_LOADING, true)); // Dispatch loading action
        if (type === "location") {
          // If fetching location-based category data, use POST method
          Post(myurl, payload).then(
            (result) => {
              // Dispatch success action with the fetched data
              const obje = {
                ...result,
                ...result?.data,
                count: result?.count,
                name: result?.name,
                message: result?.message,
              };
              dispatch(CategoryAction(CATEGORY_FETCH_DATA_SUCCESS, obje));
              resolve(result);
            },
            (error) => {
              reject(error); // Reject promise if there is an error
            }
          );
        } else {
          // Otherwise, use GET method for fetching category data
          Get(myurl + params).then(
            (result) => {
              // Dispatch success action with the fetched data
              const obje = {
                ...result,
                ...result?.data,
                count: result?.count,
                name: result?.name,
                message: result?.message,
              };
              dispatch(CategoryAction(CATEGORY_FETCH_DATA_SUCCESS, obje));
              resolve(result);
            },
            (error) => {
              reject(error); // Reject promise if there is an error
            }
          );
        }
      });
    } catch (error) {
      dispatch(CategoryAction(CATEGORY_HAVE_ERROR, true)); // Dispatch error action if an exception occurs
    }
  };
}
