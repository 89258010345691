// Import action types related to city result data
import {
  CITY_RESULT_ARE_LOADING,
  CITY_RESULT_HAVE_ERROR,
  CITY_RESULT_FETCH_DATA_SUCCESS,
} from "../actionType";

// Define the initial state for the CityResultReducer
const initialState = {
  data: [],
  loading: true,
  error: [], // Initialize error as null for better error handling
};

// Reducer function to handle city result-related actions and update state accordingly
export function CityResultReducer(state = initialState, action) {
  switch (action.type) {
    // Handle error case when fetching city result data
    case CITY_RESULT_HAVE_ERROR:
      return {
        ...state,
        error: action.hasError, // Update the error state with the error from the action
        loading: false, // Set loading to false since the request is complete
      };

    // Handle loading state when city result data is being fetched
    case CITY_RESULT_ARE_LOADING:
      return {
        ...state,
        loading: action.isLoading, // Update the loading state with the loading status from the action
      };

    // Handle success case when city result data is successfully fetched
    case CITY_RESULT_FETCH_DATA_SUCCESS:
      return {
        ...state,
        data: action.cityResult, // Update the data state with the fetched city result data
        loading: false, // Set loading to false since the request is complete
        error: null, // Clear any previous errors
      };

    // Return the current state by default if action type is not recognized
    default:
      return state;
  }
}
