// Import action types related to category and entertainment data
import {
  CATEGORY_FETCH_DATA_SUCCESS,
  CATEGORY_HAVE_ERROR,
  CATEGORY_ARE_LOADING,
  ENTERTAINMENT_FETCH_DATA_SUCCESS,
  ENTERTAINMENT_HAVE_ERROR,
  ENTERTAINMENT_ARE_LOADING,
} from "../actionType";

// Define the initial state for the reducers
const initialState = {
  data: [],
  loading: true,
  error: null, // Initialize error as null for better error handling
};

// Reducer function to handle category-related actions and update state accordingly
export function CategoryReducer(state = initialState, action) {
  switch (action.type) {
    // Handle error case when fetching category data
    case CATEGORY_HAVE_ERROR:
      return {
        ...state,
        error: action.hasError, // Update the error state with the error from the action
        loading: false, // Set loading to false since the request is complete
      };

    // Handle loading state when category data is being fetched
    case CATEGORY_ARE_LOADING:
      return {
        ...state,
        loading: action.isLoading, // Update the loading state with the loading status from the action
      };

    // Handle success case when category data is successfully fetched
    case CATEGORY_FETCH_DATA_SUCCESS:
      return {
        ...state,
        data: action.Category, // Update the data state with the fetched category data
        loading: false, // Set loading to false since the request is complete
        error: null, // Clear any previous errors
      };

    // Return the current state by default if action type is not recognized
    default:
      return state;
  }
}

// Reducer function to handle entertainment-related actions and update state accordingly
export function EntertainmentReducer(state = initialState, action) {
  switch (action.type) {
    // Handle error case when fetching entertainment data
    case ENTERTAINMENT_HAVE_ERROR:
      return {
        ...state,
        error: action.hasError, // Update the error state with the error from the action
        loading: false, // Set loading to false since the request is complete
      };

    // Handle loading state when entertainment data is being fetched
    case ENTERTAINMENT_ARE_LOADING:
      return {
        ...state,
        loading: action.isLoading, // Update the loading state with the loading status from the action
      };

    // Handle success case when entertainment data is successfully fetched
    case ENTERTAINMENT_FETCH_DATA_SUCCESS:
      return {
        ...state,
        data: action?.Category?.data?.posts || [], // Update the data state with the fetched entertainment data, defaulting to an empty array if data is not present
        loading: false, // Set loading to false since the request is complete
        error: null, // Clear any previous errors
      };

    // Return the current state by default if action type is not recognized
    default:
      return state;
  }
}
