/**
 * MyViewScript component
 * 
 * This component conditionally renders a script based on screen size and data from Redux.
 * 
 * @param {object} props - Component properties
 * @param {string} props.argSmall - Redux selector key for the mobile script
 * @param {string} props.argLarge - Redux selector key for the web script
 * @param {boolean} props.addstyle - Optional prop to add padding
 * @returns {JSX.Element} JSX element for the script or an empty string
 */

import React from "react";
import InjectScript from "../components/CustomScript";
import { useSelector } from "react-redux";

const MyViewScript = (props) => {
  // Destructure script data efficiently and handle missing data
  const { [props.argSmall]: scriptMobile, [props.argLarge]: scriptWeb } = useSelector(
    (state) => state?.widgetReducer?.data || {}
  );

  // Determine screen size once
  const isWeb = document.body.clientWidth > 991;

  // Choose the appropriate script based on screen size and availability
  const chosenScript = scriptMobile?.[0]?.script && !isWeb ? scriptMobile?.[0]?.script : scriptWeb?.[0]?.script;

  // Conditionally render the script using a ternary operator
  return chosenScript && chosenScript !== "" ? (
    <div key={window.location.href} style={{ padding: props.addstyle ? "5px 0" : 0 }}>
      <InjectScript script={chosenScript} />
    </div>
  ) : (
    ""
  );
};

export default MyViewScript;
