import React from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { pageFetchData } from "../../redux/actions/pageAction";

/**
 * Pages component
 */
function Pages() {
  /**
   * Get the page ID from the URL parameters
   */
  const { id } = useParams();

  /**
   * Get the page data and loading state from the Redux store
   */
  const PageList = useSelector((state) => state?.PageReducer?.data);
  const loading = useSelector((state) => state?.PageReducer?.loading);

  /**
   * Get the dispatch function from Redux
   */
  const dispatch = useDispatch();

  /**
   * Fetch the page data when the component mounts or the ID changes
   */
  React.useEffect(() => {
    dispatch(pageFetchData(id));
  }, [id]);

  return (
    <>
      {
        /**
         * Set the page title using React Helmet
         */
        PageList?.title && (
          <Helmet>
            <title>{PageList?.title} | Sandesh</title>
          </Helmet>
        )
      }
      <div className="container">
        <div className="row">
          <div className="col-12">
            {
              /**
               * Render the page content
               */
              <div
                className="page-content"
                style={{ opacity: loading? 0.5 : 1 }}
              >
                <h1>{PageList?.title}</h1>
                <div
                  className="content-area"
                  /**
                   * Use dangerouslySetInnerHTML to render the page content as HTML
                   */
                  dangerouslySetInnerHTML={{ __html: PageList?.content }}
                />
              </div>
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default Pages;