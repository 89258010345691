// Import action types related to marquee data
import {
  MARQUEE_FETCH_DATA_SUCCESS,
  MARQUEE_HAVE_ERROR,
  MARQUEE_ARE_LOADING,
} from "../actionType";

// Define the initial state for the Marquee reducer
const initialState = {
  data: [], // Initialize data as an empty array
  loading: true, // Set loading to true initially
  error: [], // Initialize error as an empty array
};

// Reducer function to handle marquee-related actions and update state accordingly
export function MarqueeReducer(state = initialState, action) {
  switch (action.type) {
    // Handle error case when fetching marquee data
    case MARQUEE_HAVE_ERROR:
      return {
        ...state,
        error: action.hasError, // Update the error state with the error from the action
        loading: false, // Set loading to false since the request is complete
      };

    // Handle loading state when marquee data is being fetched
    case MARQUEE_ARE_LOADING:
      return {
        ...state,
        loading: action.isLoading, // Update the loading state with the loading status from the action
      };

    // Handle success case when marquee data is successfully fetched
    case MARQUEE_FETCH_DATA_SUCCESS:
      return {
        ...state,
        data: action.marquee, // Update the data state with the fetched marquee data
        loading: false, // Set loading to false since the request is complete
        error: [], // Clear any previous errors (assuming the success case means no error)
      };

    // Return the current state by default if action type is not recognized
    default:
      return state;
  }
}
