import {
  ITEMS_ARE_LOADING,
  ITEMS_FETCH_DATA_SUCCESS,
  ITEMS_HAVE_ERROR,
} from "../actionType";
import HttpService from "../../utils/httpService";
import { Get } from "../../services/api.service";

// Action creator function to handle different types of menu actions
export function MenuAction(action, payload) {
  switch (action) {
    case ITEMS_HAVE_ERROR:
      return {
        type: action,
        hasError: payload,
      };

    case ITEMS_ARE_LOADING:
      return {
        type: action,
        isLoading: payload,
      };

    case ITEMS_FETCH_DATA_SUCCESS:
      return {
        type: action,
        Menu: payload,
      };

    default:
      return;
  }
}

// Async action creator function to fetch menu data
export function menuFetchData() {
  return (dispatch) => {
    try {
      // Dispatch action to indicate data loading
      dispatch(MenuAction(ITEMS_ARE_LOADING, true));

      // Check if menu data is cached in local storage
      if (localStorage.getItem("SandeshMenu")) {
        // If cached data exists, dispatch success action with cached data
        dispatch(
          MenuAction(
            ITEMS_FETCH_DATA_SUCCESS,
            JSON.parse(localStorage.getItem("SandeshMenu")),
          ),
        );
      }

      // Make a GET request to fetch menu data
      Get(HttpService.menu).then(
        (result) => {
          // Store fetched data in local storage
          localStorage.setItem("SandeshMenu", JSON.stringify(result.data));
          // Dispatch success action with fetched data
          dispatch(MenuAction(ITEMS_FETCH_DATA_SUCCESS, result.data));
        },
        (error) => {
          // Reject promise and dispatch error action if request fails
          dispatch(MenuAction(ITEMS_HAVE_ERROR, true));
        }
      );
    } catch (error) {
      // Dispatch error action if an error occurs during the try block execution
      dispatch(MenuAction(ITEMS_HAVE_ERROR, true));
    }
  };
}
