import {
  ENTERTAINMENT_FETCH_DATA_SUCCESS,
  ENTERTAINMENT_HAVE_ERROR,
  ENTERTAINMENT_ARE_LOADING,
} from "../actionType";

import HttpService from "../../utils/httpService";
import { Get } from "../../services/api.service";

// Action creator function to handle different types of entertainment actions
export function EntertainmentAction(action, payload) {
  switch (action) {
    case ENTERTAINMENT_HAVE_ERROR:
      return {
        type: action,
        hasError: payload,
      };

    case ENTERTAINMENT_ARE_LOADING:
      return {
        type: action,
        isLoading: payload,
      };

    case ENTERTAINMENT_FETCH_DATA_SUCCESS:
      return {
        type: action,
        Category: payload,
      };

    default:
      return; // Return nothing if action type is unknown
  }
}

// Async action creator function to fetch entertainment data
export function EntertainmentFetchData(type = "", payload = {}, params = "") {
  return (dispatch) => {
    // Construct URL based on parameters
    let myurl = HttpService.category + params;
    try {
      // Dispatch action indicating data is loading
      dispatch(EntertainmentAction(ENTERTAINMENT_ARE_LOADING, true));
      
      // Fetch data using GET request
      return new Promise((resolve, reject) => {
        Get(myurl).then(
          (result) => {
            // Process fetched data
            const obje = {
              ...result,
              ...result?.data,
              count: result?.count,
              name: result?.name,
              message: result?.message,
            };
            // Dispatch action with fetched data
            dispatch(
              EntertainmentAction(ENTERTAINMENT_FETCH_DATA_SUCCESS, obje),
            );
            resolve(result);
          },
          (error) => {
            reject(error); // Reject promise with error if fetching fails
          },
        );
      });
    } catch (error) {
      // Dispatch action if there's an error in fetching data
      dispatch(EntertainmentAction(ENTERTAINMENT_HAVE_ERROR, true));
    }
  };
}
