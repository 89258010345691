import React from "react";
import ReactGA from "react-ga4";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import sandeshske from "../../assets/sandeshske.png";
import CommonContent from "../CommonContent";
import CommonHeadCardContent from "../CommonHeadCardContent";
import InjectScript from "../CustomScript";

/**
 * National component
 * 
 * This component renders a section for national news on the homepage.
 */
const National = () => {
  /**
   * Get the asset base URL from the Redux store
   */
  const { 21: assetbaseUrl } = useSelector((state) => state?.SettingReducer?.data);

  /**
   * Get the national news list from the Redux store
   */
  const Nationallist = useSelector((state) => state?.HomeSecondaryReducer?.data?.National);

  /**
   * Define the business widget script
   */
  const businessWidget3 = `
    <div class="tradingview-widget-container">
      <div class="tradingview-widget-container__widget"></div>
      <div class="tradingview-widget-copyright">
        <a href="https://in.tradingview.com/markets/stocks-india/" rel="noopener" target="_blank">
          <span class="blue-text">Stock Market Today</span>
        </a>
        by TradingView
      </div>
      <script type="text/javascript" src="https://s3.tradingview.com/external-embedding/embed-widget-hotlists.js" async>
        {
          "colorTheme": "light",
          "dateRange": "12M",
          "exchange": "BSE",
          "showChart": false,
          "locale": "in",
          "largeChartUrl": "",
          "isTransparent": false,
          "showSymbolLogo": true,
          "showFloatingTooltip": false,
          "width": "400",
          "height": "407"
        }
      </script>
    </div>
  `;

  /**
   * Define the after stock widget script for web
   */
  const afterStockWidgetweb = `
    <div id='div-gpt-ad-1669617015113-0' style='min-width: 300px; min-height: 250px;'>
      <script>
        window.googletag = window.googletag || {cmd: []};
        googletag.cmd.push(function() {
          googletag.defineSlot('/22678428348/SDPL_HP_Belowstockwidget_300x250', [300, 250], 'div-gpt-ad-1669617015113-0').addService(googletag.pubads());
          googletag.pubads().enableSingleRequest();
          googletag.enableServices();
        });
      </script>
      <script>
        googletag.cmd.push(function() { googletag.display('div-gpt-ad-1669617015113-0'); });
      </script>
    </div>
  `;

  /**
   * Define the after stock widget script for mobile
   */
  const afterStockWidgetmobile = `
    <div id='div-gpt-ad-1672903013941-0' style='min-width: 300px; min-height: 250px;'>
      <script>
        window.googletag = window.googletag || {cmd: []};
        googletag.cmd.push(function() {
          googletag.defineSlot('/22678428348/Mweb_HP_BelowStockWidget_300x250', [300, 250], 'div-gpt-ad-1672903013941-0').addService(googletag.pubads());
          googletag.pubads().enableSingleRequest();
          googletag.enableServices();
        });
      </script>
      <script>
        googletag.cmd.push(function() { googletag.display('div-gpt-ad-1672903013941-0'); });
      </script>
    </div>
  `;

  return (
    <>
      <section className="national bg-gray">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-9 col-xl-9">
              <div className="single-blog">
                <span className="badge badge-warning">India</span>
                <Link
                  target="_parent"
                  to="/india"
                  onClickCapture={() =>
                    ReactGA.event({
                      category: "Homepage",
                      action: "India",
                      label: "Read More",
                    })
                  }
                  className="readmore"
                >
                  Read More
                </Link>
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-7 col-lg-7 col-xl-7 removePaddingLeft removePaddingHover">
                  {Nationallist?.length > 0 && (
                    <>
                      <CommonHeadCardContent
                        url={Nationallist[0]?.url}
                        dataClass="margin-bottom"
                        sandeshske={sandeshske}
                        media={Nationallist[0]?.media}
                        mediaimg={`${assetbaseUrl?.value}${Nationallist[0]?.media.replace("?resize=800,450", "")}?resize=567,319`}
                        post_type={Nationallist[0]?.post_type}
                        time={Nationallist[0]?.time}
                        title={Nationallist[0]?.title}
                        id={Nationallist[0]?.id}
                        publish_date={Nationallist[0]?.publish_date}
                      />

                      <CommonContent
                        url={Nationallist[1]?.url}
                        src={`${assetbaseUrl?.value}${Nationallist[1]?.media.replace("?resize=800,450", "")}?resize=90,51`}
                        post_type={Nationallist[1]?.post_type}
                        time={Nationallist[1]?.time}
                        publish_date={Nationallist[1]?.publish_date}
                        category={Nationallist[1]?.tagline}
                        title={Nationallist[1]?.title}
                      />

                     
                    </>
                  )}
                </div>
                <div className="col-md-5 col-lg-5 col-xl-5 removePaddingRight">
                  {Nationallist?.length > 0 &&
                    Nationallist?.map((els, idx) => (
                      <>
                        {idx > 1 && (
                          <CommonContent
                            url={els?.url}
                            src={`${assetbaseUrl?.value}${els?.media.replace("?resize=800,450", "")}?resize=90,51`}
                            post_type={els?.post_type}
                            time={els?.time}
                            publish_date={els?.publish_date}
                            category={els?.tagline}
                            title={els?.title}
                          />
                        )}
                      </>
                    ))}
                </div>
              </div>
            </div>
            <div className="col-md-6 offset-md-3 col-lg-3 offset-lg-0 col-xl-3 offset-xl-0">
              <div className="text-center ad-side-head">
                <div className="mb-3">
                  <div className="text-center businesswidget business-home">
                    <InjectScript
                      script={businessWidget3}
                      key={businessWidget3}
                    />
                  </div>
                </div>
                <div className="dm-national-script">
                  <InjectScript
                    script={
                      window.innerWidth > 991
                       ? afterStockWidgetweb
                        : afterStockWidgetmobile
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default National;