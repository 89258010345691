// Import action types related to categoryAstro data
import {
  CATEGORYASTRO_FETCH_DATA_SUCCESS,
  CATEGORYASTRO_HAVE_ERROR,
  CATEGORYASTRO_ARE_LOADING,
} from "../actionType";

// Define the initial state for the CategoryAstroReducer
const initialState = {
  data: [],
  loading: true,
  error: [], // Initialize error as null for better error handling
};

// Reducer function to handle categoryAstro-related actions and update state accordingly
export function CategoryAstroReducer(state = initialState, action) {
  switch (action.type) {
    // Handle error case when fetching categoryAstro data
    case CATEGORYASTRO_HAVE_ERROR:
      return {
        ...state,
        error: action.hasError, // Update the error state with the error from the action
        loading: false, // Set loading to false since the request is complete
      };

    // Handle loading state when categoryAstro data is being fetched
    case CATEGORYASTRO_ARE_LOADING:
      return {
        ...state,
        loading: action.isLoading, // Update the loading state with the loading status from the action
      };

    // Handle success case when categoryAstro data is successfully fetched
    case CATEGORYASTRO_FETCH_DATA_SUCCESS:
      return {
        ...state,
        data: action.categoryAstro, // Update the data state with the fetched categoryAstro data
        loading: false, // Set loading to false since the request is complete
        error: null, // Clear any previous errors
      };

    // Return the current state by default if action type is not recognized
    default:
      return state;
  }
}
