import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

// Function to configure Redux store
export default function configureStore(initialState) {
  // Check if Redux DevTools Extension is installed and compose enhancers accordingly
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

  // Enhancer for applyMiddleware and Redux DevTools Extension
  const enhancer = composeEnhancers(applyMiddleware(thunk));

  // Configuration for Redux Persist
  const persistConfig = {
    key: "root:sandesh", // Key for the persisted state
    storage, // Storage engine to be used (default is localStorage)
    blacklist: [ // Reducers to be excluded from persisting
      "LogingReducer",
      "PostReducer",
      "CategoryReducer",
      "EPaperCategory",
    ],
  };

  // Create a persisted reducer with the given persistConfig
  const persistedReducer = persistReducer(persistConfig, rootReducer);

  // Create Redux store with persistedReducer, initial state, and enhancer
  let store = createStore(persistedReducer, initialState, enhancer);

  // Create a persistor to persist Redux store
  let persistor = persistStore(store);

  // Return the store and persistor
  return { store, persistor };
}
