import React from "react";
import { Link } from "react-router-dom";

function CommonCardContent({ url, src, title, tagline, publish_date }) {
  // Optional chaining for safer prop access
  const hasUrl = url !== undefined; // Check for URL existence

  const content = (
    <div className="skeleton-sub">
      <img loading="lazy" src={src} alt={title} key={src} />
      {tagline && <span className="button-hover colorspan-top-trending">{tagline}</span>}
    </div>
  );

  return (
    <div className="single-blog mx-2 share-common-card-content">
      {hasUrl ? (
        <Link target="_parent" to={`/${url}`} className="main-blogs">
          {content}
          <div className="blog-details">
            <p className="ellipsis">{title}</p>
            <span className="blog-time">
              <i className="far fa-clock"></i> {publish_date}
            </span>
          </div>
        </Link>
      ) : (
        <div className="main-blogs">
          {content}
          <div className="blog-details">
            <p className="ellipsis">{title}</p>
            <span className="blog-time">
              <i className="far fa-clock"></i> {publish_date}
            </span>
          </div>
        </div>
      )}
    </div>
  );
}

export default CommonCardContent;
