import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useSelector } from "react-redux";
import CommonCardContent from "../CommonCardContent";

/**
 * Trending component
 * 
 * This component renders a section for trending news on the homepage.
 */
const Trending = () => {
  /**
   * Get the asset base URL from the Redux store
   */
  const { 21: assetbaseUrl } = useSelector((state) => state?.SettingReducer?.data);

  /**
   * Get the trending news list from the Redux store
   */
  const Trending = useSelector((state) => state?.HomeSecondaryReducer?.data?.Trending);

  /**
   * Define the responsive settings for the carousel
   */
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1200 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 1200, min: 991 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 991, min: 515 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 515, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      <section className="trending bg-gray trendingheight">
        <div className="container">
          <span className="badge badge-danger">Trending News</span>

          <div className="row">
            <div className="col-md-12">
              <Carousel
                swipeable={true}
                draggable={false}
                autoPlaySpeed={5000000}
                infinite={false}
                showDots={false}
                responsive={responsive}
                ssr={true}
                renderArrowsWhenDisabled={true}
                customTransition="transform 500ms ease"
                className="gallery-sec"
              >
                {Trending?.length > 0 &&
                  Trending?.map((els) => {
                    return (
                      <CommonCardContent
                        url={els?.url}
                        src={`${assetbaseUrl?.value}${els?.media.replace("?resize=800,450", "")}?resize=256,144`}
                        post_type={els?.post_type}
                        time={els?.time}
                        tagline={els?.tagline}
                        publish_date={els?.publish_date}
                        category={els?.category}
                        title={els?.title}
                      />
                    
                    );
                  })}
              </Carousel>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Trending;