import {
  CHILDMENU_ARE_LOADING,
  CHILDMENU_FETCH_DATA_SUCCESS,
  CHILDMENU_HAVE_ERROR,
} from "../actionType";
import HttpService from "../../utils/httpService";
import { Get } from "../../services/api.service";

// Action creator function to create actions for child menu data
export function ChildmenuAction(action, payload) {
  switch (action) {
    case CHILDMENU_HAVE_ERROR:
      return {
        type: action,
        hasError: payload,
      };

    case CHILDMENU_ARE_LOADING:
      return {
        type: action,
        isLoading: payload,
      };

    case CHILDMENU_FETCH_DATA_SUCCESS:
      return {
        type: action,
        childmenu: payload,
      };

    default:
      return; // Return undefined by default if action type is not recognized
  }
}

// Async action creator function to fetch child menu data from the server
export function childmenuFetchData() {
  return (dispatch) => {
    try {
      return new Promise((resolve, reject) => {
        dispatch(ChildmenuAction(CHILDMENU_ARE_LOADING, true)); // Dispatch loading action
        if (localStorage.getItem("SandeshTags")) {
          // If child menu data exists in local storage, dispatch success action with the stored data
          dispatch(
            ChildmenuAction(
              CHILDMENU_FETCH_DATA_SUCCESS,
              JSON.parse(localStorage.getItem("SandeshTags")),
            ),
          );
        }
        // Fetch child menu data from the server
        Get(HttpService.childMenu).then(
          (result) => {
            // Store fetched data in local storage
            localStorage.setItem("SandeshTags", JSON.stringify(result.data));
            // Dispatch success action with the fetched data
            dispatch(
              ChildmenuAction(CHILDMENU_FETCH_DATA_SUCCESS, result.data),
            );
            resolve(result);
          },
          (error) => {
            reject(error); // Reject promise if there is an error
          },
        );
      });
    } catch (error) {
      dispatch(ChildmenuAction(CHILDMENU_HAVE_ERROR, true)); // Dispatch error action if an exception occurs
    }
  };
}
