// Base URLs for API endpoints
const baseUrl = "https://new-wapi.sandesh.com/api/v1/";
const baseUrlMenu = "https://new-wapi.sandesh.com/api/v1/menu/";
const baseUrlPage = "https://new-wapi.sandesh.com/api/v1/";
const PbaseUrl = "https://new-wapi.sandesh.com/api/v1/";

// Base URL for assets
export const assetbaseUrl = "https://epapercdn.sandesh.com/";

// HTTP service object containing various API endpoints
const HttpService = {
  // Page-specific endpoints
  ganesh: baseUrlPage + "ganesh/",
  baseurl: baseUrlPage + "page/",
  rssURL: baseUrlPage + "rss",
  // Menu endpoints
  metro: baseUrl + "menu/gujarat-metro",
  menu: baseUrlMenu + "web-menu",
  footer: baseUrlMenu + "footer-menu",
  // General endpoints
  setting: baseUrl + "setting",
  invrel: baseUrl + "investor",
  gallary: PbaseUrl + "gallerypage",
  gallaryInner: PbaseUrl + "gallery/",
  homeSecondary: PbaseUrl + "home/secondary",
  homePrimary: PbaseUrl + "home/primary",
  post: PbaseUrl + "post-details",
  videoInner: PbaseUrl + "video/",
  category: PbaseUrl + "category/",
  categoryLocation: PbaseUrl + "location/",
  tag: PbaseUrl + "tag/",
  forgetPassword: PbaseUrl + "forgot",
  updatepassword: PbaseUrl + "updatepassword",
  categoryAstro: PbaseUrl + "category/astrology",
  gujaratSamagraCity: PbaseUrl + "city",
  video: PbaseUrl + "videopage",
  loginCheck: PbaseUrl + "logincheck",
  otpCheck: PbaseUrl + "checkotp",
  signup: PbaseUrl + "register",
  login: PbaseUrl + "login",
  city: PbaseUrl + "city",
  cityResult: PbaseUrl + "cityresult/",
  tithi: PbaseUrl + "tithi",
  childMenu: PbaseUrl + "childmenu",
  horoscope: PbaseUrl + "horoscope",
  gameZop: PbaseUrl + "gamezop",
  widget: PbaseUrl + "widget",
  epaperCategory: PbaseUrl + "e-paper?slug=",
  ePaperMenu: baseUrl + "menu/e-paper-menu",
  searchResult: PbaseUrl + "search",
  ePaperImage: PbaseUrl + "epaper-latest",
  googleResult: PbaseUrl + "sociallogin",
  ProfileResult: PbaseUrl + "profile",
  profileUpdateResult: PbaseUrl + "profileupdate",
  analytics: PbaseUrl + "analytics",
  openapp: PbaseUrl + "openapp",
  postComment: PbaseUrl + "post-comment",
  reportcomment: PbaseUrl + "report-comment",
  comment: PbaseUrl + "comments",
  videoCategory: PbaseUrl + "videocategory/",
  sportScore: PbaseUrl + "sportspage",
  marquee: PbaseUrl + "marquee",
};

export default HttpService;
