import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Get } from "../../services/api.service";
import HttpService from "../../utils/httpService";

const Sitemap = () => { // Rename component for clarity

  const [categories, setCategories] = useState([]); // Use specific variable name

  // Fetch category data on component mount
  useEffect(() => {
    async function fetchData() {
      const response = await Get(HttpService.rssURL);
      setCategories(response?.data?.category || []); // Handle potential empty response
    }
    fetchData();
  }, []);

  return (
    <>
      <section className="rss"> {/* Consider renaming class for sitemap */}
        <div className="container">
          <Helmet>
            <title>Sitemap | Sandesh</title>
          </Helmet>

          <div className="row my-3">
            <div className="col-md-2"></div>
            <div className="col-md-8">
              <h1>Sitemap</h1>

              <div className="rss-feed-sec-title pt-0">
                <span>List of Categories:</span>
                <div className="row">
                  {/* Conditionally render categories */}
                  {categories.length > 0 ? (
                    categories.map((category) => (
                      <div className="col-md-3 rss-feed my-1 col-6" key={category.url}>
                        <Link target="_parent" to={`/${category.url}`}>
                          <i className="fa fa-link mr-2"></i>
                          {category.name}
                        </Link>
                      </div>
                    ))
                  ) : (
                    <div className="col-md-12 text-center">Loading...</div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-2"></div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Sitemap;
