import React, { useEffect } from "react"; // Importing React and useEffect from the react library
import ReactGA from "react-ga4"; // Importing ReactGA for Google Analytics
import { useSelector } from "react-redux"; // Importing useSelector from react-redux for state management
import { Link } from "react-router-dom"; // Importing Link from react-router-dom for client-side routing
import sandeshsvg from "../../assets/sandesh-svg.svg"; // Importing the Sandesh SVG logo
import HelperScript from "../../utils/helper"; // Importing a helper script for unknown purposes

// Defining the Footer component
const Footer = (props) => {
  // Using useSelector to get the FooterReducer state
  const Footerlist = useSelector((state) => state?.FooterReducer);
  
  // Using useSelector to get the MenuReducer state
  const menuList = useSelector((state) => state?.MenuReducer);

  // Using useSelector to get specific settings from the SettingReducer state
  const { 28: Facebook } = useSelector((state) => state?.SettingReducer?.data);
  const { 29: Twitter } = useSelector((state) => state?.SettingReducer?.data);
  const { 30: Instagram } = useSelector((state) => state?.SettingReducer?.data);
  const { 31: Youtube } = useSelector((state) => state?.SettingReducer?.data);
  const { 26: sharerMsg } = useSelector((state) => state?.SettingReducer?.data);
  const { 24: Android } = useSelector((state) => state?.SettingReducer?.data);
  const { 25: Apple } = useSelector((state) => state?.SettingReducer?.data);

  // Initializing state variables for footer and menu items
  const [footer, setFooterItems] = React.useState("");
  const [menu, setMenuItems] = React.useState("");

  // Using useEffect to set the footer and menu items when the component mounts
  useEffect(() => {
    setFooterItems(Footerlist.data);
    setMenuItems(menuList.data);
  }, [Footerlist, menuList]);

  // Defining a script for Google AdSense interstitial ads
  const footer_bottom_homepage =
    "" +
    "<script>" +
    "window.googletag = window.googletag || { cmd: [] };" +
    "var ub_interstitialSlot;" +
    "googletag.cmd.push(function () {" +
    'ub_interstitialSlot = googletag.defineOutOfPageSlot("/22678428348/InterstiatialUS_Median_Sep",googletag.enums.OutOfPageFormat.INTERSTITIAL)' +
    ".addService(googletag.pubads());" +
    "googletag.enableServices();" +
    "});" +
    "googletag.cmd.push(function () {" +
    "googletag.pubads().refresh([ub_interstitialSlot]);" +
    "});" +
    "</script>" +
    "";

  // Returning the JSX for the Footer component
  return (
    <>
      <div className="ad-section">
        {/* Including the HelperScript component */}
        <HelperScript argSmall={191} argLarge={44} />
      </div>
      <section className="ft_section">
        <div className="row">
          <div id="footer_bottom">
            <div className="col-md-12 p-0">
              <div
                className="ft-sticky"
                style={{ transform: "translateY(0%)" }}
              >
                <div className="ft-sticky--mid">
                  <div className="ft-sticky_wrap">
                    <div className="ft-sticky--collapse">
                      <div className="footer_group">
                        <ul>
                          <li className="more highlighted">
                            <a href="#">OUR CATEGORIES</a>
                          </li>
                          {/* Mapping over the menu items and rendering links */}
                          {menu &&
                            Object.values(menu).map((els, idx) => (
                              <li
                                className="nav-item"
                                onClickCapture={() =>
                                  ReactGA.event({
                                    category: "Footer",
                                    action: "Category",
                                    label: els?.name,
                                  })
                                }
                              >
                                {els?.open === 1? (
                                  <a
                                    key={Math.random()}
                                    className="dropdown"
                                    target="_blank"
                                    href={`${els?.url}`}
                                    rel="noreferrer"
                                  >
                                    {els?.name}
                                  </a>
                                ) : (
                                  <a>
                                    <Link
                                      key={Math.random()}
                                      className="dropdown"
                                      to={`/${els?.category}`}
                                    >
                                      {els?.name}
                                    </Link>
                                  </a>
                                )}
                              </li>
                            ))}
                        </ul>
                      </div>
                      <div className="footer_nav">
                        <ul>
                          {/* Mapping over the footer items and rendering links */}
                          {footer&&
                            Object.values(footer).map((els, idx) => (
                              <li
                                className="list-inline-item"
                                onClickCapture={() =>
                                  ReactGA.event({
                                    category: "Footer",
                                    action: "Page",
                                    label: els?.name,
                                  })
                                }
                              >
                                {els?.open === 1? (
                                  <a
                                    key={Math.random()}
                                    target="_blank"
                                    href={`${els?.url}`}
                                    rel="noreferrer"
                                  >
                                    {els?.name}{" "}
                                  </a>
                                ) : els?.page === "investor-relations" ? (
                                  <Link
                                    key={Math.random()}
                                    to={`/investor-relations`}
                                  >
                                    {els?.name}{" "}
                                  </Link>
                                ) : (
                                  <Link
                                    key={Math.random()}
                                    to={`/page/${els?.page}`}
                                  >
                                    {els?.name}{" "}
                                  </Link>
                                )}
                              </li>
                            ))}
                        </ul>
                      </div>
                      <div className="footer_copy">
                        {/* Copyright text */}
                        © COPYRIGHT SANDESH DIGITAL PRIVATE LIMITED{" "}
                        {new Date().getFullYear()}. ALL RIGHTS RESERVED.
                        {/* Hidden input field for sharerMsg */}
                        <input
                          type="hidden"
                          value={sharerMsg?.value}
                          className="sharerMsg"
                        />
                      </div>
                    </div>
                    <div className="ft-sticky_lft">
                      <div className="ft-icons-right">
                        <div className="open-links-mobile">
                          {/* Links to Android and Apple app stores */}
                          <a
                            href="https://play.google.com/store/apps/details?id=com.sandesh.news"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src="https://epapercdn.sandesh.com/images/2024/04/16/yAGjx1D7Ne9UfVGTn3pMYHi4RsIfGrGZHZsLDxO9.svg"
                              alt=""
                            />
                          </a>
                          <a
                            href="https://apps.apple.com/in/app/sandesh-news/id1200153846"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src="https://epapercdn.sandesh.com/images/2024/04/16/g6SbD2Webbbs7p01gwZ4HREoLBrIHUFj890lux4k.svg"
                              alt=""
                            />
                          </a>
                        </div>
                      </div>
                      <div className="ft-icons-right">
                        <div className="ft-sticky_logo">
                          {/* Link to homepage */}
                          <Link
                            target="_parent"
                            to="/"
                            onClickCapture={() =>
                              ReactGA.event({
                                category: "Footer",
                                action: "Logo",
                                label: "Logo",
                              })
                            }
                          >
                            <img
                              style={{ filter: "none" }}
                              className="sandesh_logo"
                              src={sandeshsvg}
                              alt=""
                            />
                          </Link>
                        </div>
                        <div className="follow-us">
                          <span>Follow us on</span>
                          <div className="footer_socials">
                            <ul className="social-icone-center-footer">
                              {/* Social media links */}
                              <li>
                                <a
                                  target="_blank"
                                  onClickCapture={() =>
                                    ReactGA.event({
                                      category: "Footer",
                                      action: "Icon",
                                      label: "Whatsapp",
                                    })
                                  }
                                  className="menu-page-social-icon-whatsapp"
                                  href="https://chat.whatsapp.com/DrLl02j2JZTJmnRCCmPumD"
                                  rel="noreferrer"
                                >
                                  {" "}
                                  <i class="fa-size-footer fab fa-whatsapp"></i>{" "}
                                </a>
                              </li>
                              <li>
                                <a
                                  target="_blank"
                                  onClickCapture={() =>
                                    ReactGA.event({
                                      category: "Footer",
                                      action: "Icon",
                                      label: "Facebook",
                                    })
                                  }
                                  className="menu-page-social-icon-facebook"
                                  href={Facebook?.value}
                                  rel="noreferrer"
                                >
                                  <i className="fa-size-footer fab fa-facebook"></i>
                                </a>
                              </li>
                              <li>
                                <a
                                  target="_blank"
                                  onClickCapture={() =>
                                    ReactGA.event({
                                      category: "Footer",
                                      action: "Icon",
                                      label: "Twitter",
                                    })
                                  }
                                  className="menu-page-social-icon-twitter"
                                  href={Twitter?.value}
                                  rel="noreferrer"
                                >
                                  <i className="fa-size-footer fab fa-twitter"></i>
                                </a>
                              </li>
                              <li>
                                <a
                                  target="_blank"
                                  onClickCapture={() =>
                                    ReactGA.event({
                                      category: "Footer",
                                      action: "Icon",
                                      label: "Instagram",
                                    })
                                  }
                                  className="menu-page-social-icon-instagram-footer"
                                  href={Instagram?.value}
                                  rel="noreferrer"
                                >
                                  <i className="fa-size-footer fab fa-instagram"></i>
                                </a>
                              </li>
                              <li>
                                <a
                                  target="_blank"
                                  onClickCapture={() =>
                                    ReactGA.event({
                                      category: "Footer",
                                      action: "Icon",
                                      label: "Youtube",
                                    })
                                  }
                                  className="menu-page-social-icon-yt"
                                  href={Youtube?.value}
                                  rel="noreferrer"
                                >
                                  <i className="fa-size-footer fab fa-youtube"></i>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>
      
    </>
  );
};

export default Footer;