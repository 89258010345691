import {
  CATEGORYASTRO_FETCH_DATA_SUCCESS,
  CATEGORYASTRO_HAVE_ERROR,
  CATEGORYASTRO_ARE_LOADING,
} from "../actionType";
import HttpService from "../../utils/httpService";
import { Get } from "../../services/api.service";

// Action creator function to create actions for categoryAstro data
export function categoryAstroAction(action, payload) {
  switch (action) {
    case CATEGORYASTRO_HAVE_ERROR:
      return {
        type: action,
        hasError: payload,
      };

    case CATEGORYASTRO_ARE_LOADING:
      return {
        type: action,
        isLoading: payload,
      };

    case CATEGORYASTRO_FETCH_DATA_SUCCESS:
      return {
        type: action,
        categoryAstro: payload,
      };

    default:
      return; // Return undefined by default if action type is not recognized
  }
}

// Async action creator function to fetch categoryAstro data from the server
export function categoryAstroFetchData() {
  return (dispatch) => {
    try {
      return new Promise((resolve, reject) => {
        dispatch(categoryAstroAction(CATEGORYASTRO_ARE_LOADING, true)); // Dispatch loading action
        Get(HttpService.categoryAstro).then(
          (result) => {
            // Dispatch success action with the fetched data
            dispatch(
              categoryAstroAction(
                CATEGORYASTRO_FETCH_DATA_SUCCESS,
                result.data,
              ),
            );
            resolve(result);
          },
          (error) => {
            reject(error); // Reject promise if there is an error
          },
        );
      });
    } catch (error) {
      dispatch(categoryAstroAction(CATEGORYASTRO_HAVE_ERROR, true)); // Dispatch error action if an exception occurs
    }
  };
}
